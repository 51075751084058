import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { JOB_POST_DECISION } from "../../constants";
import API from "../../api";

const initialState = {
  jobDecision: null, // it can hold 'copy' for copy and paste, also it can hold 'zoe' to create via chatGpt and by default it will be null
  isJobDecisionModalOpen: false,
  prevJobDecisionState: null,
  progressModel: false,
  buyJobsModel: false
};

const jobDecisionSlice = createSlice({
  name: "jobDecision",
  initialState,
  reducers: {
    setJobDecision: (state, action) => {
      state.jobDecision = action.payload;
    },
    clearJobDecision: (state) => {
      state.jobDecision = null;
    },
    getJobDecision: (state) => state.jobDecision,
    changeJobDecisionToSyetemGen: (state) => {
      state.prevJobDecisionState = state.jobDecision;
      state.jobDecision = JOB_POST_DECISION.SYSTEM_GEN;
    },
    changeJobDecisionToCopy: (state) => {
      state.prevJobDecisionState = state.jobDecision;
      state.jobDecision = JOB_POST_DECISION.COPY_PASTE;
    },
    changeJobDecisionToZoe: (state) => {
      state.prevJobDecisionState = state.jobDecision;
      state.jobDecision = JOB_POST_DECISION.ZOE;
    },
    openJobDecisionModal: (state) => {
      state.isJobDecisionModalOpen = true;
    },
    closeJobDecisionModal: (state) => {
      state.isJobDecisionModalOpen = false;
    },
    clearPrevJobDecisionState: (state) => {
      state.prevJobDecisionState = null;
    },
    setProgressModel: (state, action) => {
      state.progressModel = action.payload;
    },
    setBuyJobsModelModel: (state, action) => {
      state.buyJobsModel = action.payload;
    },
  },
});

export const {
  setJobDecision,
  clearJobDecision,
  getJobDecision,
  changeJobDecisionToSyetemGen,
  changeJobDecisionToCopy,
  changeJobDecisionToZoe,
  openJobDecisionModal,
  closeJobDecisionModal,
  clearPrevJobDecisionState,
  setProgressModel,
  setBuyJobsModelModel
} = jobDecisionSlice.actions;

export default jobDecisionSlice.reducer;



export const getAvailableJobPostsDataAsync = createAsyncThunk(
  "emp/getAvailableJobPostsData",
  async () => {
    try {
      const response = await API.get(`/available-job-posts`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);


export const getProductPricesDataAsync = createAsyncThunk(
  "emp/getProductPricesData",
  async () => {
    try {
      const response = await API.get(`/job-board/get-product-price?category=single-job-post`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const purchaseJObDataAsync = createAsyncThunk(
  "emp/purchaseJObData",
  async ({ user_id, product_name, unit_amount, quantity, success_url, cancel_url }) => {
    const formData = new FormData();
    user_id && formData.append("user_id", user_id);
    product_name && formData.append("product_name", product_name);
    unit_amount && formData.append("unit_amount", unit_amount);
    quantity && formData.append("quantity", quantity);
    success_url && formData.append("success_url", success_url);
    cancel_url && formData.append("cancel_url", cancel_url);

    try {
      const response = await API.post(`/job-board/purchase-job`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);