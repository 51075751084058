import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Field } from "formik";
import OutsideClickHandler from "react-outside-click-handler";
import * as Yup from "yup";
import API from "../../../../api";
import PhoneNumber from "../../../Common/PhoneNumber/PhoneNumber";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import APIPvt from "../../../../apiPvt";
import {
  getCitiesAsync,
  getStateAsync,
} from "../../../../redux/slices/AuthSlice";
import { urlRegex } from "../../../../constants";

const CanProfileForm = ({
  loader,
  baseInfo,
  jobTitles,
  tags,
  companyLogo,
  maskedPlaceHolderP,
  maskedPhoneNumberP,
  statesCount,
  editHandler,
  companyLogoModelHandler,
  submitHandler,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [allJobTitles, setAllTobTitles] = useState([]);
  const [selectJobTitles, setSelectJobTitles] = useState([]);
  const [allJobTitlesHide, setAllJobTitlesHide] = useState(true);

  const [allSkillTags, setAllSkillTags] = useState([]);
  const [selectSkillTags, setSelectSkillTags] = useState([]);
  const [allSkillTagsHide, setAllSkillTagsHide] = useState(true);
  const [currencyOption, setCurrencyOption] = useState([]);

  const [selectedImageName, setSelectedImageName] = useState("");
  const [selectedVideoName, setSelectedVideoName] = useState("");

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [locationHide, setLocationHide] = useState(true);

  const [stateDDHide, setStateDDHide] = useState(true);
  const [stateSearch, setStateSearch] = useState("");

  const [cityDDHide, setCityDDHide] = useState(true);
  const [citySearch, setCitySearch] = useState("");

  const [phoneNumberValue, setPhoneNumberValue] = useState(null);
  const [allWorkPreference, setAllWorkPreference] = useState([]);
  const [selectWorkPreferenceTags, setSelectWorkPreferenceTags] = useState([]);
  const [allWorkPreferenceTagsHide, setAllWorkPreferenceTagsHide] =
    useState(true);
  const workPreferenceArray = [];
  const [salaryRangeOption, setSalaryRangeOption] = useState([]);

  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [maskedPlaceHolder, setMaskedPlaceHolder] = useState("");

  const onChange = (event) => {
    setPhoneNumberValue(event.target.value);
  };

  const highlightMatchingText = (option, inputValue) => {
    const lowercaseInputValue = inputValue?.toLowerCase();
    const regex = new RegExp(`(${lowercaseInputValue})`, "gi");
    const parts = option.split(regex);

    return (
      <span>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <strong key={index} style={{ color: "#143975" }}>
              {part}
            </strong>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      firstName: "",
      lastName: "",
      country: "",
      countryId: null,
      state: "",
      stateId: null,
      city: "",
      cityId: null,
      email: "",
      phoneNo: "",
      linkedinURL: "",
      aboutSelf: "",
      interested: "",
      resume: "",
      introVideo: null,
      jobTitle: "",
      skillTags: "",
      salaryRange: "year",
      salaryExpectation: "",
      userWorkPreferences: [],
      currency: "",
      currencyId: null,
      workPreference: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "First name should be an alphabet")
        .required("Please enter first name"),
      lastName: Yup.string()
        .matches(/^[A-Za-z ]+$/, "Last name should be an alphabet")
        .required("Please enter last name"),
      country: Yup.string().required("Please select the country"),
      state: Yup.string(),
      city: Yup.string(),
      phoneNo: Yup.string(),
      linkedinURL: Yup.string().matches(
        urlRegex,
        "Please enter a valid linkedin url"
      ),
      files: Yup.mixed()
        .test(
          "fileType",
          "File can be a JPEG or PNG document",
          (value) => !value || ["image/png", "image/jpeg"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 10 MB",
          (value) => !value || value?.size <= 10 * 1024 * 1024
        ),

      introVideo: Yup.mixed()
        .test(
          "fileType",
          "Unsupported file format",
          (value) =>
            !value ||
            [
              "video/mp4",
              "video/webm",
              "video/x-matroska",
              "video/3gpp",
            ].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 25 MB",
          (value) => !value || value?.size <= 25 * 1024 * 1024
        ),
      /*skillTags: Yup.mixed().test(
        "skillTags",
        "Please select a minimum of 5 Skill Tags",
        (value) =>
          !!(Array.isArray(selectSkillTags) && selectSkillTags?.length > 5),
      ),*/
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      const { state, city } = data;

      if (stateOptions?.length > 0 && state === "") {
        formik?.setFieldError("state", "Please select state");
        return;
      }
      if (citiesOptions?.length > 0 && city === "") {
        formik?.setFieldError("city", "Please select city");
        return;
      }

      const selectJobTitlesNames = selectJobTitles?.map((item) => item.id);
      const selectSkillTagsNames = selectSkillTags.map((item) => item.id);
      const selectWorkPreferenceNames = selectWorkPreferenceTags.map(
        (item) => item.id
      );

      // user["currency_id"] = data.currencyId
      submitHandler(
        data,
        user,
        selectJobTitlesNames,
        selectSkillTagsNames,
        selectWorkPreferenceNames
      );
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isFileFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFileErrorMessage = (name) => {
    return (
      isFileFieldValid(name) && (
        <p className="file-error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const onSalaryRangeClick = async (name) => {
    console.log(name);
    await formik.setFieldValue("salaryRange", name);
  };
  /*const isSkillTagsFieldValid = () => {
    if ( selectSkillTags?.length < 5){
      formik.errors["skillTags"] = "Please select a minimum of 5 Skill Tags";
    }
    return !!((formik.touched["skillTags"] && formik.errors["skillTags"] && selectSkillTags?.length < 5) || (formik.errors["skillTags"] && selectSkillTags?.length < 5) || (selectSkillTags?.length < 5))
  }

  const getSkillTagsErrorMessage = () => {
    return (
        isSkillTagsFieldValid() && (
            <p className="error-msg">{formik.errors["skillTags"]}</p>
        )
    )
  }*/

  const handleImageChange = async (event) => {
    companyLogoModelHandler(true);
    // const file = event.target.files[0];

    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setSelectedImageName(file.name);
    //     formik.setFieldValue("files", file);
    //   };
    //   reader.readAsDataURL(file);
    // } else {
    //   setSelectedImageName("");
    //   formik.setFieldValue("files", null);
    // }
  };

  const countryHandler = async (item) => {
    const {
      id,
      name,
      currency,
      masked_phone_number,
      placeholder,
      states_count,
    } = item;

    await formik.setFieldValue("country", name);
    await formik.setFieldValue("countryId", id);

    await formik.setFieldValue("state", "");
    await formik.setFieldValue("stateId", null);

    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);

    onCurrencyClick(currency?.id, currency?.title);

    setMaskedPhoneNumber(masked_phone_number);
    setMaskedPlaceHolder(placeholder);
    setLocationHide(true);

    setStateOptions([]);
    setCitiesOptions([]);

    if (states_count === 0) {
      getCitiesData(id, null);
    } else {
      getStatusData(id);
    }
  };

  const stateHandler = async (id, name) => {
    setStateDDHide(true);
    setStateSearch(name);

    await formik.setFieldValue("state", name);
    await formik.setFieldValue("stateId", id);
    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);
    getCitiesData(null, id);
  };

  const cityHandler = async (id, name) => {
    setCityDDHide(true);
    setCitySearch(name);

    await formik.setFieldValue("city", name);
    await formik.setFieldValue("cityId", id);
  };

  /* State search */

  const filteredStateItem = stateOptions?.filter((item) =>
    item?.name?.toLowerCase().includes(stateSearch.toLowerCase())
  );

  const searchStateLocation = (e) => {
    setStateSearch(e.target.value);
  };

  /********************/

  /* City search */

  const filteredCityItem = citiesOptions?.filter((item) =>
    item?.name?.toLowerCase().includes(citySearch.toLowerCase())
  );

  const searchCityLocation = (e) => {
    setCitySearch(e.target.value);
  };

  /********************/

  const onJobTitileChange = async (value) => {
    await formik.setFieldValue("jobTitle", value);
    getSearchSuggestion(value, "jobTitle");
  };

  const onWorkPreferenceTagsCancel = async (curentSkillTags) => {
    const updatedItems = selectWorkPreferenceTags.filter(
      (item) => item.id !== curentSkillTags?.id
    );
    setSelectWorkPreferenceTags(updatedItems);
  };
  const onJobTitileClick = async (curentJobTitles) => {
    const isIdExists = selectJobTitles?.some(
      (item) => item.id === curentJobTitles?.id
    );
    if (isIdExists) return;
    setSelectJobTitles([...selectJobTitles, curentJobTitles]);
    setAllJobTitlesHide(true);
    await formik.setFieldValue("jobTitle", "");
  };

  const onJobTitileCancel = async (curentJobTitles) => {
    const updatedItems = selectJobTitles.filter(
      (item) => item.id !== curentJobTitles?.id
    );
    setSelectJobTitles(updatedItems);
  };

  const onSkillTagsChange = async (value) => {
    await formik.setFieldValue("skillTags", value);
    getSearchSuggestion(value, "jobTag");
  };

  const onSkillTagsClick = async (curentSkillTags) => {
    const isIdExists = selectSkillTags?.some(
      (item) => item.id === curentSkillTags?.id
    );
    if (isIdExists) return;
    setSelectSkillTags([...selectSkillTags, curentSkillTags]);
    setAllSkillTagsHide(true);
    await formik.setFieldValue("skillTags", "");
  };

  const getJobDropDownData = () => {
    // if (user !== null) {}
    const currencies = [
      {
        id: 1,
        country: null,
        flag_link:
          "https://demo.workbridgetalent.ca/uploads/flags/macedonia.png",
        symbol: "Ден",
        title: "MKD",
      },
    ];
    const salaryRange = [
      {
        label: "Year",
        page: "",
        value: "year",
      },
      {
        label: "Month",
        page: "",
        value: "month",
      },
      {
        label: "Hour",
        page: "",
        value: "hour",
      },
    ];

    setSalaryRangeOption(salaryRange);
  };

  const onWorkPreferenceTagsClick = async (curentSkillTags) => {
    const isIdExists = selectWorkPreferenceTags?.some(
      (item) => item.id === curentSkillTags?.id
    );
    if (isIdExists) return;
    workPreferenceArray.push([...selectWorkPreferenceTags, curentSkillTags]);
    console.log(workPreferenceArray);
    setSelectWorkPreferenceTags([...selectWorkPreferenceTags, curentSkillTags]);
    setAllWorkPreferenceTagsHide(true);
    await formik.setFieldValue("userWorkPreferences", "");
  };

  const onSkillTagsCancel = async (curentSkillTags) => {
    const updatedItems = selectSkillTags.filter(
      (item) => item.id !== curentSkillTags?.id
    );
    setSelectSkillTags(updatedItems);
  };

  const onCurrencyClick = async (id, name) => {
    await formik.setFieldValue("currencyId", id);
    await formik.setFieldValue("currency", name);
  };

  const getWorkPreferences = async () => {
    const workPreferenceList = [
      { id: 1, name: "Remote" },
      { id: 2, name: "Onsite" },
      { id: 3, name: "Hybrid" },
      { id: 4, name: "All" },
    ];

    setAllWorkPreference(workPreferenceList);
  };

  const getStatusData = async (id) => {
    dispatch(
      getStateAsync({
        id: id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        setStateOptions(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCitiesData = async (id1, id2) => {
    dispatch(
      getCitiesAsync({
        country_id: id1,
        state_id: id2,
      })
    )
      .then((response) => {
        const res = response?.payload;
        setCitiesOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchSuggestion = async (searchValue, searchType) => {
    try {
      let params = ``;
      if (searchValue !== "") {
        params = `?query_key=${searchValue}&type=${searchType}`;
      }
      await API.get(`/job-board/get-search-suggestions${params}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data;
            if (searchType === "jobTitle") {
              if (res?.job_titles?.length > 0) {
                setAllTobTitles(res?.job_titles);
                setAllJobTitlesHide(false);
              } else {
                setAllTobTitles([]);
                setAllJobTitlesHide(false);
              }
            }

            if (searchType === "jobTag") {
              if (res?.job_tags?.length > 0) {
                setAllSkillTags(res?.job_tags);
                setAllSkillTagsHide(false);
              } else {
                setAllSkillTags([]);
                setAllSkillTagsHide(false);
              }
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (companyLogo !== null) {
      formik.setFieldValue("files", companyLogo);
      setSelectedImageName(companyLogo?.name);
    }
  }, [companyLogo]);

  useEffect(() => {
    if (baseInfo?.workPreferences?.length > 0) {
      setSelectWorkPreferenceTags(baseInfo?.workPreferences);
    }

    if (baseInfo?.countryId) {
      if (statesCount === 0) getCitiesData(baseInfo?.countryId, null);
      else {
        getStatusData(baseInfo?.countryId);
        getCitiesData(null, baseInfo?.countryId);
      }
    }
    setSelectJobTitles(jobTitles);
    setSelectSkillTags(tags);
    getWorkPreferences();
    getJobDropDownData();

    setMaskedPlaceHolder(maskedPlaceHolderP);
    setMaskedPhoneNumber(maskedPhoneNumberP);

    formik.setValues({
      firstName: baseInfo?.first_name || "",
      lastName: baseInfo?.last_name || "",
      countryId: baseInfo?.countryId || null,
      country: baseInfo?.country || "",
      cityId: baseInfo?.cityId || null,
      city: baseInfo?.city || "",
      stateId: baseInfo?.stateId || null,
      state: baseInfo?.state || "",
      email: baseInfo?.email || "",
      phoneNo: baseInfo?.phone || "",
      linkedinURL: baseInfo?.linkedin || "",
      aboutSelf: baseInfo?.bio || "",
      interested: baseInfo?.my_interests || "",
      salaryExpectation: baseInfo?.expected_salary || 0,
      userWorkPreferences: baseInfo?.workPreferences,
      currency: baseInfo?.currency || "",
      currencyId: baseInfo?.currencyId || null,
      resume: baseInfo?.file,
      salaryRange: baseInfo?.salary_range,
    });
  }, []);

  // console.log("formik errors =======>", formik.errors);

  return (
    <div className="my-profile-row">
      <div className="candidtae-profile-image new-profile-image-st profile-pic-desktop">
        <img
          src={
            companyLogo !== null
              ? URL?.createObjectURL(companyLogo)
              : baseInfo?.avatar === null || baseInfo?.avatar === undefined
              ? "/image/avtar.jpg"
              : baseInfo?.avatar
          }
          alt=""
          className="profile-opac"
        />
        <div className="addProfile-icon icon-cursor">
          <img src="/image/addProfile.png" alt="" onClick={handleImageChange} />
        </div>
      </div>
      <span className="profile-img-mbl">
        <div className="candidtae-profile-image new-profile-image-st">
          <img
            src={
              companyLogo !== null
                ? URL?.createObjectURL(companyLogo)
                : baseInfo?.avatar === null || baseInfo?.avatar === undefined
                ? "/image/avtar.jpg"
                : baseInfo?.avatar
            }
            alt=""
            className="profile-opac"
          />
          <div className="addProfile-icon icon-cursor">
            <img
              src="/image/addProfile.png"
              alt=""
              onClick={handleImageChange}
            />
          </div>
        </div>
      </span>
      <div className="candidate-information with-accordion">
        <div className="candidate-about-me">
          <div className="candidate-profile-form">
            <h5>About Me</h5>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-block-width">
                <div className={`candidate-name-field-profile profile-field-f`}>
                  <div
                    class=""
                    className={`first-name-profile ${
                      isFormFieldValid("firstName") && "error-input"
                    }`}
                  >
                    <input
                      className="form-font-f"
                      type="text"
                      id="candidate-name-field"
                      placeholder="First name"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("firstName")}
                  </div>
                  <div
                    className={`first-name-profile ${
                      isFormFieldValid("lastName") && "error-input"
                    }`}
                  >
                    <input
                      className="form-font-f"
                      type="text"
                      id="candidate-name-field"
                      placeholder="Last name"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("lastName")}
                  </div>
                </div>
                <div
                  className={`profile-field-f signup-passowrd-job location-profile-list disable-field ${
                    isFormFieldValid("country") && "error-input"
                  }`}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-location.png"
                        className="location-icon"
                        alt=""
                      />
                    </div>
                    <div
                      id="dropdown-boxId-2"
                      className="company-form-dropdownBox-profile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {formik?.values?.country === ""
                        ? "location"
                        : formik?.values?.country}
                    </div>
                    <div className="icon-hide">
                      {isFormFieldValid("country") ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/form-d-f.png" alt="" />
                      )}
                    </div>
                  </div>
                  {getFormErrorMessage("country")}
                </div>

                <div className="candidate-username-signup-form">
                  <div
                    className={`profile-field-f signup-passowrd-job location-profile-list first-name ${
                      (isFormFieldValid("state") && "error-input") ||
                      (stateOptions?.length === 0 && "disable-field")
                    }`}
                  >
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setStateDDHide(true);
                      }}
                    >
                      <div className="industry-feild-frm bg-pass-f location-f-new">
                        <div className="password-icon-logo">
                          <img
                            src="/image/profile-location.png"
                            className="location-icon"
                            alt=""
                          />
                        </div>
                        <div className="profile-location-feild">
                          <div
                            id="dropdown-boxId2"
                            className="profile-form-dropdownBox1"
                            onClick={() => setStateDDHide(!stateDDHide)}
                          >
                            <div className="text-filter-profile">
                              <p className="text-filter-select">
                                {" "}
                                {formik?.values?.state === ""
                                  ? "Province/State"
                                  : formik?.values?.state}
                              </p>

                              <div className="icon-hide-2">
                                {isFormFieldValid("state") ? (
                                  <img src="/image/warning.png" alt="" />
                                ) : (
                                  <img src="/image/form-d-f.png" alt="" />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`admin-status-dropdown-profile ${
                              !stateDDHide && stateOptions?.length > 0
                                ? "open"
                                : ""
                            }`}
                          >
                            <div className="searchbox-invite">
                              <input
                                name="searchLocation"
                                value={stateSearch}
                                onChange={(e) => searchStateLocation(e)}
                                id="job-search-field"
                                className="location-search-field"
                                placeholder="State location"
                                autocomplete="off"
                              />
                            </div>

                            {stateOptions?.length > 0 &&
                              !filteredStateItem.length && (
                                <>
                                  <ul className="dorpdown-list-location">
                                    {stateOptions?.map((j, i) => (
                                      <li
                                        className="icon-cursor"
                                        onClick={() =>
                                          stateHandler(j?.id, j?.name)
                                        }
                                      >
                                        <img
                                          src="/image/location-j-place.png"
                                          alt=""
                                        />
                                        {j?.name}
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )}

                            {filteredStateItem?.length > 0 && (
                              <>
                                <ul className="dorpdown-list-location">
                                  {filteredStateItem.map((j, i) => (
                                    <li
                                      className="icon-cursor"
                                      onClick={() =>
                                        stateHandler(j?.id, j?.name)
                                      }
                                    >
                                      <img
                                        src="/image/location-j-place.png"
                                        alt=""
                                      />
                                      {j?.name}
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {getFormErrorMessage("state")}
                    </OutsideClickHandler>
                  </div>
                  <div
                    className={`profile-field-f signup-passowrd-job location-profile-list first-name ${
                      (isFormFieldValid("city") && "error-input") ||
                      (citiesOptions?.length === 0 && "disable-field")
                    }`}
                  >
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setCityDDHide(true);
                      }}
                    >
                      <div className="industry-feild-frm bg-pass-f">
                        <div className="password-icon-logo">
                          <img
                            src="/image/profile-location.png"
                            className="location-icon"
                            alt=""
                          />
                        </div>
                        <div className="profile-location-feild">
                          <div
                            id="dropdown-boxId2"
                            className="profile-form-dropdownBox1"
                            onClick={() => setCityDDHide(!cityDDHide)}
                          >
                            <div className="text-filter-profile">
                              <p className="text-filter-select">
                                {" "}
                                {formik?.values?.city === ""
                                  ? "City"
                                  : formik?.values?.city}
                              </p>

                              <div className="icon-hide-2">
                                {isFormFieldValid("city") ? (
                                  <img src="/image/warning.png" alt="" />
                                ) : (
                                  <img src="/image/form-d-f.png" alt="" />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`admin-status-dropdown-profile ${
                              !cityDDHide && citiesOptions?.length > 0
                                ? "open"
                                : ""
                            }`}
                          >
                            <div className="searchbox-invite">
                              <input
                                name="searchLocation"
                                value={citySearch}
                                onChange={(e) => searchCityLocation(e)}
                                id="job-search-field"
                                className="location-search-field"
                                placeholder="City location"
                                autocomplete="off"
                              />
                            </div>

                            {citiesOptions?.length > 0 &&
                              !filteredCityItem.length && (
                                <>
                                  <ul className="dorpdown-list-location">
                                    {citiesOptions?.map((j, i) => (
                                      <li
                                        className="icon-cursor"
                                        onClick={() =>
                                          cityHandler(j?.id, j?.name)
                                        }
                                      >
                                        <img
                                          src="/image/location-j-place.png"
                                          alt=""
                                        />
                                        {j?.name}
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )}

                            {filteredCityItem?.length > 0 && (
                              <>
                                <ul className="dorpdown-list-location">
                                  {filteredCityItem.map((j, i) => (
                                    <li
                                      className="icon-cursor"
                                      onClick={() =>
                                        cityHandler(j?.id, j?.name)
                                      }
                                    >
                                      <img
                                        src="/image/location-j-place.png"
                                        alt=""
                                      />
                                      {j?.name}
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {getFormErrorMessage("city")}
                    </OutsideClickHandler>
                  </div>
                </div>

                {/* <div className="candidate-username-signup-form">
                  <div
                    className={`profile-field-f signup-passowrd-job location-profile-list first-name ${isFormFieldValid("state") && "error-input"
                      ||
                      stateOptions?.length === 0 && "disable-field"
                      }`}
                  >
                    <div className="industry-feild-frm bg-pass-f">
                      <div className="password-icon-logo">
                        <img
                          src="/image/profile-location.png"
                          className="location-icon"
                          alt=""
                        />
                      </div>
                      <div
                        id="dropdown-boxId-2"
                        className="company-form-dropdownBox-profile"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {" "}
                        {formik?.values?.state === ""
                          ? "Province/State"
                          : formik?.values?.state}
                      </div>
                      <div className="icon-hide-2">
                        {isFormFieldValid("state") ? (
                          <img src="/image/warning.png" alt="" />
                        ) : (
                          <img src="/image/form-d-f.png" alt="" />
                        )}
                      </div>
                      {stateOptions?.length > 0 && (
                        <div
                          id="company-dropList-t"
                          className={`dropdown-form-f company-drop-list-t 
                          }`}
                        >
                          <ul>
                            {stateOptions?.map((j, i) => (
                              <li
                                className="icon-cursor-f"
                                key={i}
                                onClick={() => stateHandler(j?.id, j?.name)}
                              >
                                <img
                                  src="/image/profile-location.png"
                                  className="location-icon-drop"
                                  alt=""
                                />{" "}
                                {j?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    {getFormErrorMessage("state")}
                  </div>

                  <div
                    className={`profile-field-f signup-passowrd-job location-profile-list first-name ${isFormFieldValid("city") && "error-input"
                      ||
                      citiesOptions?.length === 0 && "disable-field"
                      }`}
                  >
                    <div className="industry-feild-frm bg-pass-f">
                      <div className="password-icon-logo">
                        <img
                          src="/image/profile-location.png"
                          className="location-icon"
                          alt=""
                        />
                      </div>
                      <div
                        id="dropdown-boxId-2"
                        className="company-form-dropdownBox-profile"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {" "}
                        {formik?.values?.city === ""
                          ? "City"
                          : formik?.values?.city}
                      </div>
                      <div className="icon-hide-2">
                        {isFormFieldValid("city") ? (
                          <img src="/image/warning.png" alt="" />
                        ) : (
                          <img src="/image/form-d-f.png" alt="" />
                        )}
                      </div>
                      {citiesOptions?.length > 0 && (
                        <div
                          id="company-dropList-t"
                          className={`dropdown-form-f company-drop-list-t`}
                        >
                          <ul>
                            {citiesOptions.map((j, i) => (
                              <li
                                className="icon-cursor-f"
                                key={i}
                                onClick={() => cityHandler(j?.id, j?.name)}
                              >
                                <img
                                  src="/image/profile-location.png"
                                  className="location-icon-drop"
                                  alt=""
                                />{" "}
                                {j?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    {getFormErrorMessage("city")}
                  </div>

                </div> */}
                <div className={`email-candidate-profile-f profile-field-f`}>
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img src="/image/profile-email.png" alt="" />
                    </div>
                    <input
                      className="form-font-f"
                      id="profile-email-candidate"
                      placeholder="E-mail"
                      name="email"
                      value={formik.values.email}
                    />
                    <div className="icon-hide">
                      <img src="/image/locked-field.png" alt="" />
                      <span className="tooltiptext-profile">
                        Contact Admin Support
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className={`profile-field-f ${
                    isFormFieldValid("phoneNo") && "error-input"
                  }`}
                >
                  <div
                    className={`country-code-profile phone-f-profile  ${
                      isFormFieldValid("phoneNo") && "error-input"
                    }`}
                  >
                    <div className="location-f-icon bg-pass-f">
                      <div className="password-icon-logo">
                        <img
                          src="/image/profile-phone.png"
                          alt=""
                          className="profile-icon"
                        />
                      </div>
                      <PhoneNumber
                        className="form-font-f"
                        id="profile-call"
                        name="phoneNo"
                        value={formik.values.phoneNo}
                        maskedPlaceHolder={maskedPlaceHolder}
                        maskedPhoneNumber={maskedPhoneNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="icon-hide">
                        {isFormFieldValid("phoneNo") && (
                          <img src="/image/warning.png" alt="" />
                        )}
                      </div>
                    </div>
                  </div>
                  {getFormErrorMessage("phoneNo")}
                </div>
                <div className={`candidate-link-profile profile-field-f 
                 ${isFormFieldValid("linkedinURL") && "error-input"}`}
                >
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-linked.png"
                        alt=""
                        className="profile-icon"
                      />
                    </div>
                    <input
                      className="form-font-f"
                      type="text"
                      id="social-link"
                      placeholder="Enter your linkedIn profile here"
                      name="linkedinURL"
                      value={formik.values.linkedinURL}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                     {isFormFieldValid("linkedinURL") && (
                      <img src="/image/warning.png" alt="" />
                    )}
                  </div>
                  {getFormErrorMessage("linkedinURL")}
                </div>
              </div>
              <div className="form-block-width">
                <div className="row m-0">
                  <div className="col-md-6 order-md-0 order-1">
                    <button
                      className="btn btn-black w-100 btn-lg"
                      onClick={() => editHandler(false)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-6 order-md-1 order-0">
                    <button
                      type="submit"
                      className="btn btn-outline-black w-100 btn-lg mb-md-0 mb-3"
                    >
                      {loader ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanProfileForm;
