import { useEffect, useState } from "react";
import Ably from "ably";
import { ABLY_API_KEY_DEV, ABLY_API_KEY_QA } from "../config";

const useAblyHook = (channelName) => {
  const [channel, setChannel] = useState(null);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine); // Check initial network status

  const getAblyApiKey = () => {
    const url = window.location.href;
    if (url.includes("dev")) {
      return ABLY_API_KEY_DEV;
    } else if (url.includes("qa")) {
      return ABLY_API_KEY_QA;
    } else {
      return ABLY_API_KEY_DEV;
    }
  };

  const connectionHandler = async () => {
    const ABLY_API_KEY = getAblyApiKey();
    const ably = new Ably.Realtime({
      key: ABLY_API_KEY,
      log: { level: 0 }, // Disable all logging
      autoConnect: true, // Auto-connect once the internet is back
      transports: ["web_socket"], // Use WebSocket explicitly
    });

    const ablyChannel = ably.channels.get(channelName);
    setChannel(ablyChannel);

    const handleError = (err) => {
      setError(err);
    };

    // Connection state change listener
    ably.connection.on("connected", () => {
      setIsConnected(true);
      //console.log("Connected to Ably");
    });

    ably.connection.on("disconnected", () => {
      setIsConnected(false);
      console.warn("Disconnected from Ably");
    });

    ably.connection.on("suspended", () => {
      setIsConnected(false);
      console.warn("Connection suspended");
    });

    ably.connection.on("failed", handleError);

    // Attach to channel
    ablyChannel.attach((err) => {
      if (err) {
        handleError(err); // Handle channel attachment errors
      } else {
      }
    });

    // Clean up function
    return () => {
      ablyChannel.detach((err) => {
        if (err) {
        } else {
        }
      });
      ably.close(); // Close connection
    };
  };

  useEffect(() => {
    // Event listeners for online/offline status
    const handleOnline = () => {
      setIsOnline(true);
      connectionHandler(); // Reconnect Ably when online
    };

    const handleOffline = () => {
      setIsOnline(false);
      setIsConnected(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [channelName]);

  useEffect(() => {
    if (isOnline) {
      connectionHandler();
    }
  }, [isOnline, channelName]);

  return { channel, error, isConnected };
};

export default useAblyHook;
