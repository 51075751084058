import { useState } from "react";
import { Link } from "react-router-dom";
import JobRecordRight from "../../Common/JobRecord/JobRecordRight";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobCardMbl from "../../Common/JobCardMbl/JobCardMbl";

const RecJob = ({ recJobsCount, recJobs }) => {
  const [count, setCount] = useState(10);

  const countHandler = () => {
    if (count < recJobsCount) setCount((prevCount) => prevCount + 10);
  };

  if (recJobs?.length === 0) return null;

  return (
    <section className="new-job" id="rec_job">
      <div className="job-heading">
        <div className="container container-site">
          <h2>Recommended jobs</h2>
        </div>
      </div>
      <div className="container container-site mt-2">
        {recJobs?.length > 0 &&
          recJobs?.slice(0, count)?.map((prod, index) => (
            <>
              <span className="jobs-section-lg desktop-jobs">
                {index != 0 && (<div className="divider divide-desk"></div>)}
                <div className="Job-Opportunities-row">
                  <JobRecordLeft JobData={prod} index={index} />
                  <JobRecordRight
                    hashId={prod?.hashed_id}
                    slug={prod?.slug}
                    date={prod?.date}
                    selectedJobId={prod?.id}
                    jobCountry={prod?.country}
                    isApplied={prod?.is_applied}
                    wishList={prod?.whishlist}                    
                  />
                </div>
                <div className="divider divide-mbl"></div>
              </span>
              <span className="mbl-jobs">
                <JobCardMbl
                  hashId={prod?.hashed_id}
                  slug={prod?.slug}     
                  JobData={prod}
                  index={index}
                  date={prod?.date}
                  jobCountry={prod?.country}
                  selectedJobId={prod?.id}
                  isApplied={prod?.is_applied}
                  wishList={prod?.whishlist}
                />
              </span>
            </>
          ))
        }
        <div className="divider"></div>
      </div>
      {recJobs?.length > 10 && (
        <div className="see-more-job" onClick={() => countHandler()}>
          <Link href="#">
            <button>More jobs</button>
          </Link>
        </div>
      )}
    </section>
  );
};

export default RecJob;
