import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import "./FileUploadModel.css";

const FileUploadModel = ({
  modelHandler,
  resumeFileHandler,
  title = "Upload Resume",
}) => {
  const [selectedImageName, setSelectedImageName] = useState("");

  const formik = useFormik({
    initialValues: {
      files: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      files: Yup.mixed()
        .required("Upload a resume in a PDF format")
        .test(
          "fileType",
          "Please upload a file in PDF format",
          (value) => !value || ["application/pdf"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 5 MB",
          (value) => !value || value?.size <= 5 * 1024 * 1024
        ),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { files } = data;
      resumeFileHandler(files, selectedImageName);
      modelHandler(false);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="candidate-file-res-suberror">{formik.errors[name]}</p>
      )
    );
  };

  const handleImageChange = async (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extracting file name and extension
        const fileName = file?.name;
        const fileExtension = fileName?.substring(
          fileName?.lastIndexOf(".") + 1
        );
        const completeFileName = `${fileName}`;
        setSelectedImageName(completeFileName);
        formik.setFieldValue("files", file);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImageName("");
      formik.setFieldValue("files", null);
    }
  };

  return (
    <section className="model-popup model-popup-2">
      <div className="upload-resume-model-popup-bg">
        <OutsideClickHandler
          onOutsideClick={() => {
            modelHandler(false);
          }}
        >
          <div className="popup-resume-content">
            <div
              className="close-resmodle-file"
              onClick={() => modelHandler(false)}
            >
              <img src="/image/cut-sm.png " alt=" " />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="res-upload-heading">{title}</div>

              <div className="model-box-poly">
                <p className="employee-model-content">Accepted Formats</p>
                <p className="image-type-size">PDF is the only valid format</p>
                <div className="user-input-resumefile-box">
                  <div className="resmfile-img-data">
                    <label id="candidate-img">
                      <img src="/image/PDF.png" alt="" />
                      <p class="img-cap">
                        Maximum file size should not exceed 5 MB.
                      </p>
                      <p className="brow-btn icon-cursor">Browse File</p>
                      <input
                        id="file-input"
                        type="file"
                        name="files"
                        placeholder="Choose file"
                        onChange={handleImageChange}
                      />
                    </label>
                    {getFormErrorMessage("files") ? (
                      getFormErrorMessage("files")
                    ) : (
                      <p className="candidate-file-res-subtext-upload">
                        {selectedImageName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="employee-img-btns">
                  <a onClick={() => modelHandler(false)}>
                    <button className="cancel-img-btn">Cancel</button>
                  </a>
                  <a className="uplod-btn">
                    <label id="upload-img">
                      <img
                        className="icon-cursor"
                        src="/image/upload-btn.png"
                        alt=""
                      />
                      <button
                        className="img-e-upload icon-cursor"
                        type="submit"
                      >
                        Upload                   
                      </button>
                      <input
                        id="file-input"
                        type="file"
                        name="files"
                        placeholder="Choose file"
                        onChange={handleImageChange}
                      />
                    </label>
                  </a>
                </div>
              </div>
            </form>
          </div>
        </OutsideClickHandler>
      </div>
    </section>
  );
};

export default FileUploadModel;
