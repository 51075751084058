import React, { useState } from 'react'
import ShowRows from '../../../components/Common/Admin/ShowRows/ShowRows'
import TablePagination from '../../../components/Common/Admin/TablePagination/TablePagination';
import { SORT_DIRECTION, USER_TYPE } from "../../../constants";

const Billing = () => {
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortByStartDate, setSortByStartDate] = useState("desc");

  const onsortByStartDateClick = () => {
    if (sortByStartDate === SORT_DIRECTION.ASC) {
      setSortByStartDate(SORT_DIRECTION.DESC);
    } else {
      setSortByStartDate(SORT_DIRECTION.ASC);
    }
  };
  return (
    <>

      <div className="page-space bg-clr-admin">
        <div className="page-title-heading">
          <h2>Billing and invoicing</h2>
        </div>{" "}
        <div className="filter-search-bar">
          <div className="two-drop-role">
            <div className="col-left-adminfilter">
              <p className="filter-label-admin">Year</p>
              <div className="admin-status-filter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${"text-filter-select"
                        }`}
                    >
                      Year
                    </p>

                    <img src="/image/admin-d.png" alt="" />

                  </div>
                </div>


              </div>
            </div>

            <div className="col-left-adminfilter">
              <p className="filter-label-admin">Month</p>
              <div className="admin-status-filter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={"text-filter-select"}
                    >
                      Month
                    </p>

                    <img src="/image/admin-d.png" alt="" />

                  </div>
                </div>


                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-co/* The above code is written in JavaScript and it is
                  using the useState hook from React. It declares a
                  state variable called perPage and a function to
                  update that state variable called setPerPage, with
                  an initial value of 10. This code snippet is
                  likely part of a React functional component where
                  the perPage state will be used to manage the
                  number of items to display per page. */
                  mpany">

                    <li
                      key={""}
                      onClick={() => {
                        ""
                      }}
                    >

                    </li>

                  </ul>
                </div>

              </div>
            </div>
          </div>

          <div className="col-right-searcgadmin">
            <div className="search-admin">
              <img src="/image/job-search.png" alt="" />
              <input
                type="text"
                name="search"
                id="jon-search-box"
                placeholder="Search"
                className="form-font-f"
                onChange={(e) => {
                  ""
                }}
              />
            </div>
          </div>
        </div>
        <ShowRows
          perPage={perPage}
        />
        <div className="department-table-imge">
          <div className="col-user-table">
            <div className="table-responsive">
              <table className="depart-table-left">
                <thead className="tb-heading-user">
                  <tr>
                    <th scope="col">
                      <div className="th-admin-heading">Invoice #</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Description</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Qty</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Subtotal</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Tax</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Total amount paid</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">
                        Purchase date{" "}
                        <img
                          className="arrow-icon icon-cursor"
                          src={
                            sortByStartDate === SORT_DIRECTION.ASC
                              ? "/image/bars-arrow-up.png"
                              : "/image/bars-arrow-down.png"
                          }
                          alt=""
                          onClick={() => onsortByStartDateClick()}
                        />{" "}
                      </div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Download</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="tb-user">
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>01-01-2024</td>
                    <td><button className='btn btn-black'>Download</button></td>
                  </tr>
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>01-01-2024</td>
                    <td><button className='btn btn-black'>Download</button></td>
                  </tr>
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>01-01-2024</td>
                    <td><button className='btn btn-black'>Download</button></td>
                  </tr>
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>01-01-2024</td>
                    <td><button className='btn btn-black'>Download</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TablePagination
              totalPages={1}
              currentPage={1}
            />
          </div>
        </div>
      </div>

    </>
  )
}

export default Billing