import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSideBar,
  setFormMode,
} from "../../../../redux/slices/AdminDashboardSlice";
import BulkResumeUploadForm from "../../../../components/Admin/BulkResume/BulkResumeUpload";
import ZipFileUploadModel from "../../../../components/Common/ZipFileUploadModel/ZipFileUploadModel";
import { uploadBulkResumeFileAsync } from "../../../../redux/slices/bulkResumeCanDetailSlice";
import ActionModal from "../../../../components/Common/ActionModal/ActionModal";
import "./index.css";

const BulkResumeUpload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { totalBulkResumeCredit } = useSelector((state) => state.buyBulkAnalysis);

  const [loader, setLoader] = useState(false);
  const [toggleResumeModel, setToggleResumeModel] = useState(false);
  const [toggleStatusModel, setToggleStatusModel] = useState(false);

  const [reqStatus, setReqStatus] = useState("");
  const [msg, setMsg] = useState("");

  const [zipResume, setZipResume] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  const navHandler = () => {
    dispatch(setFormMode("add"));

    dispatch(
      setActiveSideBar({
        id: "41",
        name: "Resume batch tool",
        value: "Resume batch tool",
      })
    );

    navigate("/bulk-resume-analysis");
  };

  const uploadBulkResumeFileHandler = (data) => {
    if (user !== null) {
      const { jobTitle, jobDes, files } = data;
      setLoader(true);
      setMsg("");
      dispatch(
        uploadBulkResumeFileAsync({
          userId: user?.id,
          jobTitle: jobTitle,
          jobDes: jobDes,
          local_zip_file: files,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            dispatch(
              setActiveSideBar({
                id: "41",
                name: "Resume batch tool",
                value: "Resume batch tool",
              })
            );
            setMsg(res?.message?.success);
            setReqStatus("success");
          } else {
            setMsg(res?.message?.error);
            setReqStatus("failed");
          }
          setToggleStatusModel(true);
          setLoader(false);

          setTimeout(() => {
            navigate("/bulk-resume-analysis");
          }, 5000);
        })
        .catch((error) => {
          setMsg("");
          setReqStatus("failed");
          setLoader(false);
          setToggleStatusModel(false);
          console.log(error);
        });
    }
  };

  const resumeModelHandler = (value) => {
    setToggleResumeModel(value);
  };

  const zipResumeHandler = (file, selectedFileName) => {
    setZipResume(file);
    setSelectedFileName(selectedFileName);
  };

  return (
    <>
      <div className="department-role-space bg-clr-admin">
        <div className="form-heading-prom form-heading-sub-prom">        
            <div className="form-heading-sub-prom1">
              <img
                src="/image/form-back-arrow.png"
                alt=""
                onClick={() => navHandler()}
              />
              <h2>Bulk resume upload</h2>
            </div>
            <button className="btn btn-black dp">
              <img
                className="img-btn"
                alt="img"
                src="./image/pc-icon-white.png"
              />
              {`${totalBulkResumeCredit} PolyCareers credit`}  
            </button>
        
        </div>
        <BulkResumeUploadForm
          zipResume={zipResume}
          selectedFileName={selectedFileName}
          loader={loader}
          resumeModelHandler={resumeModelHandler}
          uploadBulkResumeFileHandler={uploadBulkResumeFileHandler}
        />
      </div>

      {toggleResumeModel && (
        <ZipFileUploadModel
          title="Upload zip file"
          modelHandler={resumeModelHandler}
          resumeFileHandler={zipResumeHandler}
        />
      )}

      {toggleStatusModel && (
        <ActionModal
          heading={reqStatus === "success" ? "Success" : "Sorry"}
          closeModal={() => setToggleStatusModel(false)}
          modalArt={
            reqStatus === "success"
              ? "/image/success-modal.png"
              : "/image/failed-modal.png"
          }
          content={msg}
        ></ActionModal>
      )}
    </>
  );
};

export default BulkResumeUpload;
