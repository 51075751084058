import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ApplicantsTable from "../../../components/Common/ApplicantsTable/ApplicantsTable";
import JobNoRecordFound from "../../../components/Common/JobNoRecordFound/JobNoRecordFound";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import ResumeModel from "../../../components/Common/ResumeModel/ResumeModel";
import CommentModel from "../../../components/Common/CommentModel/CommentModel";
import API from "../../../api";
import APIPvtDB from "../../../apiPvtD";
import {
  candidateFirstImpressionChangeAsync,
  candidateStatusChangeAsync,
  changeCandidateStatusToResumeReviewedAsync,
  favoriteCandidateAsync,
} from "../../../redux/slices/employerDashboardSlice";
import { FvtData } from "../../../data/employer";
import { CANDIDATE_STATUSES } from "../../../constants";
import {
  addJobCommentAsync,
  emptyCommentsModalData,
  setCandidateId,
  setJobId,
} from "../../../redux/slices/commentModalSlice";
import "./EmpFavouritesList.css";
import Button from "react-bootstrap/Button";

const EmpFavouritesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { candidateStatuses } = useSelector((state) => state.employerDashboard);
  const { user } = useSelector((state) => state.auth);
  const [count, setCount] = useState(10);
  const [toggleModel, setToggleModel] = useState(false);
  const [toggleModelC, setToggleModelC] = useState(false);
  const [search, setSearch] = useState("");
  const [jobTitles, setJobTitles] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedJobTitile, setSelectedJobTitile] = useState("");
  const [favoriteApplicants, setFavoriteApplicants] = useState([]);
  const [statusDropDown, setStatusDropDown] = useState([]);

  const commentRemoved = (commentId, commentToReplace) => {
    const updateFavoriteApplicants = favoriteApplicants.map((applicant) => {
      if (applicant?.comments?.id === commentId) {
        const newComment = {
          ...commentToReplace,
          comments_count:
            applicant?.comments?.comments_count > 0
              ? applicant?.comments?.comments_count - 1
              : 0,
        };

        if (commentToReplace === "") {
          return {
            ...applicant,
            comments: "",
          };
        }

        return {
          ...applicant,
          comments: {
            ...newComment,
          },
        };
      }
      return applicant;
    });
    setFavoriteApplicants(updateFavoriteApplicants);
  };

  const commentAdded = (candidateId, jobId, comment) => {
    const updateFavoriteApplicants = favoriteApplicants.map((applicant) => {
      if (
        applicant?.account_id === candidateId &&
        applicant?.job_id === jobId
      ) {
        const newComment = {
          ...comment,
          comments_count: applicant?.comments?.comments_count + 1,
        };

        return {
          ...applicant,
          comments: {
            ...newComment,
          },
        };
      }
      return applicant;
    });
    setFavoriteApplicants(updateFavoriteApplicants);
  };

  const handleNewCommentSubmit = async (text, jobId, candidateId) => {
    if (text === null || text === undefined || text === "") return;
    if (jobId === null || jobId === undefined || jobId === "") return;
    if (candidateId === null || candidateId === undefined || candidateId === "")
      return;

    dispatch(
      addJobCommentAsync({
        userId: user?.id,
        jobId: jobId,
        candidateId: candidateId,
        commentText: text,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedFavoriteApplicants = favoriteApplicants.map(
            (applicant) => {
              if (
                applicant?.account_id === candidateId &&
                applicant?.job_id === jobId
              ) {
                return {
                  ...applicant,
                  comments: {
                    ...res?.data[0]?.comments[0],
                    comments_count: 0,
                  },
                };
              }
              return applicant;
            }
          );
          setFavoriteApplicants(updatedFavoriteApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const modelCHandler = (v, candidate_id, job_id) => {
    setToggleModelC(v);

    if (v === false) {
      dispatch(emptyCommentsModalData());
      return;
    }
    if (
      candidate_id === null ||
      candidate_id === undefined ||
      candidate_id === "" ||
      job_id === null ||
      job_id === undefined ||
      job_id === ""
    ) {
      setToggleModelC(false);
      return;
    }

    dispatch(setJobId(job_id));
    dispatch(setCandidateId(candidate_id));
  };
  const modelHandler = (v) => setToggleModel(v);

  const countHandler = () => {
    if (count < favoriteApplicants?.length)
      setCount((prevCount) => prevCount + 10);
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onJobTitleClick = (j) => {
    setSelectedJobTitile(j);
  };

  const onJobTitleCean = () => {
    setSelectedJobTitile("");
  };

  const onResumeClickHandler = async (candidateId, jobId, currentStatus) => {
    if (currentStatus !== CANDIDATE_STATUSES.NEW_APPLICANT) return;

    dispatch(
      changeCandidateStatusToResumeReviewedAsync({
        candidateId: candidateId,
        jobId: jobId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = favoriteApplicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                status_id: res?.status_id,
                status: res?.status,
              };
            }
            return applicant;
          });
          setFavoriteApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFirstImpressionClick = async (
    candidateId,
    jobId,
    impressionId,
    CurrentStatusId
  ) => {
    if (CurrentStatusId === CANDIDATE_STATUSES.NEW_APPLICANT) return;
    if (candidateId === null || jobId === null || impressionId === null) return;

    const selectedFavoriteApplicant = favoriteApplicants?.find(
      (applicant) =>
        applicant?.job_id === jobId && applicant?.account_id === candidateId
    );
    if (
      parseInt(selectedFavoriteApplicant?.is_favorite) === 1 &&
      impressionId === 1
    ) {
      toast.error("Favourite candidate cannot have not a good fit status.", {
        autoClose: 2000,
        closeOnClick: false,
      });
      return;
    }

    dispatch(
      candidateFirstImpressionChangeAsync({
        candidateId: candidateId,
        jobId: jobId,
        impressionId: impressionId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = favoriteApplicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                impression_id: res?.impression_removed === 1 ? 0 : impressionId,
              };
            }
            return applicant;
          });
          setFavoriteApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCandidateStatusChange = async (
    candidateId,
    jobId,
    statusId,
    currentStatusId
  ) => {
    if (candidateId === null || jobId === null || statusId === null) return;
    if (currentStatusId === statusId) return;
    dispatch(
      candidateStatusChangeAsync({
        candidateId: candidateId,
        statusId: statusId,
        jobId: jobId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = favoriteApplicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              const { id, status } = statusDropDown?.find(
                (item) => item.id === statusId
              );

              return {
                ...applicant,
                status_id: id,
                status: status,
              };
            }
            return applicant;
          });
          setFavoriteApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFvtClick = async (account_id, job_id) => {
    const selectedFavoriteApplicant = favoriteApplicants?.find(
      (applicant) =>
        applicant?.job_id === job_id && applicant?.account_id === account_id
    );
    if (selectedFavoriteApplicant?.impression_id === 1) {
      toast.error(
        "Not a good Fit candidate cannot be added to favourite list.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      return;
    }

    dispatch(
      favoriteCandidateAsync({
        userId: user?.id,
        accountId: account_id,
        jobId: job_id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedFavoriteApplicants = favoriteApplicants?.filter(
            (favorite) =>
              !(favorite?.job_id === job_id && favorite?.account_id === account_id)
          );
          setFavoriteApplicants(updatedFavoriteApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmpFvtApplicantsData = async () => {
    if (user?.id) {
      try {
        let params = `?`;        
        if (selectedJobTitile !== "") {
          params += `job_title=${selectedJobTitile}&`;
        }
        if (search !== "") {
          params += `search=${search}&`;
        }

        await APIPvtDB.get(`/get-favorite-candidates${params}`)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304 ||
              response?.status === 401
            ) {
              let res = response?.data;
              if (res?.result === true) {
                setFavoriteApplicants(res?.favorite_applicants);
                setJobTitles(res?.job_titles);
                setStatusDropDown(res?.statuses);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getEmpFvtApplicantsData();
  }, [search, selectedJobTitile]);

  return (
    <>
      <div className="position-relative mt-3">
        <div className="container container-site">
          <Button
            variant="link"
            className="back-to-home add resp-mob position-relative p-md-0 p-2 mb-0"
            onClick={() => navigate(-1)}
          >
            <img src="/image/form-back-arrow.png" alt="" />
          </Button>
        </div>{" "}
      </div>
      <section className="job-heading mt-md-5 mt-3">
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="job-search-box-row position-relative">
              <div className="job-heading-home smilar-job-heading job-heading-local w-100">
                <h2>Favourite candidates</h2>

                <div
                  className={`job-search-div m-0 ${openSearch ? "job-searchBtn-open" : "job-search-div-mbl"
                    }`}
                  onClick={() => {
                    setOpenSearch(true);
                  }}
                >
                  <img
                    className="job-searchIcon job-searchIcon-mbl"
                    src="/image/job-searchIcon.png"
                    alt="job-searchBar"
                  />
                </div>
                <div
                  className={`job-search-div m-0 ${openSearch ? "job-search-div-abs" : "job-search-open"
                    }`}
                >
                  <img
                    className="job-searchIcon"
                    src="/image/job-searchIcon.png"
                    alt="job-searchBar"
                    onClick={() => {
                      setOpenSearch(false);
                    }}
                  />
                  <input
                    type="text"
                    name="search"
                    id="jon-search-box"
                    placeholder="Search applicants"
                    className="form-font-f"
                    onChange={(e) => {
                      onSearchChange(e?.target?.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="new-job">
        <div className="container container-site">
          <div className="divider-opcity "></div>
          <div className="j-fillter-only my-3">
            <div className="job-fillter-company">
              <div className="j-company-fillter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {" "}
                      {selectedJobTitile === ""
                        ? "Job Title"
                        : selectedJobTitile?.length >= 18
                          ? selectedJobTitile?.substr(0, 18) + " ..."
                          : selectedJobTitile}
                    </p>
                    {selectedJobTitile === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onJobTitleCean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {jobTitles?.length > 0 && (
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {jobTitles?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onJobTitleClick(j);
                          }}
                        >
                          {j}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="job-listing">
            <div className="job-listing-header">
              <div className="lr full-name">Full name</div>
              <div className="lr j-title">Job applied to</div>
              <div className="lr experience">Experience</div>
              <div className="lr cand-status">Candidate status</div>
              <div className="lr match">Overall match</div>
              <div className="lr impression">Impression</div>
              <div className="lr date-app">Date applied</div>
              <div className="lr action">Action</div>
            </div>
            <div className="job-listing-mb">
              {favoriteApplicants?.slice(0, count)?.map((item, index) => (
                <div className="job-listing-body">
                  <ApplicantsTable
                    showCheckBox={false}
                    key={index}
                    index={index}
                    item={item}
                    candidateStatusesList={statusDropDown}
                    onFvtClick={onFvtClick}
                    modelHandler={modelHandler}
                    modelCHandler={modelCHandler}
                    onFirstImpressionClick={onFirstImpressionClick}
                    onCandidateStatusChangeHandler={onCandidateStatusChange}
                    onResumeClickHandler={onResumeClickHandler}
                    handleAddNewCommentHandler={handleNewCommentSubmit}
                    handleRemovedComment={commentRemoved}
                  />
                </div>
              ))}
            </div>
            <div className="divider"></div>
          </div>
          {favoriteApplicants?.length === 0 && (
            <>
              <JobNoRecordFound />
              <div className="divider"></div>
            </>
          )}
        </div>
        {favoriteApplicants?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See more</button>
            </Link>
          </div>
        )}
      </section>
      <WorldConnect />
      {toggleModel && <ResumeModel modelHandler={modelHandler} />}
      {toggleModelC && (
        <CommentModel
          modelCHandler={modelCHandler}
          commentAdded={commentAdded}
          commentRemoved={commentRemoved}
        />
      )}
    </>
  );
};

export default EmpFavouritesList;
