import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQuery } from '../../../utils/useQuery';
import MetaTags from "../../../utils/MetaTags";
import JobHead from "../../../components/Job/JobHead/JobHead";
import JobDescription from "../../../components/Job/ViewDetail/JobDescription";
import SimilarJobs from "../../../components/Job/ViewDetail/SimilarJobs";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import LoginModel from "../../../components/Common/LoginModel/LoginModel";
import { JobDescriptionData, JobDetailData } from "../../../data/job";
import {
  setMostSearchJob,
  setSearchWord,
} from "../../../redux/slices/JobSearchSlice";
import API from "../../../api";
import "./index.css";


const ViewDetail = () => {
  const query = useQuery();
  const currentJobId = query.get('currentJobId');
  const shareUrl = window.location.href;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isEmailVerify, user } = useSelector((state) => state.auth);
  const { mostSearchJob } = useSelector((state) => state.jobSearch);

  const [metaData, setMetaData] = useState(null);
  const [search, setSearch] = useState("");
  const [showMsg, setShowMSg] = useState(false);
  const [loader, setLoader] = useState(false);

  const [qaStatus, setQAStatus] = useState(false);
  const [qrCodeData, setQRCodeData] = useState(null);
  const [jobDetail, setJobDetail] = useState([]);
  const [similarJobs, setSimilarJobs] = useState([]);
  const headRef = useRef(null);

  const findJobHandler = () => {
    navigate("/job-search");
  };

  const onMostSearchjobClick = (value) => {
    dispatch(setSearchWord(value));
    dispatch(setMostSearchJob(!mostSearchJob));
    navigate("/job-search");
  };

  const scrollToHead = () => {
    if (headRef.current) {
      headRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };
  useEffect(() => {
    async function getJobDetail() {
      setLoader(true);
      if (currentJobId !== null) {
        let params = `?`;
        if (user !== null) {
          params += `user_id=${user?.id}&`;
        }
        if (currentJobId !== null) {
          params += `jobId=${currentJobId}&`;
        }
        if (search !== "") {
          params += `search=${search}&`;
        }
        try {
          await API.get(`/job-board/job-detail${params}`)
            .then((response) => {
              if (response?.status === 200) {
                let res = response?.data;
                let jobDesDetail = Object.assign({}, res?.jobDetail[0]);
                let metaTags = jobDesDetail?.meta_tags ? jobDesDetail?.meta_tags : null;

                if (search !== "") {
                  setSimilarJobs(res?.similarJobs);
                  setShowMSg(true);
                } else {
                  setJobDetail(res?.jobDetail);
                  setSimilarJobs(res?.similarJobs);
                  setQAStatus(res?.qa_status);
                  setQRCodeData(res?.qr_code_data);  
                  setMetaData(metaTags);
                  setShowMSg(false);

                  // setMetaData({
                  //   title: 'Job Opportunity',
                  //   meta_description: 'Explore this exciting job opportunity.',
                  //   meta_robots: 'index,follow',
                  //   link_canonical: shareUrl,
                  //   og_title: 'Job Opportunity',
                  //   og_description: 'Explore this exciting job opportunity.',
                  //   og_image: 'https://dbx9fh0y1icj3.cloudfront.net/uploads/CA/companies/4/logo/1726664424_avtar-4.png', 
                  //   og_url: shareUrl,
                  //   twitter_card: 'summary_large_image',
                  //   twitter_title: 'Job Opportunity',
                  //   twitter_description: 'Explore this exciting job opportunity.',
                  //   twitter_image: 'https://dbx9fh0y1icj3.cloudfront.net/uploads/CA/companies/4/logo/1726664424_avtar-4.png', 
                  //   keywords: jobDesDetail?.tags?.map(tag => tag?.name).join(', ') || 'default, keywords',
                  // })

                }
                setLoader(false);
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
                setLoader(false);
              }
            });
        } catch (error) {
          console.log(error);
          setLoader(false);
        }
      } else {
        console.log("else");
        setLoader(false);
        setJobDetail(JobDescriptionData);
        setSimilarJobs(JobDetailData);
      }
    }

    getJobDetail();
  }, [currentJobId, search]);

  // useEffect(() => {
  //   if (jobDetail) {
  //     scrollToHead();
  //   }
  // }, [UrlParams, jobDetail]);

  return (
    <>
      {jobDetail?.length > 0 || loader === false ? (
        <>
          <MetaTags metaData={metaData} />
          {jobDetail?.length > 0 &&   
          <>
            <span ref={headRef}>
             <JobHead hideQuesationBtn={false} jobDetail={jobDetail} qaStatus={qaStatus} />
            </span>
             <JobDescription jobDetail={jobDetail} qrCodeData={qrCodeData} shareUrl={shareUrl} />  
             <SimilarJobs
                showMsg={showMsg}
                similarJobs={similarJobs}
                onSearchChange={onSearchChange}
              />
              <WorldConnect />
              <LoginModel />
          </>          
          }                       
        </>
      ) : (
        <div className="loader-div">
          <span
            className="spinner-border spinner-border"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
};

export default ViewDetail;
