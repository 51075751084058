import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import { setClearFilter } from "../../../redux/slices/JobSearchSlice";
import { useSelector } from "react-redux";
import API from "../../../api";
import APIPvtDB from "../../../apiPvtD";
import JobRecordLeft from "../../../components/Common/JobRecord/JobRecordLeft";
import { setFavoriteJobsCount, setLoginModel } from "../../../redux/slices/AuthSlice";
import ActionModal from "../../../components/Common/ActionModal/ActionModal";
import "./index.css";
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";
import { ApplyJobSteps } from "../../../components/Common/JobRecord/ApplyJobSteps/Index";


const CanWishlist = () => {
  const initialValuesForSubmitData = {
    id: null,
    profile: false,
    salary: false,
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scroll = "search";
  const { user, isEmailVerify } = useSelector((state) => state.auth);
  const [msg, setMsg] = useState("");
  const [userWishList, setUserWishList] = useState([]);
  const [applyAllModal, setApplyAllModal] = useState(false);
  const [deleteAllModal, setDeleteAll] = useState(false);
  const [deleteJobModal, setDeleteJobModal] = useState(null);
  const [congratsModal, setCongratsModal] = useState(false);
  const [showApplyJobModal, setShowApplyJobModal] = useState(false);
  const [toggleApplied, setToggleApplied] = useState(false);
  const [submitData, setSubmitData] = useState(initialValuesForSubmitData);
  const [applyingToJob, setApplyingToJob] = useState(false);
  const [applyAllDone, setApplyAllDone] = useState(false);

  const showModal = (modalType) => {
    if (modalType === 'applyAll') {
      setApplyAllModal(true);
    } else {
      setDeleteAll(true);
    }
  };

  const closeModal = () => {
    setApplyAllModal(false);
    setDeleteAll(false);
    setCongratsModal(false);
    setDeleteJobModal(null);
    setApplyAllDone(false);
  };

  const handleYesButtonClicked = async (modalType) => {
    if (modalType === 'deleteAll') {
      if (user?.id && userWishList?.length > 0) {
        try {
          await APIPvtDB.get(`/auth/clear-all-wishlist-jobs`)
            .then((response) => {
              if (
                response?.status === 200 ||
                response?.status === 201 ||
                response?.status === 304
              ) {
                let res = response?.data;
                if (res?.result === true) {
                  setUserWishList([]);
                  dispatch(setFavoriteJobsCount(0));
                  closeModal();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    }

    if (modalType === 'applyAll') {
      if (user?.id && userWishList.length > 0) {
        try {
          await API.get(`/auth/apply-all-wishlist-jobs?user_id=${user?.id}`)
            .then((response) => {
              if (
                response?.status === 200 ||
                response?.status === 201 ||
                response?.status === 304
              ) {
                let res = response?.data;
                if (res?.result) {
                  setUserWishList([]);
                  closeModal();
                  setCongratsModal(true)
                  dispatch(setFavoriteJobsCount(0));
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    }

  };

  const getCandidateFavoriteJobs = async () => {
    if (user?.id) {
      try {
        setMsg("");
        await APIPvtDB.get(`/get-wishlist`)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;
              if (res?.result === true) {
                if (res?.favorite_jobs?.length === 0) {
                  setMsg("Please add jobs to wishlist");
                } else {
                  setMsg("");
                }
                setUserWishList(res?.favorite_jobs);
                dispatch(setFavoriteJobsCount(res?.favorite_jobs?.length));
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const removeFromFavoriteJobs = async (jobId) => {
    if (user?.id && jobId) {
      try {
        const formData = new FormData();
        formData.append("user_id", user?.id);
        formData.append("job_id", jobId);
        await API.post(`/update-whishlist`, formData)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;

              if (res?.result === true) {
                dispatch(setFavoriteJobsCount(res?.count));

                let newUserWishList = userWishList.filter(
                  (job) => job.id !== jobId
                );

                if (newUserWishList?.length === 0) {
                  setMsg("Please add jobs to wishlist");
                } else {
                  setMsg("");
                }

                setDeleteJobModal(null)

                setUserWishList(newUserWishList);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getCandidateFavoriteJobs();
  }, []);

  const onDetailJobHandler = (JobData) => {
    const hashId = JobData?.hashed_id;
    const slug = JobData?.slug;
    if (slug && hashId) {
      navigate(`/jobs/${slug}/?currentJobId=${hashId}`);
    }
  };

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, [])

  if (userWishList.length === 0 && msg !== "") {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return (
      <>
        <section className="wishlist-sec">
          <div className="job-heading">
            <div className="container-site">
              <h2>My wishlist</h2>
            </div>
          </div>
          <div className="container-site">
            <p class="error-wishlist">{msg}</p>
          </div>
        </section>
        <WorldConnect />
      </>
    );
  }

  const applyNowHandler = () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    }
    else {
      setShowApplyJobModal(true);
    }
  };

  const handleSubmit = async (resume_id, include_profile, show_expected_salary) => {
    setApplyingToJob(true);
    await APIPvtDB.get(`/auth/apply-all-wishlist-jobs-new?resume_id=${resume_id}&include_profile=${include_profile}&show_expected_salary=${show_expected_salary}`)
      .then((response) => {
        const res = response?.data;
        if (res?.result === true) {
          setToggleApplied(true);
          setShowApplyJobModal(false);
          setSubmitData(initialValuesForSubmitData);
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          setApplyAllDone(true)
          getCandidateFavoriteJobs();
        }
        setApplyingToJob(false);
      })
      .catch((error) => {
        console.log(error);
        setApplyingToJob(false);
      });
  };

  return (
    <>
      <section className="wishlist-sec pt-0">
        <div className="container container-site py-2">
          <Button variant="link" className="back-to-home add top-0 position-relative" onClick={() => navigate(-1)}>
            <img src="/image/form-back-arrow.png" alt="" />
          </Button>
        </div>
        <div className="job-heading">
          <div className="container-site">
            <h2>My wishlist</h2>
          </div>
        </div>
        <div className="container-site position-relative mt-md-5 mt-3">
          {userWishList.length > 0 &&
            userWishList.map((job, index) => (
              <Fragment key={job?.id}>
                {index != 0 ? <div className="divider mb-md-0 mb-3"></div> : ''}
                <div className="Job-Opportunities-row">
                  <JobRecordLeft JobData={job} />
                  <div className="job-announcements">
                    <div className="job-announcements-col-candidate">
                      <a onClick={() => onDetailJobHandler(job)}>
                        <button>View job</button>
                      </a>
                    </div>
                    <div className="job-wishlist-btn">
                      <button onClick={() => setDeleteJobModal(job)}>
                        Delete from list
                      </button>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          {/* <div className="divider"></div> */}
        </div>
      </section>
      {userWishList.length !== 0 && msg === "" && (
        <section className="wishlist-btn-sec">
          <div className="container-site">
            <div className="whishlist-three-btn-stack">
              <div className="wishlist-apply-btn whishlist-btn">
                {/* <button onClick={() => showModal('applyAll')}>Apply All</button> */}
                <button onClick={() => applyNowHandler()}>Apply All</button>
              </div>
              <div className="wishlist-clear-btn whishlist-btn">
                <button onClick={() => showModal('deleteAll')}>Clear All</button>
              </div>
              <div className="wishlist-findjob-btn whishlist-btn">
                <Link
                  to={`/job-search/${scroll}`}
                  onClick={() => dispatch(setClearFilter())}
                >
                  <button>Find Another Job</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}

      <WorldConnect />
      {deleteAllModal ? (
        <>
          <ActionModal
            closeModal={closeModal}
            modalArt='/image/warning-wishlist.png'
            heading='Double-Check'
            content='Are you sure you want to clear your wish list?'>
            <button className="btn btn-black" onClick={() => closeModal()}>No, keep it.</button>
            <button className="btn btn-outline-black" onClick={() => handleYesButtonClicked('deleteAll')}>Yes, clear it.</button>
          </ActionModal>
        </>
      ) : null}


      {deleteJobModal ? (
        <>
          <ActionModal
            closeModal={closeModal}
            modalArt='/image/warning-wishlist.png'
            heading='Double-Check'
            content='Are you sure you want to delete it from your wish list?'>
            <button className="btn btn-black" onClick={() => closeModal()}>No, keep it.</button>
            <button className="btn btn-outline-black" onClick={() => removeFromFavoriteJobs(deleteJobModal.id)}>Yes, delete it.</button>
          </ActionModal>
        </>
      ) : null}
      {applyAllModal ? (
        <>
          <ActionModal
            closeModal={closeModal}
            modalArt='/image/warning-wishlist.png'
            heading='Double-Check'
            content='Are your sure you want to apply to all the jobs in the wishlist?'>
            <button className="btn btn-black" onClick={() => closeModal()}>No, keep it.</button>
            <button className="btn btn-outline-black" onClick={() => handleYesButtonClicked('applyAll')}>Yes, apply all.</button>
          </ActionModal>
        </>
      ) : null}
      {congratsModal ? (
        <>
          <ActionModal
            closeModal={closeModal}
            modalArt='/image/congrats.png'
            mainHeading='Congratulations!'
            heading='You’ve taken a big step forward!'
            content='You’ve successfully applied for all jobs.'>
          </ActionModal>
        </>
      ) : null
      }
      {showApplyJobModal &&
        <ApplyJobSteps
          setSubmitData={(data) => {
            setSubmitData(data);
          }}
          fromWishList='true'
          submitData={submitData}
          handleSubmit={handleSubmit}
          applyingToJob={applyingToJob}
          show={showApplyJobModal}
          onHide={() => setShowApplyJobModal(false)}
        />
      }
      {applyAllDone &&
        <ActionModal
          closeModal={closeModal}
          modalArt='/image/congrat.png'
          mainHeading='Congratulations!'
          heading='You’ve taken a big step forward!'
          content="You've successfully applied for all jobs.">
        </ActionModal>
      }
    </>
  );
};

export default CanWishlist;
