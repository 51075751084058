import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import GoogleAuth from "../../GoogleAuth";
import {
  setActiveTab,
  setEmailVerify,
  setFavoriteJobsCount,
  setLoginUser,
  setLoginUserName,
  setResumeDetail,
  resendVerifyEmailAsync,
  setLoginUserCountry,
  setUserResumes,
} from "../../../../redux/slices/AuthSlice";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../../redux/slices/AdminDashboardSlice";
import { ADMIN, CANDIDATE, EMPLOYER } from "../../../../constants";
import { getResumeForApplyingOnJob } from "../../../../redux/slices/candidateDetailSlice";
import API from "../../../../api";
import ActionModal from "../../../Common/ActionModal/ActionModal";
import "./LoginForm.css";


const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [locationValidModel, setLocationValidModel] = useState(false);
  const [domainUrl, setDomainUrl] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const getResumes = async (token) => {
    dispatch(
      getResumeForApplyingOnJob({token: token})
    )
      .then(async (response) => {
        const res = response?.payload?.data;
        dispatch(setUserResumes(res));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Please enter email"),
      password: Yup.string().required("Please enter password"),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { email, password } = data;
      try {
        const formData = new FormData();
        formData.append("email", email.trim());
        formData.append("password", password.trim());
        setLoader(true);

        await API.post(`/auth/login`, formData)
          .then(async (response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              const { location_valid, valid_location } = response?.data;
              const user = response?.data?.user;
              const country = user?.country;

              if (location_valid === false) {
                setDomainUrl(valid_location);
                setLocationValidModel(true);         
                setLoader(false);      
                return;
              }

              if (res?.result === true) {
                dispatch(setLoginUser(res));
                dispatch(setLoginUserName(user?.first_name));
                await getResumes(res?.access_token);
                dispatch(
                  setLoginUserCountry({
                    id: country?.id,
                    name: country?.name,
                  })
                );
                dispatch(setEmailVerify(true));
                dispatch(
                  setResumeDetail({
                    resumeId: user?.resume_id,
                    resumeType: user?.resume_type,
                  })
                );
                dispatch(setFavoriteJobsCount(res?.favorite_job_count));
                if (res?.permissions)
                  dispatch(setdefaultPermissions(res?.permissions));
                localStorage.setItem("token", res?.access_token);
                if (
                  res?.user?.user_type &&
                  CANDIDATE.includes(res?.user?.user_type)
                ) {                          
                    navigate("/candidate-dashboard");                  
                } else if (
                  res?.user?.user_type &&
                  EMPLOYER.includes(res?.user?.user_type)
                )
                  navigate("/employer-dashboard");
                else if (
                  res?.user?.user_type &&
                  ADMIN.includes(res?.user?.user_type)
                ) {
                  dispatch(
                    setActiveSideBar({
                      id: "2",
                      name: "Department",
                      value: "Department",
                    })
                  );
                  navigate("/admin-department");
                }
              }
              setLoader(false);
            }
          })
          .catch((error) => {
            if (error) {
              const { response } = error;
              const { data } = response;
              setErrorMsg(data?.message?.error);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
              console.log(error);
            }
            setLoader(false);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const resendVerifyEmailHandler = async () => {
    if (formik?.values?.email === "" || isFormFieldValid("email")) {
      formik.touched["email"] = true;
      isFormFieldValid("email");
      formik?.setFieldError("email", formik?.errors?.email);
      return;
    } else {
      dispatch(
        resendVerifyEmailAsync({
          email: formik?.values?.email,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          } else {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
          console.log(error);
        });
    }
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const locationValidModelHandler = (value) => {
    setLocationValidModel(value);
  };
  const onSignUpHandler = () => {
    dispatch(setActiveTab("candidate"));
  };

  return (
    <>
      <section className="candiate-login">
        <div className="container container-site">
          <div className="candidate-login-row">
            <div className="candiate-login-form">
              <div className="candidate-signin">
                <h2>Sign in</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div
                    className={`candidate-username ${
                      isFormFieldValid("email") && "error-input"
                    }`}
                  >
                    <input
                      className="form-font-f"
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("email")}
                  </div>

                  <div className="candidate-password">
                    <div
                      className={`signup-passowrd  ${
                        isFormFieldValid("password") && "error-input"
                      }`}
                    >
                      <div className="password-icon bg-pass-f">
                        <input
                          className="form-font-f"
                          id="password-field"
                          type={passwordShown ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <div className="icon-hide">
                          {isFormFieldValid("password") ? (
                            <img src="/image/warning.png" alt="" />
                          ) : (
                            <>
                              <img
                                src="/image/hide-password.png"
                                alt=""
                                className={
                                  passwordShown && "password-icon-togle"
                                }
                                onClick={() => setPasswordShown(true)}
                              />
                              <img
                                src="/image/password.png"
                                alt=""
                                className={
                                  !passwordShown && "password-icon-togle"
                                }
                                onClick={() => setPasswordShown(false)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      {getFormErrorMessage("password")}
                    </div>

                    <div className="remember-forget-password">
                      <input
                        id="rememberme"
                        name="rememberme"
                        value="remember"
                        type="checkbox"
                      />{" "}
                      &nbsp;Remember me
                      <Link to="/forgot-password">
                        <p className="forget-password">Forgot password?</p>
                      </Link>
                    </div>
                    <div className="heading-below-p">
                      <p className="heading-below-text">{errorMsg}</p>
                    </div>
                    <div className="candidate-from-submit">
                      <button type="submit" className="form-submit">
                        {loader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Sign in"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="signup-here">
                    <p>
                      Don’t have an account?{" "}
                      <Link to="/signup" onClick={() => onSignUpHandler()}>
                        <span>Sign up</span>
                      </Link>
                    </p>
                  </div>
                  <div className="or-divider">
                    <div className="divider-one"></div>
                    <div className="text-or">Or</div>
                    <div className="divider-one"></div>
                  </div>
                  <div className="mt-3 d-none">
                    <GoogleAuth />
                  </div>                
                  <div className="signup-here">
                    <p>
                      Haven't received the confirmation email yet?{" "}
                      <Link href="#" onClick={() => resendVerifyEmailHandler()}>
                        <span>Resend Email</span>
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {locationValidModel && (
        <ActionModal
          modalArt="/image/warning-wishlist.png"
          heading="Invalid location"
          content={`Your account does not exist on this domain. Please login to your local domain`}
          closeModal={locationValidModelHandler}
        >
          <div className="d-flex justify-content-center w-100">
            <a
              href={domainUrl === "" ? "#" : domainUrl}
              className="domain-url-login"
            >
              {domainUrl}
            </a>
          </div>
        </ActionModal>
      )}
    </>
  );
};

export default LoginForm;
