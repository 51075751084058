import "./MostSearchJob.css";

const MostSearchJob = ({ onMostSearchjobClick, mostSearchedJobs }) => {
  if (mostSearchedJobs?.length === 0) return null;

  return (
    <>
      {" "}
      <section className="job-search-sec">
        <div className="container container-site">
          <div className="job-search-tag-heading d-none">
            <h2>Most search jobs</h2>
          </div>
          <div className="most-job-search-tag">
            <p className="d-md-block d-none">Most searched jobs:</p>
            <ul className="find-job-tag m-0">
              <li className="d-md-none d-inline">
                <p>Most searched jobs:</p>
              </li>
              {mostSearchedJobs?.length > 0 &&
                mostSearchedJobs.map((tag) => (
                  <li
                    className="icon-cursor"
                    onClick={() => onMostSearchjobClick(tag?.query)}
                  >
                    {tag?.query}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default MostSearchJob;
