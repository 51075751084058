import { useEffect, useState, Fragment, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import CommentRightCard from "./CommentRightCard";
import {
  addJobCommentAsync,
  fetchJobCommentsAsync,
  removeJobCommentAsync,
  setComments,
} from "../../../redux/slices/commentModalSlice";
import {
  formatCurrDate,
  formatRelativeDate,
  formatTimestamp,
} from "../../../utils/formatDate";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { current } from "@reduxjs/toolkit";
import "./CommentModel.css";

const CommentModel = ({
  modelCHandler,
  commentAdded,
  commentRemoved,
}) => {
  const dispatch = useDispatch();
  const { comments, jobId, candidateId } = useSelector(
    (state) => state.comments,
  );
  const { user } = useSelector((state) => state.auth);

  const [commentss, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [toggleEmojiPicker, setToggleEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(true);

  // Create a ref for the modal container
  const modalRef = useRef(null);

  useEffect(() => {
    // Scroll the modal to the bottom when it opens
    console.log(user)
    fetchComments();
  }, []);

  const scrollToBottom = () => {
    if (modalRef.current) {
      modalRef.current.scrollTop = modalRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [commentss, toggleEmojiPicker]);

  const removeCommentClickHandler = (commentId) => {
    if (
      commentId === undefined ||
      commentId === null ||
      commentId === "" ||
      commentId <= 0
    )
      return;

    dispatch(
      removeJobCommentAsync({
        commentId: commentId,
        candidateId: candidateId,
        jobId: jobId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          setComments(res?.data);
          commentRemoved(
            commentId,
            res?.lastComment[res?.lastComment?.length - 1] ?? ""
          );

          // Use the map method to update the data structure
          /*const updatedComments = commentss?.map((item) => {
            // Check if the 'comments' property exists and is an array
            if (Array.isArray(item?.comments)) {
              // Use filter to remove the comment with the specified ID
              item.comments = item?.comments?.filter(
                (comment) => comment.id !== commentId,
              );
            }
            return item;
          });*/

          // setComments(updatedComments);
          // const lastArray = commentss[commentss?.length - 1]?.comments;
          // const lastComment = lastArray[lastArray?.length - 1];
          // commentRemoved(commentId, lastComment ?? "");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleEmojiPickerHandler = () => {
    setToggleEmojiPicker((current) => !current);
    scrollToBottom();
  };

  const addEmojiToInput = (e) => {
    setCommentText((current) => current + e.native);
  };

  const handleCommentBoxChange = (e) => {
    const value = e.target.value;

    setCommentText(value);
    if (value === null || value === undefined || value === "") {
      setDisableSubmit(true);
      return;
    }
    setDisableSubmit(false);
  };

  const clearCommentBox = () => setCommentText("");

  const handleSubmit = () => {
    if (jobId === null || jobId === undefined || jobId === "") return;
    if (candidateId === null || candidateId === undefined || candidateId === "")
      return;
    if (user?.id === null || user?.id === undefined || user?.id === "") return;
    if (commentText === null || commentText === undefined || commentText === "")
      return;

    dispatch(
      addJobCommentAsync({
        jobId: jobId,
        candidateId: candidateId,
        userId: user?.id,
        commentText: commentText,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          setComments(res?.data);
          const data = res?.data;
          const lastArray = data[data?.length - 1]?.comments;
          const lastComment = lastArray[lastArray?.length - 1];
          commentAdded(candidateId, jobId, lastComment);
          scrollToBottom();
        }
      })
      .catch((error) => {
        console.log(error);
      });

    clearCommentBox();
  };

  const fetchComments = async () => {
    if (
      jobId === null ||
      jobId === undefined ||
      jobId === "" ||
      candidateId === null ||
      candidateId === undefined ||
      candidateId === ""
    ) {
      return;
    }
    dispatch(fetchJobCommentsAsync({ jobId, candidateId }))
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setComments(res?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (loading === true) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  };

  return (
    <div className="click-model-close">
      <section className="model-comment-popup">
        <div className="popup-bg">
          <OutsideClickHandler
            onOutsideClick={() => {
              modelCHandler(false);
            }}
          >
            <div className="popup-chat-model-user">
              <div className="header-bar-comment">
                <div className="clinet-profile size-w-d">
                  <img src="/image/form-back-arrow.png" alt="" />
                  <img src="/image/client-img.png" alt="" />
                  <div className="clint-info">
                    <p className="name-clinet">
                      {user?.first_name + " " + user?.last_name}
                    </p>
                    {/* <p className="clinet-rank">{user.user_type}</p> */}
                  </div>
                </div>
                <div className="comment-img-b size-w-d d-md-flex d-none">
                  <p>Comments</p>
                  <img src="/image/com-img.png" alt="" />
                </div>
                <div
                  className="close-img-comment size-w-d"
                  onClick={() => modelCHandler(false)}
                >
                  <img src="/image/cut-black.png" alt="" />
                </div>
              </div>
              <div className="chat-model-comment-box" ref={modalRef}>
                <div className="position-relative">
                  <div className="all-chat-comment-m">
                    {commentss?.map((value, index) => (
                      <Fragment key={index}>

                        <div className="comment-model-title">
                          <hr />
                          <div className="chat-date-b">
                            <h1>{formatRelativeDate(value.day)}</h1>
                          </div>
                        </div>

                        {value?.comments?.map((comment, index) => (
                          <Fragment key={index}>
                            {comment?.user_id === user?.id ? (
                              <>
                                <CommentRightCard
                                  comment={comment}
                                  handleSubmit={fetchComments}
                                  removeCommentClickHandler={
                                    removeCommentClickHandler
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <div className="comment-chat-model">
                                  <img
                                    src={
                                      comment?.avatar_link ??
                                      "/image/client-img.png"
                                    }
                                    alt=""
                                    className="model-avtar p-1"
                                    style={{ border: "1px solid black" }}
                                  />
                                  <div className="chat-comment-box">
                                    <div className="comment-add-box">
                                      <div className="comment-owner-details">
                                        <p className="name-comment-owner">
                                          {comment?.first_name +
                                            " " +
                                            comment?.last_name}
                                        </p>
                                      </div>
                                    </div>
                                    <p className="left-chat-c">
                                      <p>{comment?.comments}</p>
                                    </p>

                                    {/* <div className="chat-reply">
                                      <p>Replyyyyy</p>
                                      <img src="/image/comment-reply.png" alt="" />
                                    </div> */}
                                  </div>
                                  <p className="time-chat-left">
                                    {" "}
                                    {formatTimestamp(comment?.created_at)}
                                  </p>
                                </div>
                              </>
                            )}
                          </Fragment>
                        ))}
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <div className="chat-model-footer position-relative">
                <div className="emoji-container">
                  {toggleEmojiPicker === true ? (
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setToggleEmojiPicker(false);
                      }}
                    >
                      <Picker
                        data={data}
                        theme="light"
                        onEmojiSelect={(e) => addEmojiToInput(e)}
                      />
                    </OutsideClickHandler>
                  ) : null}
                </div>
                <div className="chat-comment-f">
                  <div className="chat-form">
                    {/* <img src="/image/attchment.png " alt=" " /> */}
                    <input
                      type="text"
                      name=""
                      id="comment-f"
                      placeholder="Type here..."
                      value={commentText}
                      onChange={handleCommentBoxChange}
                      onKeyDown={handleKeyPress}
                    />
                    <img
                      src="/image/ssmile.png"
                      alt=""
                      onClick={() => toggleEmojiPickerHandler()}
                    />
                  </div>
                  <div className="send-comment icon-cursor">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!commentText.length}
                    >
                      <img src="/image/send-chat.png" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </section>
    </div>
  );
};

export default CommentModel;
