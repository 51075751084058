import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Player, BigPlayButton } from "video-react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import { getColor } from "../../../../utils/progressbarFn";
import "./CanProfileView.css";
import "video-react/dist/video-react.css";
import formatFileSize from "../../../../utils/formatFileSize";

const CanProfileView = ({
  baseInfo,
  jobTitles,
  workPreferences,
  salaryRange,
  countryOptions,
  tags,
  onSubmitAboutSelf,
  onSubmitInterested,
  onSubmitIntroVideo,
}) => {
  const [aboutSelf, setAboutSelf] = useState("");
  const [interested, setInterested] = useState("");
  const [selectedVideoName, setSelectedVideoName] = useState("");
  const [fileSize, setFileSize] = useState(null); 
  const [introVideo, setIntroVideo] = useState(null);

  const progressValue = parseInt(baseInfo?.percentage, 10);
  const safeProgressValue = isNaN(progressValue) ? 0 : progressValue;

  const formik = useFormik({
    initialValues: {
      introVideo: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      introVideo: Yup.mixed()
        .test(
          "fileType",
          "Unsupported file format",
          (value) =>
            !value ||
            [
              "video/mp4",
              "video/webm",
              "video/x-matroska",
              "video/3gpp",
            ].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 25 MB",
          (value) => !value || value?.size <= 25 * 1024 * 1024
        ),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      const { introVideo } = data;
      if (introVideo !== null) onSubmitIntroVideo(introVideo);
    },
  });

  const isFileFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFileErrorMessage = (name) => {
    return (
      isFileFieldValid(name) && (
        <p className="file-error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const aboutSelfClickHandler = () => {
    onSubmitAboutSelf(aboutSelf);
  };

  const interestedClickHandler = () => {
    onSubmitInterested(interested);
  };

  const handleVideoChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedVideoName(file.name);
        setFileSize(file.size);
        formik.setFieldValue("introVideo", file);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedVideoName("");
      setFileSize(null);
      formik.setFieldValue("introVideo", null);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])

  return (
    <>
      <div className="profile-sec-view">
        <div className="profile-row">
          {baseInfo?.avatar === null || baseInfo?.avatar === undefined ? (
            <div className="candidtae-profile-image-avatar candidate-viewP-mbl">
              <img src="/image/avtar.jpg" alt="" />
              <div className="addProfile-icon-view-mbl d-none">
                <img src="/image/addProfile.png" alt="" />
              </div>
            </div>
          ) : (
            <div className="candidtae-profile-image-avatar candidate-viewP-mbl">
              <img src={baseInfo?.avatar} alt="" />
              <div className="addProfile-icon-view-mbl d-none">
                <img src="/image/addProfile.png" alt="" />
              </div>
            </div>
          )}
          <div className="candidate-information">
          <div className="candidate-profile-date-after">
              <div className="d-flex align-items-center">
              <h5>{`${capitalizeFirstLetter(
                baseInfo?.first_name
              )}  ${baseInfo?.last_name}`}</h5>
              {" "}
              <div className="prog-bar-cir ml-5">
                <div
                  style={{
                    width: 50,
                    height: 50,
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <CircularProgressbar
                    minValue="0"
                    strokeWidth={12}
                    value={safeProgressValue}
                    text={`${safeProgressValue}%`}
                    counterClockwise={false}
                    styles={buildStyles({
                      strokeLinecap: "round",
                      pathTransitionDuration: 0.5,
                      pathColor: getColor(safeProgressValue),
                      textColor: getColor(safeProgressValue),
                      textSize: "24px",
                      trailColor: "#d6d6d6",
                    })}
                  />
                </div>
          </div>
        </div>

              {baseInfo?.country && (
                <div className="candiate-info-heading">
                  <Link href="#">
                    <img
                      src="/image/profile-location.png"
                      className="location-icon"
                      alt=""
                    />
                    <p className="candidate-info-text location-name-local userName-mb ml-0">
                    {baseInfo?.city !== "" ? baseInfo?.city + ", ": ""}
                    {baseInfo?.state !== "" ? baseInfo?.state + ", ": ""}              
                    {baseInfo?.country !== "" ? baseInfo?.country : ""}
                    </p>
                  </Link>
                </div>
              )}

              {baseInfo?.email && (
                <div className="candiate-info-heading">
                  <a  href={`mailto:${baseInfo?.email}`}>
                    <img
                      src="/image/profile-email.png"
                      className="email-icon"
                      alt=""
                    />
                    <p className="candidate-info-text">{baseInfo?.email}</p>
                  </a>
                </div>
              )}

          
                <div className="candiate-info-heading">
                  <a>
                    <img
                      src="../image/profile-phone.png"
                      className="profile-icon"
                      alt=""
                    />
                  {baseInfo?.phone ? 
                  (
                    <p className="candidate-info-text">{baseInfo?.phone}</p>
                  )
                  :
                   <p className="candidate-info-text opacity-50">Phone no.</p>
                  }
                  </a>
                </div>
           
                <div className="candiate-info-heading">
                  <a href={baseInfo?.linkedin} target="_blank">
                    <img
                      src="/image/profile-linked.png"
                      className="profile-icon"
                      alt=""
                    />       
                    {baseInfo?.linkedin ? 
                  (
                    <p className="candidate-info-text">{baseInfo?.linkedin}</p>
                  )
                  :
                   <p className="candidate-info-text opacity-50">LinkedIn ID.</p>
                  }
                   </a>
                </div>          
            </div>
          </div>
        </div>


        <div className="profile-row d-none">
          <div className="candidate-information with-accordion divider-top-tabdata divider-mobile">
            <div
              className={`accordion-candidate-prfile py-3 ${(jobTitles?.length !== 0 || tags?.length !== 0) &&
                "divider-bottom-tabdata"
                }`}
              id="myAccordionCandidateCandidate"
            >
              {baseInfo?.bio !== null ? (
                <div className="accordion-item">
                  <div className="candidate-profile-datefill">About Me</div>
                  <div className="card-body">
                    <p className="candidate-profile-data">{baseInfo?.bio} </p>
                  </div>
                </div>
              ) : (
                <div className="accordion-item border-0 p-0">
                  <div className="accordion-header " id="headingOne">
                    <button
                      type="button"
                      className="accordion-button collapsed accordian-profile-res mb-0"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                    >
                      Tell us about yourself
                      <i className="plus-icon">
                        <img src='/image/plus.png' alt='plus-icon' />
                      </i>
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse mt-3"
                    data-bs-parent="#myAccordionCandidate"
                  >
                    <div className="card-body">
                      <textarea
                        className="form-font-f"
                        id="candidate-profile-tabs"
                        cols="30"
                        rows="10"
                        name="aboutSelf"
                        value={aboutSelf}
                        onChange={(e) => {
                          setAboutSelf(e.target.value);
                        }}
                      />
                      <p className="candidate-profile-tabs-msg">100 words limit</p>
                      <div className="save-cancel-btn">
                        <button
                          className="cancel-btn-profile"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          Cancel
                        </button>
                        <button
                          className="save-btn-profile"
                          onClick={() => aboutSelfClickHandler()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {baseInfo?.my_interests !== null ? (
                <div className="accordion-item">
                  <div className="candidate-profile-datefill ">
                    Things I like to do.
                  </div>
                  <div className="card-body">
                    {/* <ul className="candidate-profile-data-list">
                  {baseInfo?.my_interests}{" "}
                </ul> */}
                    <p className="candidate-profile-data">
                      {baseInfo?.my_interests}{" "}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="accordion-item border-0 p-0 my-3">
                  <div className="accordion-header" id="headingTwo">
                    <button
                      type="button"
                      className="accordion-button collapsed accordian-profile-res mb-0"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                    >
                      Things I like to do.
                      <i className="plus-icon">
                        <img src='/image/plus.png' alt='plus-icon' />
                      </i>
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse mt-3"
                    data-bs-parent="#myAccordionCandidate"
                  >
                    <div className="card-body">
                      <textarea
                        className="form-font-f"
                        id="candidate-profile-tabs"
                        cols="30"
                        rows="10"
                        name="interested"
                        value={interested}
                        onChange={(e) => {
                          setInterested(e.target.value);
                        }}
                      ></textarea>
                      <p className="candidate-profile-tabs-msg">100 words limit</p>
                      <div className="save-cancel-btn">
                        <button
                          className="cancel-btn-profile"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                        >
                          Cancel
                        </button>
                        <button
                          className="save-btn-profile"
                          onClick={() => interestedClickHandler()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {baseInfo?.intro_video_link !== null ? (
                <div className="accordion-item">
                  <div className="candidate-profile-datefill">
                    Short Intro video
                  </div>
                  <div className="card-body">
                    <div className="intro-profile-vedio-chek">
                      <div className="upload-candidate-intro-vedio">
                        <Player src={baseInfo?.intro_video_link}>
                          <BigPlayButton position="center" />
                        </Player>
                      </div>
                      <div className="check-box-vedio">
                        <div className="chek-box-one">
                          <input type="checkbox" name="" id="" />
                          &nbsp;Send video with every job application
                        </div>
                        <div className="check-box-two">
                          <input type="checkbox" name="" id="" />
                          &nbsp;Ask before applying for job
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <div className="accordion-item border-0 p-0">
                    <div className="accordion-header" id="headingThree">
                      <button
                        type="button"
                        className="accordion-button collapsed accordian-profile-res mb-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        Short Intro video
                        <i className="plus-icon">
                          <img src='/image/plus.png' alt='plus-icon' />
                        </i>
                      </button>
                    </div>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse mt-3"
                      data-bs-parent="#myAccordionCandidate"
                    >
                      <div className="card-body">
                        <div className="upload-intro-vedio">
                          <label for="file-input" id="candidate-img">
                            <img src="/image/upload-1.png" alt="pic" className="upload-profile-img" />
                            <input
                              id="file-input"
                              type="file"
                              name="introVideo"
                              onClick={handleVideoChange}
                              onChange={handleVideoChange}
                            />
                            <p className="candidate-logo-file">{selectedVideoName ? "Replace": "Upload here"}</p>                         
                            {selectedVideoName ? 
                            <></>
                            :   
                          <p className="file-size-candidate">
                            Format: .mp4, .webm, .mkv, .3gp{" "}
                          </p>
                           }     
                            <p className='file-name-candidate'>{selectedVideoName}</p> 
                            {fileSize ?  
                               <p className="file-size-selected-candidate">File size: {formatFileSize(fileSize)}</p>   
                            :  <p className="file-size-candidate">File size max: 25MB</p>   
                            }                         
                            {getFileErrorMessage("introVideo")}
                          </label>
                        </div>
                        <div className="save-cancel-btn">
                          <button
                            className="cancel-btn-profile"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                          >
                            Cancel
                          </button>
                          <button className="save-btn-profile" type="submit">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>

            {(jobTitles?.length !== 0 || tags?.length !== 0) && (
              <div className="preferences-row mt-2">
                <h5>My Preferences</h5>
              </div>
            )}

            <div className="signup-job-titles f-feild tags-tittles">
              <label for="fjobtitles">Salary Expectation</label>
              <br />

              {baseInfo?.expected_salary !== null ?
                <p className="candidate-info-text">{baseInfo?.currency?.length > 0 ? baseInfo?.currency[0]?.title : 'MKD'} {baseInfo?.expected_salary} per {salaryRange ? salaryRange : 'Year'}</p>
                : '-'}
            </div>

            {workPreferences?.length !== 0 ?
              <div className="signup-job-titles f-feild tags-tittles">
                <label for="fjobtitles">Work Preferences</label>
                <br />

                <ul className="tags-titles-list">
                  {workPreferences?.map((j, i) => (
                    <li>{j?.name}</li>
                  ))}
                </ul>
              </div>
              : ''}

            {jobTitles?.length > 0 && (
              <div className="signup-job-titles f-feild tags-tittles">
                <label for="fjobtitles">Job Titles I’m interested in</label>
                <br />

                <ul className="tags-titles-list">
                  {jobTitles.map((j, i) => (
                    <li>{j?.name}</li>
                  ))}
                </ul>
              </div>
            )}

            {tags?.length > 0 && (
              <div className="signup-job-titles f-feild tags-tittles">
                <label for="fjobtitles">Tech Tags</label>
                <br />
                <ul className="tags-titles-list">
                  {tags.map((j, i) => (
                    <li>{j?.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CanProfileView;
