import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {};

const CanQASlice = createSlice({
  name: "CanQA",
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function
export const {} = CanQASlice.actions;

export default CanQASlice.reducer;


// asynchronous function with createAsyncThunk
export const getCanJobQuesationAsync = createAsyncThunk(
  "can/getCanJobQuesation",
  async ({ currentJobId }) => {
    let params = `?`;
    if (currentJobId !== null) {
      params += `currentJobId=${currentJobId}`;
    }

    try {
      const response = await API.get(`/job-board/get-job-questions${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);



export const saveCanSolutionAsync = createAsyncThunk(
  "candidate/saveCanSolution",
  async ({ job_id, candidate_id, screening_questions }) => {
    const rawData = {
      job_id: job_id,
      candidate_id: candidate_id,
      screening_questions: screening_questions
    };

    try {
      const response = await API.post("/job-board/save-candidate-solution", rawData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
