import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";
import { CONVERSATION_STATUS } from "../../constants";

const initialState = {
  selectedInboxUser: {},
};
const InboxSlice = createSlice({
  name: "Inbox",
  initialState,
  reducers: {
    setInboxUser: (state, action) => {
      state.selectedInboxUser = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInboxUser } = InboxSlice.actions;

export default InboxSlice.reducer;

// asynchronous function with createAsyncThunk
export const getInboxAllUserAsync = createAsyncThunk(
  "emp/getInboxAllUser",
  async ({ userId, search, job_id, status }) => {
    const formData = new FormData();
    userId && formData.append("user_id", userId);
    search !== "" && formData.append("search", search);
    job_id !== null && formData.append("job_id", job_id);
    status === CONVERSATION_STATUS.UNREAD && formData.append("is_unread", 1);
    status === CONVERSATION_STATUS.PINNED && formData.append("is_pinned", 1);

    try {
      const response = await API.post(`/fetch-inbox`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getInboxUserMsgAsync = createAsyncThunk(
  "emp/getInboxUserMsg",
  async ({ conversation_id }) => {
    const formData = new FormData();
    conversation_id && formData.append("conversation_id", conversation_id);

    try {
      const response = await API.post(`/fetch-all-messages`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const saveInboxUserMsgAsync = createAsyncThunk(
  "emp/saveInboxUser",
  async ({ conversation_id, message, fileUpload }) => {
    const formData = new FormData();
    conversation_id && formData.append("conversation_id", conversation_id);
    message && formData.append("message", message);
    fileUpload && formData.append("file", fileUpload);

    try {
      const response = await API.post(`/send-message`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getInvitesInboxDataAsync = createAsyncThunk(
  "emp/getInvitesInboxData",
  async () => {
    try {
      const response = await API.get(`/fetchInvitesData`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCandidatesInboxDataAsync = createAsyncThunk(
  "emp/fetchCandidatesInboxData",
  async ({ search, job_id, city_id }) => {
    const formData = new FormData();
    search && formData.append("search", search);
    job_id && formData.append("job_id", job_id);
    city_id && formData.append("city_id", city_id);

    try {
      const response = await API.post(`/fetchCandidates`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const fetchEmployersInboxDataAsync = createAsyncThunk(
  "emp/fetchEmployersInboxData",
  async ({ search, my_id, company_id }) => {
    const formData = new FormData();
    search && formData.append("search_word", search);
    my_id && formData.append("my_id", my_id);
    company_id && formData.append("company_id", company_id);

    try {
      const response = await API.post(
        `/job-board/auto-search-company-user`,
        formData
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const startChatDataAsync = createAsyncThunk(
  "emp/startChatData",
  async ({ job_id, candidate_id, user_id }) => {
    
    const formData = new FormData();
    job_id && formData.append("job_id", job_id);
    candidate_id && formData.append("candidate_id", candidate_id);
    user_id !== undefined &&
    Object.keys(user_id).forEach((key) => {
      formData.append(`user_ids[]`, user_id[key]);
    });


    try {
      const response = await API.post(`/startConversation`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const addMemberConversationDataAsync = createAsyncThunk(
  "emp/startChatData",
  async ({ conversation_id, user_id }) => {
    const formData = new FormData();
    conversation_id && formData.append("conversation_id", conversation_id);
    user_id !== null &&
      Object.keys(user_id).forEach((key) => {
        formData.append(`user_ids[]`, user_id[key]);
      });

    try {
      const response = await API.post(`/add-members-to-conversation`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const pinUnpinInboxDataAsync = createAsyncThunk(
  "emp/pinUnpinInboxData",
  async ({ conversation_id }) => {
    const formData = new FormData();
    conversation_id && formData.append("conversation_id", conversation_id);

    try {
      const response = await API.post(`pin-unpin-conversation`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const markAsReadInboxDataAsync = createAsyncThunk(
  "emp/markAsReadInboxData",
  async ({ conversation_id }) => {
    const formData = new FormData();
    conversation_id && formData.append("conversation_id", conversation_id);

    try {
      const response = await API.post(`/mark-conversation-as-read`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteInboxConversationDataAsync = createAsyncThunk(
  "emp/deleteInboxConversationData",
  async ({ conversation_id }) => {
    try {
      const response = await API.delete(
        `/delete-conversation/${conversation_id}`
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteInboxChatDataAsync = createAsyncThunk(
  "emp/deleteInboxChatData",
  async ({ message_id }) => {
    const formData = new FormData();
    message_id && formData.append("message_id", message_id);

    try {
      const response = await API.post(`/delete-message`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const editInboxChatDataAsync = createAsyncThunk(
  "emp/editInboxChatData",
  async ({ message_id, message }) => {
    const formData = new FormData();
    message_id && formData.append("message_id", message_id);
    message && formData.append("comments", message);

    try {
      const response = await API.post(`/update-message`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
