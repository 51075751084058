import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../redux/slices/AdminDashboardSlice";
import {
  clearLoginUser,
  setResumeDetail,
} from "../../../redux/slices/AuthSlice";
import { setInboxUser } from "../../../redux/slices/InboxSlice";
import {
  setTotalBulkResumeCredit,
} from "../../../redux/slices/buyBulkAnalysisSlice";
import { USER_TYPE } from "../../../constants";
import "./AdminHeader.css";

const AdminHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, userName } = useSelector((state) => state.auth);
  const { activeSideBar } = useSelector((state) => state.adminDashboard);
  const { countryISOCode } = useSelector(
    (state) => state.home
  );

  const [hideMenu, setHideMenu] = useState(true);

  const onSystemHubHandler = () => {
    dispatch(
      setActiveSideBar({
        id: "6",
        name: "My job posts & more",
        value: "My job posts & more",
      })
    );
    navigate("/jobs-posted");
  }


  const onLogOutHandler = () => {
    dispatch(clearLoginUser());
    dispatch(setResumeDetail({ resumeId: null, resumeType: 1 }));
    dispatch(setdefaultPermissions({}));
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Department",
        value: "Department",
      })
    );
    dispatch(setInboxUser({}));
    dispatch(setTotalBulkResumeCredit(0));
    localStorage.removeItem("token");
  };

  return (
    <div className="header-sidebar">
      <div className="row-header">
        <div className="col-left-sidebar">
          <div className="text-header-sidebar">
            <p onClick={() => onSystemHubHandler()}>System hub /</p>
            <span className="active">{activeSideBar?.value}</span>
          </div>
        </div>
        <div className="col-right-sidebar">
          <div className="department-name-img icon-cursor">
            <a href="#">
              <img src="/image/profile.png" alt="" />
              <p className="department-name">
                Hi, {userName !== "" ? capitalizeFirstLetter(userName) : ""}
              </p>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setHideMenu(true);
                }}
              >
                <div
                  className="toggle-img-admin"
                  onClick={() => setHideMenu(!hideMenu)}
                >
                  <img src="/image/toogle-btn.png" alt="" />
                </div>
              </OutsideClickHandler>
            </a>
          </div>
          {user?.user_type === USER_TYPE.SUPER_ADMIN && (
            <div className="all-flag-sidebar">
              <div className="headder-flag-sidebar">
                <div className="flag-list active">
                  <img src="/image/flag-1.png" alt="" />
                  <div className="flag-caption active">
                    <p>{countryISOCode}</p>
                  </div>
                </div>
              </div>
              <div className="headder-flag-sidebar">
                <div className="flag-list">
                  <img src="/image/flag-2.png" alt="" />
                </div>
                <div className="flag-caption">
                  <p>{countryISOCode}</p>
                </div>
              </div>
              <div class="headder-flag-sidebar">
                <div class="flag-list">
                  <img src="/image/flag-3.png" alt="" />
                </div>
                <div class="flag-caption">
                  <p>{countryISOCode}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`dropdown-dashbord-admin ${hideMenu && "hide-show-click"}`}
        onClick={() => setHideMenu(true)}
      >
        <ul className="dropdown-menu-dashbord">
          {user?.user_type !== USER_TYPE.SUPER_ADMIN && (
            <>
              <Link
                to="/employer-dashboard"
                onClick={() =>
                  dispatch(
                    setActiveSideBar({
                      id: "2",
                      name: "Department",
                      value: "Department",
                    })
                  )
                }
              >
                <li className="menu-dropdown">
                  <img src="/image/dash-m.png" alt="" />
                  Dashboard
                </li>
              </Link>
              <Link to="/employer-profile">
                <li className="menu-dropdown">
                  <img src="/image/profile-m.png" alt="" />
                  My profile
                </li>
              </Link>
            </>
          )}

          <Link to="/" onClick={() => onLogOutHandler()}>
            <li className="menu-dropdown">
              <img src="/image/log-m.png" alt="" />
              Logout
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default AdminHeader;
