import { combineReducers } from "redux";
import homeSlice from "./slices/homeSlice";
import AuthSlice from "./slices/AuthSlice";
import JobSearchSlice from "./slices/JobSearchSlice";
import JobDetailSlice from "./slices/JobDetailSlice";
import companyDetailSlice from "./slices/companyDetailSlice";
import verifyResumeSlice from "./slices/verifyResumeSlice";
import myResumeSlice from "./slices/myResumeSlice";
import employerDashboardSlice from "./slices/employerDashboardSlice";
import candidateDashboardSlice from "./slices/candidateDashboardSlice";
import InboxSlice from "./slices/InboxSlice";
import ProfileSlice from "./slices/ProfileSlice";
import CommentModalSlice from "./slices/commentModalSlice";
import CanQASlice from "./slices/CanQASlice";
import JobDecisionSlice from "./slices/JobDecisionSlice";
import JobPostZoeModalSlice from "./slices/JobPostZoeModalSlice";
import candidateListingSlice from "./slices/candidateListingSlice";
import listingSlice from "./slices/listingSlice";
import candidateDetailSlice from "./slices/candidateDetailSlice";
import AdminDashboardSlice from "./slices/AdminDashboardSlice";
import companyPromotionSlice from "./slices/companyPromotionSlice";
import bulkResumeCanDetailSlice from "./slices/bulkResumeCanDetailSlice";
import buyBulkAnalysisSlice from "./slices/buyBulkAnalysisSlice";


const rootReducer = combineReducers({
  home: homeSlice,
  auth: AuthSlice,
  jobSearch: JobSearchSlice,
  jobDetail: JobDetailSlice,
  companyDetail: companyDetailSlice,
  verifyResume: verifyResumeSlice,
  myResume: myResumeSlice,
  employerDashboard: employerDashboardSlice,
  candidateDashboard: candidateDashboardSlice,
  Inbox: InboxSlice,
  profile: ProfileSlice,
  comments: CommentModalSlice,
  CanQA: CanQASlice,
  jobDecision: JobDecisionSlice,
  jobPostZoe: JobPostZoeModalSlice,
  candidateListing: candidateListingSlice,
  listing: listingSlice,
  candidateDetail: candidateDetailSlice,
  adminDashboard: AdminDashboardSlice,
  companyPromotion: companyPromotionSlice,
  bulkResumeCanDetail: bulkResumeCanDetailSlice,
  buyBulkAnalysis: buyBulkAnalysisSlice
});

export { rootReducer };
