import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import FileUploadModel from "../../../../components/Common/FileUploadModel/FileUploadModel";
import ResumeProgressModel from "../../../../components/Common/ResumeProgressModel/ResumeProgressModel";
import WorldConnect from "../../../../components/Common/WorldConnect/WorldConnect";
import { setSelectedCustomResume } from "../../../../redux/slices/AuthSlice";
import {
  customResumeAsync,
  setResumeFormMode,
} from "../../../../redux/slices/verifyResumeSlice";
import { RESUME_MODE } from "../../../../constants";
import "./index.css";
import Button from "react-bootstrap/Button";
import ContentModal from "../../../../components/Common/ContentModal/ContentModal";

const CanResumeJobForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [loaderAI, setLoaderAI] = useState(false);

  const [toggleModal, setToggleModal] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState("");

  const formik = useFormik({
    initialValues: {
      files: null,
      JobURL: "",
      JobDescription: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      files: Yup.mixed()
        .required("Upload a resume in a PDF format")
        .test(
          "fileType",
          "Please upload a file in PDF format",
          (value) => !value || ["application/pdf"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 5 MB",
          (value) => !value || value?.size <= 5 * 1024 * 1024
        ),
      JobURL: Yup.string().required("Please enter job url"),
      JobDescription: Yup.string().required("Please copy & paste job description"),
    }),
    onSubmit: async (data, { resetForm, setErrors }) => {
      const { files, JobURL, JobDescription } = data;
      setLoader(true);
      setLoaderAI(true);

      dispatch(
        customResumeAsync({
          userId: user?.id,
          resume: files,
          JobURL: JobURL,
          JobDescription: JobDescription,
        })
      )
        .then((response) => {
          const res = response?.payload;

          if (res?.result === true) {
            // dispatch(
            //   setSelectedCustomResume({
            //     resumeId: res?.resume_id,
            //     resumeType: res?.resume_type,
            //   })
            // );
            //dispatch(setResumeFormMode(RESUME_MODE.CUSTOMIZED));
            toast.success(res?.message?.success, {
              autoClose: 2000,
              closeOnClick: false,
            });
            navigate("/my-resumes");

            setLoader(false);
            setLoaderAI(false);
          } else {
            setLoader(false);
            setLoaderAI(false);

            if (typeof res?.message === 'string') {
              toast.error(res?.message, {
                autoClose: 2000,
                closeOnClick: false,
              });
            } else if (res?.message?.error) {
              toast.error(res?.message?.error, {
                autoClose: 2000,
                closeOnClick: false,
              });
            } else {         
              toast.error("An unexpected error occurred", {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
          }
        })
        .catch((error) => {
          setLoader(false);
          setLoaderAI(false);
          console.log(error);
        });
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const modelHandler = (toggleModel) => {
    setToggleModal(toggleModel);
  };

  const loaderAIHandler = (toggleModel) => {
    setLoaderAI(toggleModel);
  };

  const resumeFileHandler = async (file, selectedImageName) => {
    await formik.setFieldValue("files", file);
    setSelectedImageName(selectedImageName);
  };

  return (
    <>
      <div className="container container-site py-md-2 py-1">
        <Button
          variant="link"
          className="back-to-home add position-relative top-0"
          onClick={() => navigate(-1)}
        >
          <img src="/image/form-back-arrow.png" alt="" />
        </Button>
      </div>
      <section className="resume-sec-upload mt-0">
        <div className="container container-site">
          <div className="candidate-job-resume-sec">
            <div className="text-mainheadingupload">
              Let our AI elevate your resume!
            </div>
            <p className="text-sumtext-upload">
              Our AI turns ordinary resumes into extraordinary career stories.
              Let's take the first step together! 🌟
            </p>
          </div>
        </div>
      </section>
      <section className="resume-form-upload">
        <div className="container container-site">
          <div className="candidate-job-resume">
            <div className="job-form-info">
              <form onSubmit={formik.handleSubmit}>
                <div
                  className={`candidate-res-file icon-cursor ${isFormFieldValid("files") && "error-input"
                    }`}
                  onClick={() => modelHandler(true)}
                >
                  <label for="file-input" id="candidate-res">
                    <img src="/image/upload-icon-res.png" alt="pic" />
                    <input id="file-input" type="text" />
                    <p className="candidate-file-res-drop">
                      {selectedImageName !== ""
                        ? selectedImageName
                        : "Upload your resume"}
                    </p>
                    <p className="file-suport-text">Supported formats: PDF</p>
                  </label>
                </div>
                {getFormErrorMessage("files")}
                <div
                  className={`job-res-frm-right f-feild-res ${isFormFieldValid("JobURL") && "error-input"
                    }`}
                >
                  <label for="lname">Copy/Paste job url</label>
                  <br />
                  <input
                    type="text"
                    className="feild-stl-res form-font-f"
                    placeholder="Place job URL"
                    name="JobURL"
                    value={formik.values.JobURL}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {getFormErrorMessage("JobURL")}
                </div>
                <div
                  className={`job-res-frm-right f-feild-res ${isFormFieldValid("JobDescription") && "error-input"
                    }`}
                >
                  <label for="lname">Copy & Paste job description</label>
                  <br />
                  <textarea
                    name="JobDescription"
                    className="feild-stl-res form-font-f"
                    cols="30"
                    rows="20"
                    placeholder="Copy/paste the job description for which you want to customise your resume."
                    value={formik.values.JobDescription}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {getFormErrorMessage("JobDescription")}
                </div>
                <div className="job-res-frm-right f-feild-res">
                  <div className="two-can-sav-btn-right">
                    <button
                      type="text"
                      className="can-res-btn-res"
                      onClick={() => navigate("/candidate-resume-decision")}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="save-res-btn-res">
                      Let’s customize
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <WorldConnect />
    

    {toggleModal && (
      <FileUploadModel
        modelHandler={modelHandler}  
        resumeFileHandler={resumeFileHandler}
      />
    )}
      {loaderAI && (
        <ContentModal size='lg'
          closeModal={loaderAIHandler}>
          <ResumeProgressModel 
              heading="Just a moment please, customizing your resume." 
              content="A quick dance while you wait for Polycareers AI to customize your resume."
          />
        </ContentModal>
      )}
    </>
  );
};

export default CanResumeJobForm;
