import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getCreatePromotionPagesForAdsAsync,
  getPromotionJobsAsync,
  getTimePromotionDataAsync,
  getWeeksPromotionDataAsync,
  setPromotionFormMode,
  setSelectedDefultPromotionDate,
  setSelectedJob,
  setSelectedPromotionDate,
  setSelectedPromotionType,
  setSelectedTimeFrame,
} from "../../../../redux/slices/companyPromotionSlice";
import { FormDropdown } from "../../../../components/Admin/Promotion/CreatePromotion/FormDropdown";
import { promotionTypes } from "../../../../constants";

const AdminCreateProCompanyForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedPromotionType, selectedTimeFrame, selectedPromotionDate } =
    useSelector((state) => state.companyPromotion);

  /*Dropdown Value*/
  const [promotionTypeD, setPromotionTypeD] = useState([]);
  const [promotionTypeDLoading, setPromotionTypeDLoading] = useState(false);
  const [timeFrameD, setTimeFramesD] = useState([]);
  const [promotionPages, setPromotionPages] = useState([]);
  const [promotionPagesLoading, setPromotionPagesLoading] = useState(false);
  const [promotionDate, setPromotionDate] = useState([]);
  const [promotionDateLoading, setPromotionDateLoading] = useState(false);
  const [promotionJobs, setPromotionJobs] = useState([]);
  const [promotionJobsLoading, setPromotionJobsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      promotion: "",
      promotionId: null,
      timeFrame: "",
      timeFrameId: null,
      page: "",
      pageId: null,
      promotionDate: "",
      promotionDateId: null,
      job: "",
      jobId: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      promotion: Yup.string().required("Please enter promotion type"),
      timeFrame: Yup.string().required("Please enter promotion time frame"),
      job: Yup.string().when("promotion", {
        is: (value) => value === "Job Ads",
        then: () => Yup.string().required("Please select job to promote"),
        otherwise: () => Yup.string(),
      }),
      promotionDate: Yup.string().required("Please enter promotion date"),
    }),
    onSubmit: async (data, { resetForm }) => {
      dispatch(setPromotionFormMode("add"));
      navigate("/admin-promotion-slot");
    },
  });

  const onBackClick = () => {
    dispatch(setSelectedPromotionType({ id: null, name: "" }));
    dispatch(setSelectedTimeFrame({ id: null, name: "" }));
    navigate("/admin-all-promotion");
  };

  const onproClick = async (id, name) => {
    await formik.setFieldValue("promotionId", id);
    await formik.setFieldValue("promotion", name);
    await formik.setFieldValue("timeFrameId", null);
    await formik.setFieldValue("timeFrame", "");
    await formik.setFieldValue("promotionDate", "");
    await formik.setFieldValue("promotionDateId", null);

    dispatch(setSelectedPromotionType({ id: id, name: name }));
    // const needDataForJobPromotion = formik?.values?.timeFrameId && id === 2;
    // needDataForJobPromotion &&
    //   getPromotionPagesData(formik?.values?.timeFrameId);

    getTimePromotionData(id);
    setPromotionDate([]);
    dispatch(setSelectedDefultPromotionDate());
  };

  const onTimeFrameClick = async (id, name) => {
    await formik.setFieldValue("timeFrameId", id);
    await formik.setFieldValue("timeFrame", name);
    dispatch(setSelectedTimeFrame({ id: id, name: name }));
    const needDataForJobPromotion =
      formik?.values?.promotionId === 2 && promotionPages.length === 0;
    needDataForJobPromotion && getPromotionPagesData(id);
    getPromotionDate(id);
  };


  const onPromotionDateSelection = async (id, name) => {
    await formik.setFieldValue("promotionDate", name);
    await formik.setFieldValue("promotionDateId", id);
    await formik.setFieldValue("job", "");
    await formik.setFieldValue("jobId", null);
    dispatch(setSelectedJob(null));
    dispatch(
      setSelectedPromotionDate({ id: id, name: name, ...promotionDate[id] })
    );
    getPromotionJobs({ ...promotionDate[id] });
  };

  const getTimePromotionData = async (promotion_type_id) => {
    setPromotionTypeDLoading(true);
    dispatch(
      getTimePromotionDataAsync({ promotion_type_id: promotion_type_id })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          const data = res?.data;
          onTimeFrameClick(data[0]?.id, data[0]?.name);
          //setTimeFramesD(res?.data);
        }
        setPromotionTypeDLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPromotionTypeDLoading(false);
      });
  };

  const getPromotionPagesData = async (time_frame_id) => {
    setPromotionPagesLoading(true);
    dispatch(getCreatePromotionPagesForAdsAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setPromotionPages(res?.data);
        }
        setPromotionPagesLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPromotionPagesLoading(false);
      });
    getPromotionDate(time_frame_id);
  };

  const getPromotionJobs = async ({ start_date, end_date }) => {
    setPromotionJobsLoading(true);
    dispatch(getPromotionJobsAsync({ start_date, end_date }))
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setPromotionJobs(res?.data);
        }
        setPromotionJobsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPromotionJobsLoading(false);
      });
  };

  const getPromotionDate = async (time_frame_id) => {
    if (time_frame_id !== undefined) {
      setPromotionDateLoading(true);
      dispatch(getWeeksPromotionDataAsync({ timeFrame: time_frame_id }))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            setPromotionDate(
              res?.data.map((date, index) => ({
                id: index,
                name: `${date?.week_start_day} - ${date?.week_end_day}`,
                ...date,
              }))
            );
          }
          setPromotionDateLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setPromotionDateLoading(false);
        });
    }
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  useEffect(() => {
    async function getSelectedProValue() {
      await formik.setFieldValue("promotionId", selectedPromotionType?.id);
      await formik.setFieldValue("promotion", selectedPromotionType?.name);
      await formik.setFieldValue("timeFrameId", selectedTimeFrame?.id);
      await formik.setFieldValue("timeFrame", selectedTimeFrame?.name);
      // await formik.setFieldValue("promotionDateId", selectedPromotionDate?.id);
      // await formik.setFieldValue("promotionDate", selectedPromotionDate?.name);
    }
    getSelectedProValue();

    onproClick(promotionTypes[0].id, promotionTypes[0].name);

    if (selectedTimeFrame?.id !== null) getPromotionDate(selectedTimeFrame?.id);
  }, []);

  return (
    <div className="department-role-space bg-clr-admin">
      <div className="form-heading-prom">
        <h2>Promote company</h2>
      </div>
      <div className="form-text-start">
        Want to boost your company’s visibility and jobs? You can promote all
        the jobs you’ve purchased on our front page for just $500 extra for 4
        weeks.
      </div>
      <div className="department-form-box">
        <div className="form-labeal-heading-admin">
          <h2>Promote your Company</h2>
        </div>
        <form onSubmit={formik.handleSubmit} className="make-dd-100">
          <div className="admin-from-step">
            {/* <FormDropdown
              label="Promotion type"
              placeholder="Select promotion"
              required
              value={formik?.values?.promotion}
              loading={promotionTypeDLoading}
              isInvalid={isFormFieldValid("promotion")}
              error={getFormErrorMessage("promotion")}
              options={promotionTypeD}
              onSelect={onproClick}
              formik={formik}
            /> */}
            {/* <FormDropdown
              label="Promotion time frame"
              placeholder="Time frame"
              required
              value={formik?.values?.timeFrame}
              loading={promotionTypeDLoading}
              isInvalid={isFormFieldValid("timeFrame")}
              error={getFormErrorMessage("timeFrame")}
              options={[]}
              onSelect={onTimeFrameClick}
              formik={formik}
              cusImg="img"
            /> */}
            <FormDropdown
              label="Select date"
              placeholder="Date"
              required
              value={formik?.values?.promotionDate}
              loading={promotionDateLoading}
              isInvalid={isFormFieldValid("promotionDate")}
              error={getFormErrorMessage("promotionDate")}
              options={promotionDate}
              onSelect={onPromotionDateSelection}
              formik={formik}
              cusImg="/image/admin-calendar.png"
            />
            <div className="f-admin feild-f-admin"></div>
          </div>
          <div className="img-btn-adminform">
            <div className="admin-frm-avtar">
              <img src="/image/pormotion-form-img.png" alt="" />
            </div>
            <div className="admin-form-btn">
              <button
                className="cancel-admin"
                onClick={() => navigate("/admin-all-promotion")}
              >
                Cancel
              </button>
              <button className="next-admin" type="submit">
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminCreateProCompanyForm;
