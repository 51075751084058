import { useState, useEffect, useRef, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import BaseInfo from "../../../components/Employer/Dashboard/BaseInfo";
import NewApplicants from "../../../components/Employer/Dashboard/NewApplicants";
import JobListing from "../../../components/Employer/Dashboard/JobListing";
import JobDrafts from "../../../components/Employer/Dashboard/JobDrafts";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import ResumeModel from "../../../components/Common/ResumeModel/ResumeModel";
import CommentModel from "../../../components/Common/CommentModel/CommentModel";
import ActionModal from "../../../components/Common/ActionModal/ActionModal";
import PostJobDeleteModel from "../../../components/Common/PostJobDeleteModel/PostJobDeleteModel";

import {
  setInboxCount
}
  from "../../../redux/slices/AuthSlice";

import {
  candidateFirstImpressionChangeAsync,
  candidateStatusChangeAsync,
  changeCandidateStatusToResumeReviewedAsync,
  changeJobStatusAsync,
  deletePostJobDataAsync,
  favoriteCandidateAsync,
  getSystemFieldAsync,
} from "../../../redux/slices/employerDashboardSlice";
import API from "../../../api";
import APIPvtDB from "../../../apiPvtD";
import "./index.css";
import { CANDIDATE_STATUSES } from "../../../constants";
import {
  addJobCommentAsync,
  emptyCommentsModalData,
  setCandidateId,
  setJobId,
} from "../../../redux/slices/commentModalSlice";

const EmpDashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { candidateStatuses } = useSelector((state) => state.employerDashboard);
  const headRef = useRef(null);
  /*Counter*/
  const [totalApplicants, setTotalApplicants] = useState(0);
  const [jobListingsCount, setJobListingsCount] = useState(0);
  const [draftJobsCount, setDraftJobsCount] = useState(0);
  const [favoriteCount, setFavoriteCount] = useState(0);

  /*Session*/
  const [applicants, setApplicants] = useState([]);
  const [publishedJobs, setPublishedJobs] = useState([]);
  const [draftJobs, setDraftJobs] = useState([]);
  const [showNewApplicant, setShowNewApplicant] = useState(false);
  const [showJobListing, setShowJobListing] = useState(false);
  const [showJobDraft, setShowJobDraft] = useState(false);

  /* new applicants filter selected value */

  const [overAllMatchDropDown, setOverAllMatchDropDown] = useState([]);
  const [experienceDropDown, setExperienceDropDown] = useState([]);
  const [educationDropDown, setEducationDropDown] = useState([]);
  const [impressionDropDown, setImpressionDropDown] = useState([]);
  const [statusDropDown, setStatusDropDown] = useState([]);

  const [selectedOverAllMatch, setSelectedOverAllMatch] = useState({
    label: null,
    value: null,
  });

  const [selectedExperience, setSelectedExperience] = useState({
    label: null,
    value: null,
  });

  const [selectedEducation, setSelectedEducation] = useState({
    label: null,
    value: null,
  });

  const [selectedImpression, setSelectedImpression] = useState({
    id: null,
    text: null,
  });

  const [selectedCandidateStatus, setSelectedCandidateStatus] = useState({
    id: null,
    text: null,
  });

  const [searchNewApplicant, setSearchNewApplicant] = useState(null);
  const [searchDraftJob, setsearchDraftJob] = useState("");

  /*Job Listing Filter*/
  const [jobTitles, setJobTitles] = useState([]);
  const [datePosted, setDatePosted] = useState([]);
  const [expiryDate, setExpiryDate] = useState([]);
  const [cities, setcities] = useState([]);
  const [jobStatus, setJobStatus] = useState([]);
  const [applicantJT, setApplicantJT] = useState([]);

  /*Job Listing Filter Selected Value*/
  const [selectedJobTitile, setSelectedJobTitile] = useState("");
  const [selectedJobTitileApplicant, setSelectedJobTitileApplicant] = useState("");
  const [search, setSearch] = useState("");
  const [selectedDatePostedLabel, setSelectedDatePostedLabel] = useState("");
  const [selectedDatePosted, setSelectedDatePosted] = useState("");
  const [selectedExpiryDateLabel, setSelectedExpiryDateLabel] = useState("");
  const [selectedExpiryDate, setSelectedExpiryDate] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [selectedJobStatus, setSelectedJobStatus] = useState({
    label: "",
    value: null,
  });

  /*Selected Delete Item Object*/
  const [selectedDeleteItemIndex, setSelectedDeleteItemIndex] = useState(null);

  /*Toggle Model*/
  const [toggleModel, setToggleModel] = useState(false);
  const [toggleModelC, setToggleModelC] = useState(false);
  const [toggleDeleteItemModel, setToggleDeleteItemModel] = useState(false);

  /***********************/
  const scrollToHead = () => {
    if (headRef.current) {
      headRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  /***********************************/

  const commentRemoved = (commentId, commentToReplace) => {
    const updatedApplicants = applicants.map((applicant) => {
      if (applicant?.comments?.id === commentId) {
        const newComment = {
          ...commentToReplace,
          comments_count:
            applicant?.comments?.comments_count > 0
              ? applicant?.comments?.comments_count - 1
              : 0,
        };

        if (commentToReplace === "") {
          return {
            ...applicant,
            comments: "",
          };
        }

        return {
          ...applicant,
          comments: {
            ...newComment,
          },
        };
      }
      return applicant;
    });
    setApplicants(updatedApplicants);
  };

  const commentAdded = (candidateId, jobId, comment) => {
    const updatedApplicants = applicants.map((applicant) => {
      if (
        applicant?.account_id === candidateId &&
        applicant?.job_id === jobId
      ) {
        return {
          ...applicant,
          comments: {
            ...comment,
            comments_count: applicant?.comments?.comments_count + 1,
          },
        };
      }
      return applicant;
    });
    setApplicants(updatedApplicants);
  };

  const handleNewCommentSubmit = async (text, jobId, candidateId) => {
    if (text === null || text === undefined || text === "") return;
    if (jobId === null || jobId === undefined || jobId === "") return;
    if (candidateId === null || candidateId === undefined || candidateId === "")
      return;

    dispatch(
      addJobCommentAsync({
        userId: user?.id,
        jobId: jobId,
        candidateId: candidateId,
        commentText: text,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = applicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                comments: {
                  ...res?.data[0]?.comments[0],
                  comments_count: 0,
                },
              };
            }
            return applicant;
          });
          setApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*******Model Event Handler*********/

  const modelCHandler = (v, candidate_id, job_id) => {
    setToggleModelC(v);

    if (v === false) {
      dispatch(emptyCommentsModalData());
      return;
    }
    if (
      candidate_id === null ||
      candidate_id === undefined ||
      candidate_id === "" ||
      job_id === null ||
      job_id === undefined ||
      job_id === ""
    ) {
      setToggleModelC(false);
      return;
    }

    dispatch(setJobId(job_id));
    dispatch(setCandidateId(candidate_id));
  };
  const modelHandler = (v) => setToggleModel(v);

  const deleteItemModelHandler = (v) => setToggleDeleteItemModel(v);

  /***********************/

  const onJobTitleClick = (j) => {
    setSelectedJobTitile(j);
  };

  const onJobTitleClean = () => {
    setSelectedJobTitile("");
  };

  const onJobTitleClickApplicant = (j) => {
    setSelectedJobTitileApplicant(j);
  };

  const onJobTitleCleanApplicant = () => {
    setSelectedJobTitileApplicant("");
  };

  const onResumeClickHandler = async (candidateId, jobId, currentStatus) => {
    if (currentStatus !== CANDIDATE_STATUSES.NEW_APPLICANT) return;

    dispatch(
      changeCandidateStatusToResumeReviewedAsync({
        candidateId: candidateId,
        jobId: jobId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = applicants?.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                status_id: res?.status_id,
                status: res?.status,
              };
            }
            return applicant;
          });
          setApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* new applicants on first impression click */
  const onFirstImpressionClick = async (
    candidateId,
    jobId,
    impressionId,
    currentStatusId
  ) => {
    if (currentStatusId === CANDIDATE_STATUSES.NEW_APPLICANT) return;
    if (candidateId === null || jobId === null || impressionId === null) return;

    const selectedApplicant = applicants?.find(
      (applicant) =>
        applicant?.job_id === jobId && applicant?.account_id === candidateId
    );
    if (parseInt(selectedApplicant?.is_favorite) === 1 && impressionId === 1) {
      toast.error("Favourite applicant cannot have not a good fit status.", {
        autoClose: 2000,
        closeOnClick: false,
      });
      return;
    }

    dispatch(
      candidateFirstImpressionChangeAsync({
        candidateId: candidateId,
        jobId: jobId,
        impressionId: impressionId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = applicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                impression_id: res?.impression_removed === 1 ? 0 : impressionId,
              };
            }
            return applicant;
          });
          setApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* New Application status Change Handler */
  const onCandidateStatusChangeHandler = async (
    candidateId,
    jobId,
    statusId,
    currentStatusId
  ) => {
    if (candidateId === null || jobId === null || statusId === null) return;
    if (currentStatusId === statusId) return;

    dispatch(
      candidateStatusChangeAsync({
        candidateId: candidateId,
        statusId: statusId,
        jobId: jobId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = applicants.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              const { id, status } = statusDropDown?.find(
                (item) => item.id === statusId
              );
              return {
                ...applicant,
                status_id: id,
                status: status,
              };
            }
            return applicant;
          });
          setApplicants(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* start new applicant things */

  const onSearchNewApplicantChange = (value) => {
    setSearchNewApplicant(value);
  };

  const onOverAllMatchChange = (label, value) => {
    setSelectedOverAllMatch({
      label: label,
      value: value,
    });
  };

  const onOverAllMatchClean = () => {
    setSelectedOverAllMatch({
      label: null,
      value: null,
    });
  };

  const onExperienceChange = (label, value) => {
    setSelectedExperience({
      label: label,
      value: value,
    });
  };

  const onExperienceClean = () => {
    setSelectedExperience({
      label: null,
      value: null,
    });
  };

  const onEducationChange = (label, value) => {
    setSelectedEducation({
      label: label,
      value: value,
    });
  };

  const onEducationClean = () => {
    setSelectedEducation({
      label: null,
      value: null,
    });
  };

  const onFirstImpressionChange = (id, text) => {
    setSelectedImpression({
      id: id,
      text: text,
    });
  };

  const onFirstImpressionClean = () => {
    setSelectedImpression({
      id: null,
      text: null,
    });
  };

  const onCandidateStatusChange = (id, text) => {
    setSelectedCandidateStatus({
      id: id,
      text: text,
    });
  };

  const onCandidateStatusClean = () => {
    setSelectedCandidateStatus({
      id: null,
      text: null,
    });
  };
  /* End new Applicant things*/

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onDraftSearchChange = (value) => {
    setsearchDraftJob(value);
  };

  const onDatePostedClick = (value, label) => {
    setSelectedDatePosted(value);
    setSelectedDatePostedLabel(label);
  };

  const onDatePostedClean = () => {
    setSelectedDatePosted("");
    setSelectedDatePostedLabel("");
  };

  const onExpiryClick = (value, label) => {
    setSelectedExpiryDate(value);
    setSelectedExpiryDateLabel(label);
  };

  const onExpiryClean = () => {
    setSelectedExpiryDate("");
    setSelectedExpiryDateLabel("");
  };

  const onLocationClick = ({ id, name }) => {
    setSelectedCityId(id);
    setSelectedCity(name);
  };

  const onLocationClean = () => {
    setSelectedCityId(null);
    setSelectedCity("");
  };

  const onJobStatusClick = (label, value) => {
    setSelectedJobStatus({
      label: label,
      value: value,
    });
  };

  const onJobStatusClean = () => {
    setSelectedJobStatus({
      label: "",
      value: null,
    });
  };

  const onFvtClick = async (account_id, job_id) => {
    const selectedApplicant = applicants?.find(
      (applicant) =>
        applicant?.job_id === job_id && applicant?.account_id === account_id
    );
    if (selectedApplicant?.impression_id === 1) {
      toast.error(
        "Not a Good Fit Applicant cannot be added to Favourite list.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      return;
    }

    dispatch(
      favoriteCandidateAsync({
        userId: user?.id,
        accountId: account_id,
        jobId: job_id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = applicants.map((applicant) => {
            if (
              applicant?.account_id === account_id &&
              applicant?.job_id === job_id
            ) {
              return {
                ...applicant,
                is_favorite: applicant?.is_favorite === 0 ? 1 : 0,
              };
            }
            return applicant;
          });
          setApplicants(updatedApplicants);
          setFavoriteCount(res?.count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onJobStatusListingClick = async (job_id) => {
    dispatch(
      changeJobStatusAsync({
        jobId: job_id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedJob = publishedJobs.map((job) => {
            if (job?.id === job_id) {
              return {
                ...job,
                is_active: job?.is_active === 0 ? 1 : 0,
              };
            }
            return job;
          });
          setPublishedJobs(updatedJob);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onJobStatusDraftClick = async (job_id) => {
    dispatch(
      changeJobStatusAsync({
        jobId: job_id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        const { message } = res;

        if (res?.result === true) {

          toast.success(message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });

          const updatedDraftJobs = draftJobs?.filter(
            (job) => job?.id !== job_id
          );
      
          if (jobListingsCount >= 0) setJobListingsCount(jobListingsCount + 1);
          if (draftJobsCount >= 0) setDraftJobsCount(draftJobsCount - 1);
          setDraftJobs(updatedDraftJobs);
          getEmpJobListingData();
          scrollToHead();
        }
        else if (res?.result === false) {
          toast.error(message, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**********Delete Item Model************/

  const openDeleteItemHandler = (indexToUpdate, toggleModel) => {
    setSelectedDeleteItemIndex(indexToUpdate);
    deleteItemModelHandler(toggleModel);
  };

  const closeDeleteItemHandler = () => {
    setSelectedDeleteItemIndex(null);
    deleteItemModelHandler(false);
  };

  const selectedDeleteItemHandler = () => {
    onPostDeleteHandler(selectedDeleteItemIndex);
  };

  const onPostDeleteHandler = (indexToRemove) => {
    dispatch(
      deletePostJobDataAsync({
        JobId: indexToRemove,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedDraftJobs = draftJobs?.filter(
            (job) => job?.id !== indexToRemove
          );
          if (draftJobsCount >= 0) setDraftJobsCount(draftJobsCount - 1);
          setDraftJobs(updatedDraftJobs);

          if (searchDraftJob === "" && updatedDraftJobs?.length === 0) {
            setShowJobDraft(false);
          } else setShowJobDraft(true);

          scrollToHead();
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        closeDeleteItemHandler();
      })
      .catch((error) => {
        closeDeleteItemHandler();
        console.log(error);
      });
  };

  /***********************/

  const getEmployerApplicantsData = async () => {
    if (user?.id) {
      let params = `?`;      
      if (selectedOverAllMatch?.value !== null) {
        params += `overall_match=${selectedOverAllMatch?.value}&`;
      }
      if (selectedExperience?.value !== null) {
        params += `experience=${selectedExperience?.value}&`;
      }
      if (selectedEducation?.value !== null) {
        params += `education=${selectedEducation?.value}&`;
      }
      if (selectedImpression?.text !== null) {
        params += `impression_id=${selectedImpression?.id}&`;
      }
      if (selectedCandidateStatus?.text !== null) {
        params += `status_id=${selectedCandidateStatus?.id}&`;
      }
      if (searchNewApplicant !== null) {
        params += `search=${searchNewApplicant}&`;
      }

      if (selectedJobTitileApplicant !== "") {
        params += `job_title=${selectedJobTitileApplicant}&`;
      }

      try {
        await APIPvtDB.get(`/new-applicants${params}`)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;
              if (res?.result === true) {
                setTotalApplicants(res?.total_applicants);
                setFavoriteCount(res?.favorite_applicant_count);
                setApplicants(res?.applicants);              
                dispatch(setInboxCount(res?.unread_conversation_count));

                setOverAllMatchDropDown(res?.overAllMatch[0]?.attribute_values);
                setExperienceDropDown(res?.experience[0]?.attribute_values);
                setEducationDropDown(res?.education[0]?.attribute_values);
                setImpressionDropDown(res?.impressions);
                setStatusDropDown(res?.statuses);
                setApplicantJT(res?.job_titles);

                if (
                  searchNewApplicant === null &&
                  selectedOverAllMatch?.value === null &&
                  selectedExperience?.value === null &&
                  selectedEducation?.value === null &&
                  selectedImpression?.text === null &&
                  selectedCandidateStatus?.text === null &&
                  res?.applicants?.length === 0
                ) {
                  setShowNewApplicant(false);
                } else {
                  setShowNewApplicant(true);
                }
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getEmpJobListingData = async () => {
    if (user?.id) {
      try {
        let params = `?`;       
        if (selectedJobTitile !== "") {
          params += `job_title=${selectedJobTitile}&`;
        }
        if (search !== "") {
          params += `search=${search}&`;
        }
        if (selectedDatePosted !== "") {
          params += `date_posted=${selectedDatePosted}&`;
        }
        if (selectedExpiryDate !== "") {
          params += `expiry_date=${selectedExpiryDate}&`;
        }
        if (selectedCityId !== null) {
          params += `city_id=${selectedCityId}&`;
        }
        if (selectedJobStatus?.value !== null) {
          params += `is_active=${selectedJobStatus?.value}&`;
        }

        await APIPvtDB.get(`/jobs-listing${params}`)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;
              if (res?.result === true) {
                let jobs = response?.data?.jobs;
                let filter = response?.data?.filters;

                setJobListingsCount(res?.job_listings_count);

                setJobTitles(res?.job_titles);
                setDatePosted(filter?.date_posted);
                setExpiryDate(filter?.date_expiry);
                setcities(filter?.cities);
                setJobStatus(filter?.job_status);

                setPublishedJobs(jobs?.published);

                if (
                  selectedJobTitile === "" &&
                  search === "" &&
                  selectedDatePosted === "" &&
                  selectedExpiryDate === "" &&
                  selectedCityId === null &&
                  selectedJobStatus?.value === null &&
                  jobs?.published?.length === 0
                ) {
                  setShowJobListing(false);
                } else setShowJobListing(true);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getEmpJobDraftData = async () => {
    if (user?.id) {
      try {
        let params = `?`;        
        if (searchDraftJob !== "") {
          params += `search=${searchDraftJob}&`;
        }

        await APIPvtDB.get(`/draft-jobs${params}`)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;
              if (res?.result === true) {
                let jobs = response?.data?.jobs;
                setDraftJobsCount(res?.draft_jobs_count);
                setDraftJobs(jobs?.draft);

                if (searchDraftJob === "" && jobs?.draft?.length === 0) {
                  setShowJobDraft(false);
                } else setShowJobDraft(true);
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getEmployerApplicantsData();
  }, [
    searchNewApplicant,
    selectedOverAllMatch,
    selectedExperience,
    selectedEducation,
    selectedImpression,
    selectedCandidateStatus,
    selectedJobTitileApplicant
  ]);

  useEffect(() => {
    getEmpJobListingData();
  }, [
    selectedJobTitile,
    search,
    selectedDatePosted,
    selectedExpiryDate,
    selectedCityId,
    selectedJobStatus,
  ]);

  useEffect(() => {
    getEmpJobDraftData();
  }, [searchDraftJob]);

  useEffect(() => {
    getEmployerApplicantsData();
    getEmpJobListingData();
    getEmpJobDraftData();
  }, []);

  return (
    <div ref={headRef}>
      <BaseInfo
        totalApplicants={totalApplicants}
        jobListingsCount={jobListingsCount}
        draftJobsCount={draftJobsCount}
        favoriteCount={favoriteCount}        
      />
      <NewApplicants
        showNewApplicant={showNewApplicant}
        totalApplicants={totalApplicants}
        applicants={applicants}
        onFvtClick={onFvtClick}
        modelHandler={modelHandler}
        modelCHandler={modelCHandler}
        overAllMatchDropDown={overAllMatchDropDown}
        experienceDropDown={experienceDropDown}
        educationDropDown={educationDropDown}
        impressionDropDown={impressionDropDown}
        statusDropDown={statusDropDown}
        selectedOverAllMatch={selectedOverAllMatch}
        selectedExperience={selectedExperience}
        selectedEducation={selectedEducation}
        selectedFirstImpression={selectedImpression}
        selectedCandidateStatus={selectedCandidateStatus}
        onSearchChange={onSearchNewApplicantChange}
        onOverAllMatchChange={onOverAllMatchChange}
        onOverAllMatchClean={onOverAllMatchClean}
        onExperienceChange={onExperienceChange}
        onExperienceClean={onExperienceClean}
        onEducationChange={onEducationChange}
        onEducationClean={onEducationClean}
        onFirstImpressionChange={onFirstImpressionChange}
        onFirstImpressionClean={onFirstImpressionClean}
        onCandidateStatusChange={onCandidateStatusChange}
        onCandidateStatusClean={onCandidateStatusClean}
        onFirstImpressionClick={onFirstImpressionClick}
        onCandidateStatusChangeHandler={onCandidateStatusChangeHandler}
        onResumeClickHandler={onResumeClickHandler}
        handleAddNewCommentHandler={handleNewCommentSubmit}
        handleRemovedComment={commentRemoved}

        jobTitles={applicantJT}
        onJobTitleClick={onJobTitleClickApplicant}
        onJobTitleClean={onJobTitleCleanApplicant}
        selectedJobTitile={selectedJobTitileApplicant}
      />
      {totalApplicants < 1 ? <div className="py-md-4"></div> : ""}
      <JobListing
        jobTitles={jobTitles}
        selectedJobTitile={selectedJobTitile}
        onJobTitleClean={onJobTitleClean}
        datePosted={datePosted}
        selectedDatePostedLabel={selectedDatePostedLabel}
        selectedDatePosted={selectedDatePosted}
        expiryDate={expiryDate}
        selectedExpiryDateLabel={selectedExpiryDateLabel}
        selectedExpiryDate={selectedExpiryDate}
        cities={cities}
        selectedCity={selectedCity}
        jobStatus={jobStatus}
        selectedJobStatus={selectedJobStatus}
        showJobListing={showJobListing}
        jobListingsCount={jobListingsCount}
        publishedJobs={publishedJobs}
        onJobTitleClick={onJobTitleClick}
        onSearchChange={onSearchChange}
        onDatePostedClick={onDatePostedClick}
        onDatePostedClean={onDatePostedClean}
        onExpiryClick={onExpiryClick}
        onExpiryClean={onExpiryClean}
        onLocationClick={onLocationClick}
        onLocationClean={onLocationClean}
        onJobStatusClick={onJobStatusClick}
        onJobStatusClean={onJobStatusClean}
        onChangeJobStatus={onJobStatusListingClick}
      />
      <JobDrafts
        draftJobsCount={draftJobsCount}
        draftJobs={draftJobs}
        showJobDraft={showJobDraft}
        onSearchChange={onDraftSearchChange}
        onChangeJobStatus={onJobStatusDraftClick}
        openDeleteItemHandler={openDeleteItemHandler}
      />
      <WorldConnect />
      {toggleModel && <ResumeModel modelHandler={modelHandler} />}
      {toggleModelC && (
        <CommentModel
          modelCHandler={modelCHandler}
          commentAdded={commentAdded}
          commentRemoved={commentRemoved}
        />
      )}

      {toggleDeleteItemModel && (
        <>
          <ActionModal
            closeModal={closeDeleteItemHandler}
            modalArt="/image/warning-wishlist.png"
            heading="Double-Check"
            content="Are you sure you want to delete this Job ?"
          >
            <button
              className="btn btn-black"
              onClick={() => closeDeleteItemHandler()}
            >
              No
            </button>
            <button
              className="btn btn-outline-black"
              onClick={() => selectedDeleteItemHandler()}
            >
              Yes
            </button>
          </ActionModal>
        </>
      )}
    </div>
  );
};

export default EmpDashboard;
