const TermContent = () => {
  return (
    <>
      <section className="term-condition">
        <div className="main-heading-term">Terms and conditions</div>
      </section>
      <section>
        <div className="container container-site">
          <div className="first-para-term">
            Welcome to the PolyCareers website (the "Site"). The Site is owned
            and operated by Poly Tech Talent, Inc. ( "PolyCareers", "we", "us",
            or "our"). These Terms of Use (these "Terms") govern your access to
            and use of the Site and all related services, including any content,
            functionality, and services offered on or through the Site
            (collectively, the "Services"). Please read the Terms carefully
            before you start to use the Services. By using the Services, you
            accept and agree to be bound and abide by these Terms and our
            Privacy Policy, found at
            https://www.polycareers.com.mk/privacy-policy, incorporated here in
            by reference. If you do not want to agree to these Terms or the
            Privacy Policy, you must not access or use the Services.
          </div>
          <div className="sub-heading-term">Changes to the Terms</div>
          <div className="first-sub-para-term">
            We may revise and update these Terms from time to time in our sole
            discretion. All changes are effective immediately when we post them,
            and apply to all access to and use of the Services thereafter. Your
            continued use of the Services following the posting of revised Terms
            means that you accept and agree to the changes. You are expected to
            check this page from time to time so you are aware of any changes,
            as they are binding on you.
          </div>
          <div className="sub-heading-term">Accessing the Services</div>
          <div className="first-sub-para-term">
            We reserve the right to withdraw or amend the Services, and any
            service or material we provide on the Site, in our sole discretion
            without notice. We will not be liable if for any reason all or any
            part of the site is unavailable at any time or for any period. From
            time to time, we may restrict access to some parts of the site, or
            the entire Site, to users, including registered users.
          </div>
          <div className="sub-heading-term">User Accounts</div>
          <div className="first-sub-para-term">
            In order to access certain Services, you may be required to register
            for an account with PolyCareers (an "Account"). You may be required
            to provide certain information about yourself as part of the
            registration process, and you agree to maintain the accuracy and
            completeness of this information. You are solely responsible for the
            activity that occurs on your Account, and you must keep your Account
            password secure. You must notify us immediately of any breach of
            security or unauthorized use of your Account. We will not be liable
            for any losses caused by any unauthorized use of your Account.
          </div>
          <div className="sub-heading-term">Intellectual Property Rights</div>
          <div className="first-sub-para-term">
            The Site and its entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by PolyCareers, its licensors, or
            other providers of such material and are protected by United States
            and international copyright and trademark laws. You are granted a
            limited, non exclusive, non transferable, and revocable license to
            access and use the Site and Services. This license does not include
            any resale or commercial use of the Site or its contents; any
            collection and use of any product listings, descriptions, or prices;
            any derivative use of the Site or its contents; any downloading or
            copying of account information for the benefit of another merchant;
            or any use of data mining, robots, or similar data gathering and
            extraction tools. The Site and its contents may not be reproduced,
            duplicated, copied, sold, resold, visited, or otherwise exploited
            for any commercial purpose without our express written consent. You
            may not frame or utilize framing techniques to enclose any
            trademark, logo, or other proprietary information (including images,
            text, page layout, or form) of PolyCareers without our express
            written consent.
          </div>
        </div>
      </section>
    </>
  );
};

export default TermContent;
