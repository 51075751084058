import React, { useEffect, useState } from 'react'
import './index.css'
import { useLocation, useNavigate } from 'react-router-dom';
import GuageChart from './GuageChart';
import API from '../../../../api';

const PreScreeningResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [candResult, setCandResult] = useState({});
  const [chartData, setChartData] = useState({});


  const data ={
    labels: ['Wrong', 'Correct'],
    datasets: []
  }
  const getCandidiateResult = async (candId, jobId) => {
    try {
      await API.get(`/job-board/get-candidate-result?candidate_id=${candId}&job_id=${jobId}`)
        .then((res) => {
          if(res.data.result) {
            setCandResult(res.data)

            setChartData({
              labels: ['Wrong', 'Correct'],
              datasets: [
                {
                  label: 'Overall Result',
                  data: [res.data.wrongAnswers, res.data.correctAnswers], // 65% filled, 35% empty
                  borderWidth: 5,
                  backgroundColor: ['#ECECEC', '#009FB5'], // Progress bar colors
                  cutout: '75%', // This creates the gauge/doughnut shape
                  circumference: 240, // Makes it a half-circle
                  rotation: -120, // Starts at the top
                }
              ]
            });
          }

        })
        .catch((error) => {
          if (error) {
            console.log(error)
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() =>{
    const searchParams = new URLSearchParams(location.search);
      const jobId = searchParams.get('jobId');
      const userId = searchParams.get('candidateId');
    getCandidiateResult(userId, jobId)
  },[])

  

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end",
        align: "top",
        display: function (context) {
          return context.dataset.data[context.dataIndex] > 15;
        },
        font: {
          weight: "600",
          size: 28,
        },
      },
      legend: {
        display: false,
        position: "bottom",
        align: "center",
        labels: {
          padding: 30,
          color: "#14151a",
          usePointStyle: true,
          pointStyleWidth: 20,
          font: {
            size: 13,
            weight: 200,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      
    },
    rotation: -90, // Start angle for the chart (half-circle)
    circumference: 180, // Makes it a gauge (half-circle)
  };
  return (
    <>
      <div className="page-space bg-clr-admin">
        <div className="page-title-heading align-items-center">
          <div className='flex-start-center'>
            <img src='/image/form-back-arrow.png' className='me-2 cursor-pointer' onClick={() => navigate(-1)} />
            <h2 className='m-0'>Pre-screening questions</h2>
          </div>
        </div>
        <div className='job-position mt-4'>
          <img src="/image/bag-icon.png" alt='img' />
          {/* <h3 className='m-0'>{jobTitle}</h3> */}
          <h3 className='m-0'>{candResult?.job?.full_title}</h3>
        </div>
        <div className='drag-sec h-100'>
          <div className='row m-0'>
            <div className='col-lg-3 col-md-4'>
              <div className='card'>
                <div className='card-body position-relative'>
                  <div className='text-center mb-3'>
                    <h3 className='m-0 fw-700'>Overall result</h3>
                  </div>
                  {
                    candResult && chartData.datasets ? (
                      <GuageChart data={chartData} options={options} />
                    ) : ''
                  }
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#009FB5',
                          borderRadius: '50%',
                          marginRight: '5px',
                        }}
                      ></div>
                      <p style={{fontWeight: 700,
                          color: '#767676',}}>Correct <span style={{color: '#000'}}>{candResult?.correctAnswers}</span></p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#E15C52',
                          borderRadius: '50%',
                          marginRight: '5px',
                        }}
                      ></div>
                      <p style={{fontWeight: 700,
                          color: '#767676',}}>Wrong <span style={{color: '#000'}}>{candResult?.wrongAnswers}</span></p>
                    </div>
                  </div>
                  <div className='test-percent'>
                    <img src='/image/testResult.png' alt='testResult' />
                    <p>{Math.floor(candResult.percentage)}%</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-9 col-md-8'>
              <div className='card'>
                <div className='card-body'>
                  <div className='que-container'>
                    <div className='que-content'>
                      {console.log(candResult?.data)}
                      {
                        candResult?.data?.map((question, id) => {
                          return(
                            <div className='single-ques' key={id}>
                              <p className='fs-16 mb-1'><strong className='text-theme fw-700'>Question {id+1}</strong>/{candResult.data.length}{question.is_required === 1 && (<span className='text-danger'>*</span>)}</p>
                              <div className='row'>
                                <div className='col-md-9'>
                                  <p className='fw-700 mb-2'>{question?.question_text}</p>
                                  <p className='py-1 fw-700'>Answer: <span className={` ${question?.answer !== question?.ideal_answer ? "text-danger" : " text-theme"}`}>{question?.answer ? question?.answer : "Not attempted" }</span></p>
                                </div>
                                <div className='col-md-3'>
                                  <p className='fw-700 mb-2'>
                                    Ideal answer
                                  </p>
                                  <p className='fw-700 text-theme py-1'>{question?.ideal_answer}</p>
                                </div>
                                {id !== candResult.data.length - 1 && <hr className='mt-4' />}
                                 
                              </div>
                            </div>
                          )
                        })
                      }                  
                    </div>
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PreScreeningResult