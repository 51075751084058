import React, { useEffect, useState } from 'react'
import './index.css'
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../../../api';

const JobDetail = () => {
  const navigation = useNavigate()
  const location = useLocation();
  const { state } = location;
  const [jobDetail, setJobDetail] = useState([])
  useEffect(()=>{
    getJobDetail();
  },[])

  const getJobDetail = async () => {
    try {
      await API.get(`/job-board/view-job?jobId=${state?.jobId}`).then((res) => {
        if (res?.data.result) {
          setJobDetail(res.data.data[0]);
          console.log(res.data.data[0]);
        }
      })
    } catch (error) {
      if (error) {
        console.log(error);
      }
    }
  }

  function removeHtmlTags(html) {
    return html?.replace(/<[^>]*>/g, '');
  }

  function parseHtmlToReact(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const elements = doc.body.childNodes;
    
    return Array.from(elements).map((node, index) => {
      if (node.nodeName === 'UL') {
        return (
          <ul key={index}>
            {Array.from(node.childNodes).map((li, liIndex) => (
              <li key={liIndex}>{li.textContent}</li>
            ))}
          </ul>
        );
      }
      if (node.nodeName === 'P') {
        return <p key={index}>{node.textContent}</p>;
      }
      // Handle other tags as needed
      return null;
    });
  }

  return (
    <div className="page-space bg-clr-admin">
      <div className="page-title-heading justify-content-start align-items-center mb-2">
        <img src='/image/form-back-arrow.png' className='me-2 cursor-pointer' onClick={() => navigation(-1)} /> <h2 className='m-0'>View Job Description</h2>
      </div>
      <div className='jd-container'>
        <h2>Job title</h2>
        <p>{jobDetail?.full_title}</p>
        <h2 className='mt-4 '>Job Description</h2>
        {removeHtmlTags(jobDetail?.description)}
         <br />
         <br />
        <p>Responsibilities:</p>
        {parseHtmlToReact(jobDetail?.responsibilities)}
        <p>Nice to have:</p>
        {parseHtmlToReact(jobDetail?.nice_to_have)}
        <p>Requirements:</p>
        {parseHtmlToReact(jobDetail?.skills)}
        <p>Must skills:</p>
        {parseHtmlToReact(jobDetail?.must_skills)}
        <p>Working hours:</p>
        {parseHtmlToReact(jobDetail?.working_hrs)}
      </div>
    </div>
  )
}

export default JobDetail