import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";

const SaveAsListModel = ({
  loader,
  selectedSaveAsListing,
  modelHandler,
  saveAsListHandler,
}) => {
  const formik = useFormik({
    initialValues: {
      listName: "",
      description: "",
    },

    /*validationscheme*/
    validationSchema: Yup.object({
      listName: Yup.string().required("Please enter list name"),
      description: Yup.string().required("Please enter list description"),
    }),
    onSubmit: async (data, { resetForm }) => {
      saveAsListHandler(data);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  useEffect(() => {
    async function getEditListingFormValue() {
      await formik.setFieldValue("listName", selectedSaveAsListing?.name);
      await formik.setFieldValue("description", selectedSaveAsListing?.des);
    }
    getEditListingFormValue();
  }, []);

  return (
    <div className="click-model-close">
      <section className=" model-list-popup ">
        <div className="popup-bg-listadd ">
          <OutsideClickHandler
            onOutsideClick={() => {
              modelHandler(false);
            }}
          >
            <div className="popup-content-list-add ">
              <div className="logo-main ">
                <img src="/image/WORKBRIDGE.png " alt=" " />
              </div>
              <div className="close-btn " onClick={() => modelHandler(false)}>
                <img src="/image/chat-cut.png " alt=" " />
              </div>
              <div className="list-content-s">
                <p>Save as</p>
                <img src="/image/save-list-b.png" alt=" " />
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="crea-list-m ">
                  <div
                    className={`creadt-list-form ${isFormFieldValid("listName") && "error-input"
                      }`}
                  >
                    <label for="">List Name</label>
                    <br />
                    <input
                      type="text"
                      className=""
                      class="form-font-list "
                      name="listName"
                      value={formik.values.listName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("listName")}
                  </div>
                </div>
                <div
                  className={`creadt-list-form ${isFormFieldValid("description") && "error-input"
                    }`}
                >
                  <label for="">List Description</label>
                  <br />
                  <textarea
                    id=""
                    cols="30"
                    rows="4"
                    className="form-font-list"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {getFormErrorMessage("description")}
                </div>
                <div className="add-rem-list-btn ">
                  <button
                    className="can-list "
                    type="button "
                    onClick={() => modelHandler(false)}
                  >
                    Cancel
                  </button>
                  <button className="list-sub" type="submit">
                    {loader ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </OutsideClickHandler>
        </div>
      </section>
    </div>
  );
};

export default SaveAsListModel;
