import React, { useState } from 'react'
import './CandidateJourneyCard.css'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useDispatch, useSelector } from "react-redux";
import { getColor } from '../../../utils/progressbarFn';
import { useLocation, useNavigate } from 'react-router-dom';
import { setResumeCandidateId, setResumeCandidateTab } from '../../../redux/slices/bulkResumeCanDetailSlice';
import { setCandidateDetailId, setCandidateDetailResumeId, setjobDetailId } from '../../../redux/slices/candidateDetailSlice';

const CandidateJourneyCard = ({data}) => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);

  const showResult = (data) => {
    if(data.pre_screening_status?.toLowerCase() === "completed") {
      const userId = data.id.replace('task-', '')
      const searchParams = new URLSearchParams(location.search);
      const jobId = searchParams.get('job_id');
      navigate(`/pre-screening-question/result?candidateId=${userId}&jobId=${jobId}`)
    }
  }
  
  const openAIAnalysis = (data) => {
    if (data?.id !== null) {
      const userId = data.id.replace('task-', '')
      dispatch(setResumeCandidateId(userId));
      dispatch(setResumeCandidateTab("candidate-evaluation"));
      const searchParams = new URLSearchParams(location.search);
      const jobId = searchParams.get('job_id');
      dispatch(setCandidateDetailId(userId));
      dispatch(setCandidateDetailResumeId(data?.resume_id));
      dispatch(setjobDetailId(jobId));
      console.log(data)
      navigate(`/interview-journey/ai-analysis`);
    }
  }
  return (
    <div style={{ padding: '4px 0' }}>
      <div className="adJob-card">
        <div className="adJob-card-header">
          <div>
            <h3 onClick={() => openAIAnalysis(data)}>{data.name}</h3>
          </div>
          <div className='prog-bar'>
            <CircularProgressbar
              minValue="0"
              strokeWidth={12}
              value={Math.floor(data.match)}
              text={`${Math.floor(data.match)}%`}
              counterClockwise={false}
              styles={buildStyles({
                strokeLinecap: "round",
                pathTransitionDuration: 0.5,
                pathColor: getColor(Math.floor(data.match)),
                textColor: getColor(Math.floor(data.match)),
                textSize: "25px",
                trailColor: "#d6d6d6",
              })}
            />
          </div>
          <div className="ellipses-dd d-none">
            <button className="btn btn-transparent p-0" onClick={() => setShowMenu(!showMenu)}>
              <img src="/image/adJob-dd.png" alt="adJob-dd" />
            </button>
            <div className={showMenu ? 'cv-cions active' : 'cv-cions'}>
              <div className="dd-val">
                <img src="/image/edit-black.png" alt="" className="show-tooltip" />
                <p className="dd-content">Edit</p>
              </div>
              <div className="dd-val">
                <img src="/image/bin.png" alt="" className="show-tooltip" />
                <p className="dd-content">Delete</p>
              </div>
            </div>
          </div>
        </div>

        <div className='cand-detail'>
          {/* onClick={() => openAIAnalysis(data)} */}
          <span>{data.title}</span> 
          <br/>
          <span>{Math.floor(data.experience)} years of experience</span>
          <p className="with-icon">
            <img src="/image/msg-icon.png" alt="candcard" /> <p>{data.email} </p>
          </p>
          {
            data.phone !== "null" && (<p className="with-icon">
              <img src="/image/calling-icon.png" alt="candcard" /> <p> {data.phone}  </p>
            </p>)
          }
          {
            data.pre_screening_status !== '' && (
              <p className="with-icon">
              <span className={`${data.pre_screening_status}`}>
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g>
                  <path d="M4.34884 10.9584L7.02278 14.2524L12.1962 8.0035" stroke="#959595" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4.11251 7.01118L6.78646 10.3051L11.9599 4.05623" stroke="#959595" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                </svg>
              </span> 
              <span>
                Pre-screening questions: 
                <span className={`${data.pre_screening_status?.toLowerCase() === 'completed' ? 'text-underline cursor-pointer' : ''} text-success text-capitalize `} onClick={() => showResult(data)}>{data.pre_screening_status}</span>  </span>
            </p>
            )
          }
          
        </div>

        <div className="adJob-card-footer mt-3">
          <p>Posted date: {data?.date_posted?.split("-").reverse().join("-")}</p>
        </div>
      </div>
    </div>
  )
}

export default CandidateJourneyCard