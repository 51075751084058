import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.css";

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [
      // { list: "unordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const formats = ["bold", "italic", "underline", "list", "bullet", "indent"];

const BulkResumeUploadForm = ({
  zipResume,
  selectedFileName,
  loader,
  resumeModelHandler,
  uploadBulkResumeFileHandler,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState("");
  const [selectedZipFileName, setSelectedZipFileName] = useState("");
  const [selectedZipFileSize, setSelectedZipFileSize] = useState("");

  const formik = useFormik({
    initialValues: {
      jobTitle: "",
      jobDes: "",
      files: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      jobTitle: Yup.string().required("Please enter job title"),
      jobDes: Yup.string().required("Please enter job description"),
      files: Yup.mixed()
        .required("Please upload a zip file.")
        .test(
          "fileType",
          "Please upload a file in zip format",
          (value) =>
            !value ||
            ["application/zip", "application/x-zip-compressed"].includes(
              value?.type
            )
        )
        .test(
          "fileSize",
          "The file size should not exceed 4 GB",
          (value) => !value || value?.size <= 4 * 1024 * 1024 * 1024
        ),
    }),
    onSubmit: async (data, { resetForm }) => {
      try {
        uploadBulkResumeFileHandler(data);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleFileChange = () => {
    resumeModelHandler(true);
  };

  // Function to convert bytes to megabytes (MB)
  const bytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2);
  };

  // Function to convert bytes to gigabytes (GB)
  const bytesToGB = (bytes) => {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2);
  };

  // Function to format file size based on threshold
  const formatFileSize = (bytes) => {
    if (bytes >= 1024 * 1024 * 1024) {
      // Convert to GB
      return bytesToGB(bytes) + " GB";
    } else {
      // Convert to MB
      return bytesToMB(bytes) + " MB";
    }
  };

  const cleanZipFileHandler = () => {
    formik.setFieldValue("files", null);
    setSelectedZipFileName("");
    setSelectedZipFileSize("");
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  useEffect(() => {
    if (zipResume !== null) {
      formik.setFieldValue("files", zipResume);
      setSelectedZipFileName(selectedFileName);
      setSelectedZipFileSize(zipResume?.size);
    }
  }, [zipResume]);

  return (
    <>
      <div className="department-form-box">
        <form onSubmit={formik.handleSubmit}>
          <div className="first-part-f">
            <h3>Resume analysis request</h3>
            <p>
              Enter your title, paste your job description, and upload your
              resume zip file
            </p>
          </div>
          <div className="admin-from-step space-b-f">
            <div
              className={`f-admin resume-f-admin ${
                isFormFieldValid("jobTitle") && "error-input"
              }`}
            >
              <label for="">Job title </label>
              <br />
              <input
                type="text"
                name="jobTitle"
                id="dept-f"
                placeholder="Job title"
                className="form-font-admin"
                value={formik.values.jobTitle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("jobTitle")}
            </div>
            <div
              className={`f-admin resume-f-admin ${
                isFormFieldValid("jobDes") && "error-input"
              }`}
            >
              <label for="">Job description </label>
              <br />
              <ReactQuill
                theme="snow"
                className={`${isFormFieldValid("jobDes") && "error-input"}`}
                value={formik?.values?.jobDes ?? ""}
                placeholder="Copy/paste your job description here."
                name="jobDes"
                modules={modules}
                formats={formats}
                onChange={(data) => {
                  formik.setFieldValue("jobDes", data);
                  formik.handleChange("jobDes");
                }}
                onBlur={() => {
                  formik.handleBlur("jobDes");
                  // console.log("formik data ===>", formik.values.jobDes);
                }}
              />
              {getFormErrorMessage("jobDes")}
            </div>
            <div className="upload-res-part-f">
              <h3>Upload zip file</h3>
              <p>Only zip files are supported</p>
            </div>
            <div
              className={`bulk-res-file-upload icon-cursor ${
                isFormFieldValid("files") && "error-input"
              }`}
              onClick={handleFileChange}
            >
              <img src="/image/zip-res.png" alt="" />
              <p className="resume-bulk-res">
                Drag and Drop your zip file to start uploading
              </p>
              <div className="or-divider-res">
                <div className="divider-one-res"></div>
                <div className="text-or-res">OR</div>
                <div className="divider-one-res"></div>
              </div>
              <label for="file-input" id="candidate-res">
                <div className="bulk-res-f icon-cursor">Browse files</div>
                <input id="file-input" />
              </label>
            </div>
            {/* <div className="progress-bar-resume-bulk">
              <div className="file-progress-bar">
                <div className="progress-part-f">
                  <h3>Uploading...</h3>
                  <p>65% • 30 seconds remaining</p>
                </div>
                <div className="two-img-bulk">
                  <img src="/image/pause-file.png" alt="" />
                  <img src="/image/chat-cut.png" alt="" />
                </div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-success progress-bar-color"
                  role="progressbar"
                  style={{width: "60%"}}
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div> */}

            {selectedZipFileName !== "" && (
              <div className="file-uploaded-resume-bulk">
                <img src="/image/zip-res.png" alt="" />
                <div className="file-file-complte-bulk">
                  <div className="progress-part-f">
                    <p className="progress-part-h">
                      {selectedFileName}{" "}
                      {loader && (
                        <div>
                          <img
                            src="/image/bulk-file-back.png"
                            alt=""
                            className="progress-part-img"
                          />{" "}
                          <span className="load-heading">
                            Zip file uploading....
                          </span>
                        </div>
                      )}
                    </p>
                    <p>{formatFileSize(selectedZipFileSize)}</p>
                  </div>
                </div>
                <div
                  className="two-img-bulk icon-cursor"
                  onClick={() => cleanZipFileHandler()}
                >
                  <img src="/image/chat-cut.png" alt="" />
                </div>
              </div>
            )}
          </div>
          <div className="admin-form-btn">
            <button className="next-admin" type="submit">
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                `Analyze resumes`
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BulkResumeUploadForm;
