import React, { useState, useEffect } from 'react'
import API from "../../../../api";
import './CanDetailActivities.css'
import { BigPlayButton, Player } from 'video-react';
import ProfileSection from '../../../../components/Candidate/Profile/ProfileSection'

const CanDetailActivities = ({ appId }) => {
  const [candActivity, setCandActivity] = useState({})

  const getCandActivity = async () => {
    await API.get(`/get-applicant-profile?application_id=${appId}`)
      .then((response) => {
        setCandActivity(response.data.data)
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  }
  useEffect(() => {
    getCandActivity()
  }, [])
  
  const allValuesAreNull = Object.values(candActivity).every(value => value === null);

  return (
    <div className="tab-content ex1-content" id="ex1-content">
      <div
        className="tab-pane fade show active"
        id="ex1-tabs-1"
        role="tabpanel"
        aria-labelledby="ex1-tab-1"
      >
        {
          allValuesAreNull ? (<div className='no-interview text-center'><p>Candidate has not shared any additional information</p></div>) :( <div className="tabs-content-jour">
            <p className="content-heading-jour">Candidate profile</p>
            <div className='cand-activities'>
              {
                candActivity?.bio && (
                  <div className="profile-card">
                    <ProfileSection
                      sectionType='goals'
                      heading='Career goals'
                      description={candActivity?.bio}
                    />
                  </div>
                )
              }
              {
                candActivity?.my_motivations && (
                  <div className="profile-card">
                    <ProfileSection
                      sectionType='goals'
                      heading='What’s motivating you to look for a new job?'
                      description={candActivity?.my_motivations}
                    />
                  </div>
                )
              }
  
              {
                candActivity?.my_interests && (
                  <div className="profile-card">
                    <ProfileSection
                      sectionType='goals'
                      heading='My key skills & interests'
                      description={candActivity?.my_interests}
                    />
                  </div>
                )
              }
  
              {
                candActivity?.not_interested && (
                  <div className="profile-card">
                    <ProfileSection
                      sectionType='goals'
                      heading='Things I’m not interested in'
                      description={candActivity?.not_interested}
                    />
                  </div>
                )
              }
  
              {
                candActivity?.intro_video_link && (
                  <div className='row mt-md-5 mt-3 cand-meetup'>
                    <div className='col-md-6 mb-md-0 mb-3'>
                      <div className='cand-intro-vid'>
                        <h3>Meet your candidate. <img src='./image/arrows-sec.png' /></h3>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div style={{height: "300px"}}>
                      <Player src={candActivity?.intro_video_link}>
                        <BigPlayButton position="center" />
                      </Player>
                      </div>
                      {/* <img src='./image/cand-meetup.png' alt='cand-meetup' className='img-fluid w-100' /> */}
                    </div>
                  </div>
                )
              }
            </div>
          </div>)
        }
       
      </div>
    </div>
  )
}

export default CanDetailActivities