import React, { useRef, useEffect } from 'react';
import { Chart, ArcElement, Tooltip, Legend, registerables } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

// Register necessary components from Chart.js
Chart.register(...registerables, ArcElement, Tooltip, Legend);

const GuageChart = ({ data, options }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = chartRef.current;

    // Check if the chart and data are valid before calling update
    if (chart && data && data?.datasets && data?.datasets?.length > 0) {
      chart.update();
    }
  }, [data]); // Adding 'data' in dependencies ensures the chart updates when data changes

  // Return a fallback empty object if data is undefined
  const validData = data && data.datasets ? data : { datasets: [] };

  return (
    <>
      <Doughnut ref={chartRef} data={validData} options={options} />
    </>
  );
};

export default GuageChart;
