import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopNavBar from "./TopNavBar/TopNavBar";
import Footer from "./Footer/Footer";
import ContactUsModal from "../Common/COntactusModal/ContactusModal";
import ActionModal from "../Common/ActionModal/ActionModal";


const Layout = ({
  findJobHide,
  loginHide,
  signUpHide,
  toggleAuthHeader,
  isLogged,
  children,
}) => {
  const [contactModal, setContactModal] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const showSubmitModal = () => {
    setContactModal(false)
    setSubmitSuccess(!submitSuccess)
  }

  return (
    <>
      <TopNavBar
        findJobHide={findJobHide}
        loginHide={loginHide}
        signUpHide={signUpHide}
        toggleAuthHeader={toggleAuthHeader}
        isLogged={isLogged}
        setContactModal={setContactModal}
      />
      <ToastContainer />
      <main className="main-block">
        {children}
      </main>
      <Footer />
      {contactModal && <ContactUsModal setContactModal={setContactModal} showSubmitModal={showSubmitModal} />}
      {submitSuccess &&
        <ActionModal
          heading="Thank you!"
          content="Your message has been received we will get back to you shortly"
          closeModal={() => setSubmitSuccess(!submitSuccess)}
          modalArt="/image/success-modal.png"
        ></ActionModal>
      }
    </>
  );
};

export default Layout;
