import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  selectedJobId: null,
};
const jobDetailSlice = createSlice({
  name: "jobDetail",
  initialState,
  reducers: {
    getSelectJobId: (state) => {
      return state.selectedJobId;
    },
    setSelectJobId: (state, action) => {
      state.selectedJobId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getSelectJobId, setSelectJobId } = jobDetailSlice.actions;

export default jobDetailSlice.reducer;

export const jobDesPDFDataAsync = createAsyncThunk(
  "job/jobDesPDF",
  async ({ job_id }) => {
    const formData = new FormData();
    job_id && formData.append("job_id", job_id);

    try {
      const response = await API.post(`/auth/job_description_pdf`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
