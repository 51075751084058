import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { TRACKING_ID } from '../config';

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID); 
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
};

export default TrackPageView ;