const commentDate = (data) => {
  const date = new Date(data);
  const now = new Date();
  const diffInMs = now - date;
  const diffInHours = diffInMs / (1000 * 60 * 60);

  if (diffInHours < 24) {
    // If within the last 24 hours, display only the time in `hh:mm AM/PM` format
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  } else {
    // If older than 24 hours, display the date in `Mon DD` format (without the year)
    return date.toLocaleDateString([], {
      month: 'short',
      day: '2-digit',
    });
  }
};

export default commentDate;

