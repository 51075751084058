import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ metaData }) => {

  if (!metaData) {
    return null; // or a loading spinner
  }

  return (
    <Helmet>
      <title>{metaData?.title || 'Default Title'}</title>
      <meta name="description" content={metaData?.meta_description || 'Default Description'} />
      <meta name="robots" content={metaData?.meta_robots || 'index,follow'} />
      <link rel="canonical" href={metaData?.link_canonical || window?.location?.href} />
      <meta property="og:title" content={metaData?.og_title || metaData?.title || 'Default OG Title'} />
      <meta property="og:description" content={metaData?.og_description || metaData?.meta_description || 'Default OG Description'} />
      <meta property="og:image" content={metaData?.og_image || 'default-image-url.jpg'} />
      <meta property="og:url" content={metaData?.og_url || window?.location?.href} />
      <meta name="twitter:card" content={metaData?.twitter_card || 'summary_large_image'} />
      <meta name="twitter:title" content={metaData?.twitter_title || metaData?.title || 'Default Twitter Title'} />
      <meta name="twitter:description" content={metaData?.twitter_description || metaData?.meta_description || 'Default Twitter Description'} />
      <meta name="twitter:image" content={metaData?.twitter_image || 'default-twitter-image-url.jpg'} />
      <meta name="keywords" content={metaData?.keywords || 'default, keywords'} />   
    </Helmet>
  );
};

export default MetaTags;
