const dateTime = (data, type) => {
    const date = new Date(data);
    if(type === 'date') {
      return date.toLocaleDateString([], {
        year: 'numeric', 
        month: 'short', 
        day: '2-digit'
      });
    }
    if(type === 'time') {
      let hours = date.getUTCHours();
      const minutes = date.getUTCMinutes().toString().padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12
      return `${hours}:${minutes} ${ampm}`
    }
  }

  export default dateTime;