import React from 'react'
import LoginModel from '../../components/Common/LoginModel/LoginModel'
import './PrincingTable.css';
import PricingCardDemo from './PricingCardDemo'
import WorldConnect from '../../components/Common/WorldConnect/WorldConnect';
import { useSelector } from 'react-redux';
import { COUNTRY_ISO_CODE } from "../../constants";


const PricingTableMk = () => {
  const { countryISOCode } = useSelector(
    (state) => state.home
  );

  const pricingTable = [
    {
      title: 'Free plan',
      pricing: '0',
      priveVal: '/',
      pricingTitle: '(No credit card required)',
      pricingDesc: "Post up to 2 jobs at no cost with full access to all features.",
      buttonContent: 'Signup for free',
      pricingPoint: [
        'Create and post jobs fast with our AI-powered, bias-free tools that help you write clear, candidate-friendly job descriptions',
        "Manage applicants easily with user-friendly tools that help you move them smoothly through your process.",
        "Use AI-powered ranking to find the best fit.",
        "Get AI-generated interview questions, tailored for each role.",
        "Free prescreening and chat features to streamline hiring."
      ]
    },
    {
      title: 'Extra posting',
      pricing: '59',
      priveVal: '/30 days',
      pricingTitle: '',
      pricingDesc: 'Need more? Add extra job posts any time, no limits.',
      buttonContent: 'Buy now',
      pricingPoint: [
        "Need more posts? Pay per post—no limits, no subscriptions.",
        "All features from Free plan included."
      ]
    },
    {
      title: 'Boost post',
      pricing: '99',
      priveVal: '/week',
      headerTitle: 'Top Pick',
      pricingTitle: '',
      pricingDesc: 'Boost your job post for visibility and get faster responses with a promoted post.',
      buttonContent: 'PROMOTE NOW',
      pricingHeading: 'Includes all features from the Free plan:',
      pricingPoint: [
        'Buy a post and then boost it!',
        'Your job will appear at the top of search results with a "Promoted" tag.',
        "Promoted posts get more visibility and faster candidate responses.",
        "All features from Free plan included."
      ]
    },
    {
      title: 'Boost brand',
      pricing: '500',
      priveVal: '/30 days',
      headerTitle: 'Premium spotlight',
      pricingTitle: '',
      pricingDesc: 'Highlight your company and all job openings on our homepage!',
      buttonContent: 'PROMOTE NOW',
      pricingHeading: 'Includes all features from the Free plan:',
      pricingPoint: [
        'Feature your company on our homepage!',
        'Highlight all your job openings and boost your brand’s visibility!',
        'All features from Free plan included.'
      ]
    }
  ]
  return (
    <div>
      <div className="job-heading">
        <div className="container-site text-center py-md-5 py-3">
          <h1>Simplify your hiring with flexible pricing plans</h1>
          <p>Post for free to start, no credit card needed. Upgrade for <br className='d-md-block d-none' />
            more reach and faster results.</p>
            <div className='need-help'>
              <p>Need Help? <br/> <span>Chat with Us!</span></p>
            </div>
        </div>
        
      </div>
      <div className='container'>
        <div className='pricing-tabs mk-table'>
          <div className='price-card-sec'>
          <div className='left-box ps-0'>
            <div className='top-cls'>
              <img src='./image/loud.png' alt='image'/>
              <h1>Act Fast</h1>
            </div>
            <p className='des'>Promoted post and Promoted brand spots are limited. Act now to secure premium visibilityand attract quality candidates today.</p>
            <p className='ques-p'>Questions?</p>
            <p className='email-p'>If you have any questions please contact us at: support@polycareers.com</p>
          </div>
      
            {
              pricingTable.map((itm, ind) => {
                return (
                  <PricingCardDemo key={ind}
                    item={itm}
                    index={ind}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
      {/* <div className='container container-site'>
        <div className='row justify-content-center'>
          <div className='col-md-9'>
            <div className='boost-job'>
              <div className='boost-job-img'>
                <img src='./image/boost-demo.png' />
              </div>
              <div className='boost-job-desc'>
                <p className='mb-4'>
                  Promoted job slots and company spots are limited. Act now to secure premium visibility and attract quality candidates today.
                </p>
                <p>
                  <strong>If you have questions about our pricing page feel free to contact us by email support@polycareers.com.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <WorldConnect />
      <LoginModel />
      {/* <stripe-pricing-table
        pricing-table-id="prctbl_1OvtquKlPKTZ8WKL4452xQ12"
        publishable-key="pk_test_51Ovt1FKlPKTZ8WKLj2oLk6dYL3qdyzD80EaUKGZY6N8YYGxx3z9HdstU3k3Vr0dxsJdkQfRS2KeMqf4DtdZUKtRp00sE9Bt68y" /> */}
    </div>
  )
}

export default PricingTableMk























































// import React from 'react'
// import LoginModel from '../../components/Common/LoginModel/LoginModel'
// import './PrincingTable.css';
// import PricingCardDemo from './PricingCardDemo'
// import WorldConnect from '../../components/Common/WorldConnect/WorldConnect';
// import { useSelector } from 'react-redux';
// import { COUNTRY_ISO_CODE } from "../../constants";


// const PricingTableMk = () => {
//   const { countryISOCode } = useSelector(
//     (state) => state.home
//   );

//   const pricingTable = [
//     {
//       title: 'Free',
//       pricing: '0',
//       priveVal: ' CAD',
//       pricingTitle: '(No credit card required)',
//       pricingDesc: 'Access essential tools for candidates and hiring managers at no cost.',
//       buttonContent: 'Get started',
//       pricingPoint: [
//         'Free job postings (up to 5 positions at a time)',
//         'Free job posting creation tools (Bias-free and candidate-friendly)',
//         'Free applicant management tools (Easily filter and manage applicants)',
//         'Free AI Intelligent job ranking and assessment for fit',
//         'Free AI-generated interview questions (Role-specific)'
//       ]
//     },
//     {
//       title: 'Extra posting',
//       pricing: '59',
//       priveVal: ' CAD',
//       pricingTitle: '',
//       pricingDesc: 'Need to post more than 5 jobs? Purchase additional regular job postings without the promotion.',
//       buttonContent: 'Buy more Jobs',
//       pricingPoint: [
//         "Includes all free plan features. for more than 5 slots, buy additional postings for extra capacity"
//       ]
//     },
//     {
//       title: 'Promote posting',
//       pricing: '99',
//       priveVal: ' CAD',
//       pricingTitle: '',
//       pricingDesc: 'Upgrade from Free. Make your jobs stand out to top candidates. Get qualified candidates faster',
//       buttonContent: 'PROMOTE NOW',
//       pricingHeading: 'Includes all features from the Free plan:',
//       pricingPoint: [
//         'Your job post can appear at the top of search results with a “Promoted” tag',
//         'Free job postings lose visibility over time. Promoting your job posts get you candidates faster'
//       ]
//     },
//     {
//       title: 'Promote company',
//       pricing: '500',
//       priveVal: ' CAD',
//       pricingTitle: '',
//       pricingDesc: 'Feature your company on our main page, while promoting all your job openings.',
//       buttonContent: 'PROMOTE NOW',
//       pricingHeading: 'Includes all features from the Free plan:',
//       pricingPoint: [
//         'Company brand featured on the main page',
//         'All your jobs in one spot for candidates to easily find',
//         'All job postings appear in search results with a “Promoted” tag',
//         'Bonus resume bulk feature where you can upload hundreds of resumes at once and let our AI priorities and provide you with an analysis'
//       ]
//     }
//   ]
//   return (
//     <div>
//       <div className="job-heading">
//         <div className="container-site text-center py-md-5 py-3">
//           <h1>Simplify your hiring with flexible pricing plans</h1>
//           <p>Post for free to start, no credit card needed. Upgrade for <br className='d-md-block d-none' />
//             more reach and faster results.</p>
//         </div>
//       </div>
//       <div className='container container-site'>
//         <div className='pricing-tabs mk-table'>
//           <div className='price-card-sec'>
//             {
//               pricingTable.map((itm, ind) => {
//                 return (
//                   <PricingCardDemo key={ind}
//                     item={itm}
//                     index={ind}
//                   />
//                 )
//               })
//             }
//           </div>
//         </div>
//       </div>
//       <div className='container container-site'>
//         <div className='row justify-content-center'>
//           <div className='col-md-9'>
//             <div className='boost-job'>
//               <div className='boost-job-img'>
//                 <img src='./image/boost-demo.png' />
//               </div>
//               <div className='boost-job-desc'>
//                 <p className='mb-4'>
//                   Promoted job slots and company spots are limited. Act now to secure premium visibility and attract quality candidates today.
//                 </p>
//                 <p>
//                   <strong>If you have questions about our pricing page feel free to contact us by email support@polycareers.com.</strong>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <WorldConnect />
//       <LoginModel />
//       {/* <stripe-pricing-table
//         pricing-table-id="prctbl_1OvtquKlPKTZ8WKL4452xQ12"
//         publishable-key="pk_test_51Ovt1FKlPKTZ8WKLj2oLk6dYL3qdyzD80EaUKGZY6N8YYGxx3z9HdstU3k3Vr0dxsJdkQfRS2KeMqf4DtdZUKtRp00sE9Bt68y" /> */}
//     </div>
//   )
// }

// export default PricingTableMk