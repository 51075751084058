import React, { useEffect, useState } from 'react'
import './index.css'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { purchaseJObDataAsync } from '../../../../redux/slices/JobDecisionSlice';
import ContentModal from '../../../../components/Common/ContentModal/ContentModal';
import ConfettiModal from '../../../../components/Common/ConfettiModal/ConfettiModal';
import API from '../../../../api';

const BuyMorePost = () => {
  const { user } = useSelector((state) => state.auth);
  const [buyJobsQuantity, setBuyJobsQuantity] = useState(1);
  const [jobPurchased, setJobPurchased] = useState(false);
  const [jobPurchasedMore, setJobPurchasedMore] = useState("");
  const [availJobs, setAvailJobs] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const handlePurchasedJobs =() => {
    dispatch(
      purchaseJObDataAsync({
        user_id: user?.id,
        product_name: "purchase_job",
        // unit_amount: productPrice?.product?.price,
        quantity: buyJobsQuantity,
        success_url: "buy-more-posts",
        cancel_url: "buy-more-posts",
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const url = res?.checkout_url;
          const newWindow = window.open(url, "_self", "noopener,noreferrer");
          if (newWindow) {
            newWindow.opener = null; // Prevent new tab from having access to current tab
            newWindow.location.href = url; // Navigate the new tab to the specified URL
          } else {
            // Fallback if opening in new tab fails
            navigate(url); // Navigate within the current tab if new tab failed to open
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleJobConfirmationModel = () => {
    setJobPurchased(!jobPurchased);
    navigate("/buy-more-posts");
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get("paymentStatus");
    setJobPurchasedMore(paramValue);
    if (paramValue) {
      setJobPurchased(true);
    }
  }, []);


  useEffect(() => {
    async function getjobTypes() {
      try {
        await API.get(`/available-job-posts`)
          .then((response) => {
            if (response?.status === 201) {
              let res = response?.data;
              setAvailJobs(res);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
    getjobTypes();
    
  }, []);


  return (
    <>
    <div className="page-title-heading page-space">
      <h2>Buy more posts</h2>
    </div>
    <div className="coin-container page-space ms-4">
      <div className="coin-dd">
        <img className="pc-img" alt="img" src="./image/buy-more-icon.png" />
        <div className="flex-auto">
          <h3>You have {availJobs?.availableJobPosts} Jobs posts</h3>
          <p>
          PolyCareers offers 2 free job posts, always. If you need more, you can buy additional posts here for only $59. For greater visibility and faster results, buy a job post first, then promote it for an extra $99 weekly. Taxes extra.
          </p>
          <div className="coin-panel">
                <div className="single-panel">
                  <p className="m-0">Buy regular job post $59</p>
                   <div className='buy-more'>
                    <div className='d-flex justify-center items-center gap-2'>
                    <span className='qty'>Qty: </span>
                    <select className='slt' onChange={(e) => setBuyJobsQuantity(e.target.value)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    </div>
                    <button className="btn btn-black" onClick={handlePurchasedJobs}> Confirm </button>
                    </div>
                  
                </div>
            
          </div>
        </div>
      </div>
    </div>
    {jobPurchased && (
        <ContentModal closeModal={handleJobConfirmationModel}>
          <ConfettiModal
            closeModal={handleJobConfirmationModel}
            title={
              jobPurchasedMore === "paid" ? (
                <p>You've successfully subscribed.</p>
              ) : (
                <p>You've successfully purchased new jobs.</p>
              )
            }
          />
        </ContentModal>
      )}
  </>
  )
}

export default BuyMorePost