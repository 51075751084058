import { useFormik } from "formik";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import "./CreateListModel.css";

const CreateListModel = ({ loader, modelHandler, createListHandler }) => {
  const formik = useFormik({
    initialValues: {
      listName: "",
      description: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      listName: Yup.string()
        .max(30, "List name cannot be longer than 30 characters")
        .required("Please enter list name"),
      description: Yup.string().required("Please enter list description"),
    }),
    onSubmit: async (data, { resetForm }) => {
      createListHandler(data);

    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-body">
          <div className="mt-0 heading-list">
            <h3>Create list</h3>
            {" "}
            <img src="/image/table-list.png" alt="" />
          </div>
          <div className="crea-list-m text-start w-100">
            <div
              className={`label-field mb-3 ${isFormFieldValid("listName") && "error-input"
                }`}
            >
              <label for="">List name</label>
              <br />
              <input
                type="text"
                className=""
                class="form-font-list "
                name="listName"
                value={formik.values.listName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("listName")}
            </div>

            <div
              className={`label-field ${isFormFieldValid("description") && "error-input"
                }`}
            >
              <label for="">List description</label>
              <br />
              <textarea
                id=""
                cols="30"
                rows="4"
                className="form-font-list"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("description")}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-black"
            type="button"
            onClick={() => modelHandler(false, false)}
          >
            Cancel
          </button>
          <button className="btn btn-outline-black" type="submit">
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </>

  );
};

export default CreateListModel;
