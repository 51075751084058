import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";
import APIPvtDB from "../../apiPvtD";

const initialState = {
  candidateTab: "candidate-evaluation",
  candidateId: null,
  processId: null,
};
const bulkResumeCanDetailSlice = createSlice({
  name: "bulkResumeCanDetail",
  initialState,
  reducers: {
    setResumeCandidateTab: (state, action) => {
      state.candidateTab = action.payload;
    },
    setResumeCandidateId: (state, action) => {
      state.candidateId = action.payload;
    },
    setBulkResumeProcessId: (state, action) => {
      state.processId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setResumeCandidateTab,
  setResumeCandidateId,
  setBulkResumeProcessId,
} = bulkResumeCanDetailSlice.actions;

export default bulkResumeCanDetailSlice.reducer;

// asynchronous function with createAsyncThunk

export const getBulkResumeListingDataAsync = createAsyncThunk(
  "admin/getBulkResumeListingData",
  async ({ userId, search, status, currentPage, perPage, sortBy, sortDirection}) => {
    let params = `?`;  
    if (search !== "") {
      params += `search=${search}&`;
    }
    if (status !== null) {
      params += `status=${status}&`;
    }
    if (currentPage !== null) {
      params += `page=${currentPage}&`;
    }
    if (perPage !== "") {
      params += `per_page=${perPage}&`;
    }
    if (sortBy !== "") {
      params += `sort_by=${sortBy}&`;
    }
    if (sortDirection !== "") {
      params += `sort_direction=${sortDirection}&`;
    }

    try {
      const response = await APIPvtDB.get(
        `/job-board/bulk-analysis-listing${params}`
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const uploadBulkResumeFileAsync = createAsyncThunk(
  "admin/uploadBulkResumeFile",
  async ({ userId, jobTitle, jobDes, local_zip_file }) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("jobTitle", jobTitle);
    formData.append("jobDes", jobDes);
    local_zip_file !== null &&
      formData.append("local_zip_file", local_zip_file);

    try {
      const response = await API.post(`/job-board/upload-zip`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getBulkResumeJobDetailDataAsync = createAsyncThunk(
  "admin/getBulkResumeJobDetailData",
  async ({ process_id }) => {
    let params = `?`;
    if (process_id !== null) {
      params += `process_id=${process_id}`;
    }
    try {
      const response = await API.get(`/job-board/show-job-details${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getBulkResumeReqDetailDataAsync = createAsyncThunk(
  "admin/getBulkResumeReqDetailData",
  async ({
    process_id,
    search,
    status,
    processing_status,
    currentPage,
    perPage,
    sortBy,
    sortDirection,
  }) => {
    let params = `?`;
    if (process_id !== null) {
      params += `process_id=${process_id}&`;
    }
    if (search !== "") {
      params += `search=${search}&`;
    }
    if (status !== null) {
      params += `status=${status}&`;
    }
    if (processing_status !== null) {
      params += `processing_status=${processing_status}&`;
    }
    if (currentPage !== null) {
      params += `page=${currentPage}&`;
    }
    if (perPage !== "") {
      params += `per_page=${perPage}&`;
    }
    if (sortBy !== "") {
      params += `sort_by=${sortBy}&`;
    }
    if (sortDirection !== "") {
      params += `sort_direction=${sortDirection}&`;
    }
    try {
      const response = await API.get(`/job-board/get-request-details${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const lowFitResumeAnalysisAsync = createAsyncThunk(
  "admin/lowFitResumeAnalysis",
  async ({ process_id, candidate_id, file_path }) => {
    const formData = new FormData();
    process_id && formData.append("process_id", process_id);
    candidate_id && formData.append("candidate_id", candidate_id);
    file_path && formData.append("file_path", file_path);
 
    try {
      const response = await API.post(`/job-board/low-fit-resume-analysis`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getBulkAnalysisDataAsync = createAsyncThunk(
  "admin/getBulkResumeReqDetailData",
  async ({
    process_id,
    search,
    overallMatch,
    experience,
    impressionId,
    currentPage,
    perPage,
    sortBy,
    sortDirection,
  }) => {
    let params = `?`;
    if (process_id !== null) {
      params += `process_id=${process_id}&`;
    }
    if (search !== "") {
      params += `search=${search}&`;
    }
    if (overallMatch !== null) {
      params += `overall_match=${overallMatch}&`;
    }
    if (experience !== null) {
      params += `overall_experience=${experience}&`;
    }
    if (impressionId !== null) {
      params += `impression_id=${impressionId}&`;
    }
    if (currentPage !== null) {
      params += `page=${currentPage}&`;
    }
    if (perPage !== "") {
      params += `per_page=${perPage}&`;
    }
    if (sortBy !== "") {
      params += `sort_by=${sortBy}&`;
    }
    if (sortDirection !== "") {
      params += `sort_direction=${sortDirection}&`;
    }
    try {
      const response = await API.get(`/job-board/analysis-listing${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getBulkParsingResumeDataAsync = createAsyncThunk(
  "admin/getBulkParsingResumeData",
  async ({ candidateId }) => {
    let params = `?`;
    if (candidateId !== null) {
      params += `candidate_id=${candidateId}`;
    }
    try {
      const response = await API.get(`/job-board/parsed-resume-data${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const fvtBulkResumeCandAsync = createAsyncThunk(
  "admin/fvtBulkResumeCand",
  async ({ candidate_id, is_favorite }) => {
    let params = `?`;
    if (candidate_id !== null) {
      params += `candidate_id=${candidate_id}&`;
    }
    if (is_favorite !== null) {
      params += `is_favorite=${is_favorite}&`;
    }

    try {
      const response = await API.post(`/job-board/set-favorite${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const firstImpBulkResumeChangeAsync = createAsyncThunk(
  "admin/firstImpBulkResumeChange",
  async ({ candidate_id, impression_id }) => {
    let params = `?`;
    if (candidate_id !== null) {
      params += `candidate_id=${candidate_id}&`;
    }
    if (impression_id !== null) {
      params += `impression_id=${impression_id}&`;
    }

    try {
      const response = await API.post(`/job-board/set-impression${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const candtatusBulkResumeChangeAsync = createAsyncThunk(
  "admin/candtatusBulkResumeChange",
  async ({ candidate_id, candidate_status_id }) => {
    let params = `?`;
    if (candidate_id !== null) {
      params += `candidate_id=${candidate_id}&`;
    }
    if (candidate_status_id !== null) {
      params += `candidate_status_id=${candidate_status_id}&`;
    }

    try {
      const response = await API.post(
        `/job-board/set-candidate-status${params}`
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const downloadPdfBulkResumeAsync = createAsyncThunk(
  "admin/downloadPdfBulkResume",
  async ({ candidateId }) => {
    let params = `?`;
    if (candidateId !== null) {
      params += `candidate_id=${candidateId}`;
    }

    try {
      const response = await API.get(`/job-board/download-resume${params}`);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
