import React, { useState } from 'react'
import ShowRows from '../../../components/Common/Admin/ShowRows/ShowRows'
import TablePagination from '../../../components/Common/Admin/TablePagination/TablePagination';
import { SORT_DIRECTION, USER_TYPE } from "../../../constants";
import "./index.css";

const PendingAnalysis = () => {
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortByStartDate, setSortByStartDate] = useState("desc");

  const onsortByStartDateClick = () => {
    if (sortByStartDate === SORT_DIRECTION.ASC) {
      setSortByStartDate(SORT_DIRECTION.DESC);
    } else {
      setSortByStartDate(SORT_DIRECTION.ASC);
    }
  };
  return (
    <>
      <div className="page-space bg-clr-admin">
        <div className="page-title-heading">
          <h2>Pending analysis</h2>
          <button className='btn btn-black dp'><img className='img-btn' alt='img' src='./image/pc-icon-white.png' />{"250 PolyCareers credit "}</button>
        </div>{" "}
        <div className="filter-search-bar">
          <div className="two-drop-role">

          </div>

          <div className="col-right-searcgadmin">
            <div className="search-admin">
              <img src="/image/job-search.png" alt="" />
              <input
                type="text"
                name="search"
                id="jon-search-box"
                placeholder="Search"
                className="form-font-f"
                onChange={(e) => {
                  ""
                }}
              />
            </div>
          </div>
        </div>
        <ShowRows
          perPage={perPage}
        />
        <div className="department-table-imge">
          <div className="col-user-table">
            <div className="table-responsive">
              <table className="depart-table-left">
                <thead className="tb-heading-user">
                  <tr>
                    <th scope="col">
                      <div className="th-admin-heading">
                        Job title{" "}
                        <img
                          className="arrow-icon icon-cursor"
                          src={
                            sortByStartDate === SORT_DIRECTION.ASC
                              ? "/image/bars-arrow-up.png"
                              : "/image/bars-arrow-down.png"
                          }
                          alt=""
                          onClick={() => onsortByStartDateClick()}
                        />{" "}
                      </div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Zip file name</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">
                        Total resumes{" "}
                        <img
                          className="arrow-icon icon-cursor"
                          src={
                            sortByStartDate === SORT_DIRECTION.ASC
                              ? "/image/bars-arrow-up.png"
                              : "/image/bars-arrow-down.png"
                          }
                          alt=""
                          onClick={() => onsortByStartDateClick()}
                        />{" "}
                      </div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Status</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">
                        Date submitted{" "}
                        <img
                          className="arrow-icon icon-cursor"
                          src={
                            sortByStartDate === SORT_DIRECTION.ASC
                              ? "/image/bars-arrow-up.png"
                              : "/image/bars-arrow-down.png"
                          }
                          alt=""
                          onClick={() => onsortByStartDateClick()}
                        />{" "}
                      </div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Start process</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="tb-user">
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>01-01-2024</td>
                    <td><button className='btn btn-black dp'>Analyze</button></td>
                    <td>
                      <div className="four-role-img">

                        <div
                          className="img-text-tooltip"
                          onClick={"() => onEditUserClick(item?.id)"}
                        >
                          <img
                            src="/image/role-edit.png"
                            alt=""
                            class="show-tooltip2 icon-cursor"
                          />
                          <div className="tooltip-edit-role">Edit</div>
                        </div>


                        <div
                          className="img-text-tooltip"
                          onClick={() =>
                            "deleteModelHandler(true, item?.id)"
                          }
                        >
                          <img
                            src="/image/role-delet.png"
                            alt=""
                            class="show-tooltip3 icon-cursor"
                          />
                          <div className="tooltip-delete-role">
                            Delete
                          </div>
                        </div>

                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>01-01-2024</td>
                    <td><button className='btn btn-black dp'>Analyze</button></td>
                    <td>
                      <div className="four-role-img">

                        <div
                          className="img-text-tooltip"
                          onClick={"() => onEditUserClick(item?.id)"}
                        >
                          <img
                            src="/image/role-edit.png"
                            alt=""
                            class="show-tooltip2 icon-cursor"
                          />
                          <div className="tooltip-edit-role">Edit</div>
                        </div>


                        <div
                          className="img-text-tooltip"
                          onClick={() =>
                            "deleteModelHandler(true, item?.id)"
                          }
                        >
                          <img
                            src="/image/role-delet.png"
                            alt=""
                            class="show-tooltip3 icon-cursor"
                          />
                          <div className="tooltip-delete-role">
                            Delete
                          </div>
                        </div>

                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>01-01-2024</td>
                    <td><button className='btn btn-black dp'>Analyze</button></td>
                    <td>
                      <div className="four-role-img">

                        <div
                          className="img-text-tooltip"
                          onClick={"() => onEditUserClick(item?.id)"}
                        >
                          <img
                            src="/image/role-edit.png"
                            alt=""
                            class="show-tooltip2 icon-cursor"
                          />
                          <div className="tooltip-edit-role">Edit</div>
                        </div>


                        <div
                          className="img-text-tooltip"
                          onClick={() =>
                            "deleteModelHandler(true, item?.id)"
                          }
                        >
                          <img
                            src="/image/role-delet.png"
                            alt=""
                            class="show-tooltip3 icon-cursor"
                          />
                          <div className="tooltip-delete-role">
                            Delete
                          </div>
                        </div>

                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>01-01-2024</td>
                    <td><button className='btn btn-black dp'>Analyze</button></td>
                    <td>
                      <div className="four-role-img">

                        <div
                          className="img-text-tooltip"
                          onClick={"() => onEditUserClick(item?.id)"}
                        >
                          <img
                            src="/image/role-edit.png"
                            alt=""
                            class="show-tooltip2 icon-cursor"
                          />
                          <div className="tooltip-edit-role">Edit</div>
                        </div>


                        <div
                          className="img-text-tooltip"
                          onClick={() =>
                            "deleteModelHandler(true, item?.id)"
                          }
                        >
                          <img
                            src="/image/role-delet.png"
                            alt=""
                            class="show-tooltip3 icon-cursor"
                          />
                          <div className="tooltip-delete-role">
                            Delete
                          </div>
                        </div>

                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <TablePagination
              totalPages={3}
              currentPage={2}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PendingAnalysis