import Form from 'react-bootstrap/Form';
import ContentModal from "../ContentModal/ContentModal";

const JobPurchasedModel = ({
  handleJobModel,
  productPrice,
  setBuyJobsQuantity,
  handlePurchasedJobs
}) => {
  return (
    <>
      <ContentModal size="lg" closeModal={handleJobModel}>
        <div className="modal-body job-purchase">
          <h3>Building your dream team is just a few clicks away.</h3>
          <p>
            Add more job posts for ${productPrice?.product?.price} CAD each?
          </p>
          <div className="job-buy-option">
            <Form.Select
              className="qty-db"
              defaultValue="1"
              aria-label="Default select example"
              onChange={(e) => setBuyJobsQuantity(e.target.value)}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </Form.Select>
            <button className="btn btn-black" onClick={handlePurchasedJobs}>
              Confirm
            </button>
          </div>
        </div>
      </ContentModal>
    </>
  );
};

export default JobPurchasedModel;
