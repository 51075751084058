import { useState } from "react";
import "./CanDetailSkill.css";

const CanDetailSkill = ({
  parentID,
  mainHeading,
  targetId,
  enableProficiencyLevel,
  skillData,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const mapProficiencyToPercentage = (proficiency) => {
    const proficiencyLower = proficiency?.toLowerCase();

    if (proficiencyLower === "advanced") {
      return 85;
    } else if (proficiencyLower === "intermediate") {
      return 65;
    } else if (proficiencyLower === "beginner") {
      return 25;
    } else {
      return 0;
    }
  };

  const mapProficiencyColor = (proficiency) => {
    const proficiencyLower = proficiency?.toLowerCase();

    if (proficiencyLower === "advanced") {
      return "bg-ad";
    } else if (proficiencyLower === "intermediate") {
      return "bg-inter";
    } else if (proficiencyLower === "beginner") {
      return "bg-lower";
    } else {
      return 0;
    }
  };

  return (
    <div className="accordion accordion-flush skill-tab-accr" id={parentID}>
      <div className="accordion-item skill-tabs-item">
        <h2 className="accordion-header skill-tab-tittle">
          <button
            className={`accordion-button btn-skills-tabs ${
              isCollapsed ? "" : "collapsed"
            }`}
            type="button"
            onClick={toggleCollapse}
            aria-expanded={!isCollapsed}
            aria-controls={targetId}
          >
            {mainHeading}
          </button>
        </h2>
        <div
          id={targetId}
          className={`accordion-collapse collapse ${isCollapsed ? "" : "show"}`}
          aria-labelledby={`${parentID}-${targetId}`}
        >
          <div className="accordion-body tabs-skills-body">
            <table className="table table-bordered table-responsive skill-body-table">
              <thead>
                <tr>
                  <th scope="col" className="th-skill">
                    Skill name
                  </th>
                  {enableProficiencyLevel && (
                    <th scope="col" className="th-pro-level">
                      Proficiency level
                    </th>
                  )}

                  <th scope="col" className="th-descrip">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {skillData.map((skill, index) => (
                  <tr>
                    <td data-label="Skill name:" className="td-first-row">
                      {skill?.name}
                    </td>

                    {enableProficiencyLevel && (
                      <td
                        data-label="Proficiency level:"
                        className="td-seacond-row"
                      >
                        <div className="progress prgress-width">
                          <div
                            className={`progress-bar progress-bar-color ${mapProficiencyColor(
                              skill?.proficiency
                            )}`}
                            role="progressbar"
                            style={{
                              width: `${mapProficiencyToPercentage(
                                skill?.proficiency
                              )}%`,
                            }}
                            aria-valuenow="60"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        {skill?.proficiency}
                      </td>
                    )}
                    <td data-label="Description:" className="td-third-row">
                      {skill?.context}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanDetailSkill;
