const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes < 1024) return `${sizeInBytes} Bytes`;
  else if (sizeInBytes < 1024 ** 2)
    return `${(sizeInBytes / 1024).toFixed(2)} KB`;
  else if (sizeInBytes < 1024 ** 3)
    return `${(sizeInBytes / 1024 ** 2).toFixed(2)} MB`;
  else return `${(sizeInBytes / 1024 ** 3).toFixed(2)} GB`;
};

export default formatFileSize;
