import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import "../CreateListModel/CreateListModel.css";

const EditListModel = ({
  loader,
  selectedEditListing,
  modelHandler,
  editListHandler,
}) => {
  const formik = useFormik({
    initialValues: {
      listName: "",
      description: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      listName: Yup.string().required("Please enter list name"),
      description: Yup.string().required("Please enter list description"),
    }),
    onSubmit: async (data, { resetForm }) => {
      editListHandler(data);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  useEffect(() => {
    async function getEditListingFormValue() {
      await formik.setFieldValue("listName", selectedEditListing?.name);
      await formik.setFieldValue("description", selectedEditListing?.des);
    }
    getEditListingFormValue();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-body">
          <div className="mt-0 heading-list">
            <h3>Edit list</h3>
            <img src="/image/table-list.png" alt="" />
          </div>

          <div className="crea-list-m text-start w-100">
            <div
              className={`label-field mb-3 ${isFormFieldValid("listName") && "error-input"
                }`}
            >
              <label for="">List Name</label>
              <br />
              <input
                type="text"
                className=""
                class="form-font-list "
                name="listName"
                value={formik.values.listName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("listName")}
            </div>

            <div
              className={`label-field ${isFormFieldValid("description") && "error-input"
                }`}
            >
              <label for="">List Description</label>
              <br />
              <textarea
                id=""
                cols="30"
                rows="4"
                className="form-font-list"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("description")}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-black"
            type="button"
            onClick={() => modelHandler(false, false)}
          >
            Cancel
          </button>
          <button className="btn btn-outline-black" type="submit">
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Edit & Save"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default EditListModel;
