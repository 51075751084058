import React, { useEffect, useState } from 'react'
import ContentModal from '../../../Common/ContentModal/ContentModal'
import APIPvtDB from '../../../../apiPvtD';

const AddNewNotesModal = ({closeModal, enterMsg, editNote, getCommentsDetail}) => {
  const [inputValue, setInputValue] = useState('');
  const [wordCount, setWordCount] = useState(0);
  
  const handleInputChange = (e) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/);
    const currentWordCount = words.filter((word) => word).length;
    if (currentWordCount <= 100) {
      setInputValue(inputText);
      setWordCount(currentWordCount);
    }
  };
  const submitMsg = () => {
    if(enterMsg && !editNote) {
      enterMsg(inputValue)
    }
    if(editNote) {
      updateComment()
    }
  }

  const updateComment = async (data) => {
    try {
      const formData = {
        "id": editNote.id,
        "comments": inputValue
      }
      await APIPvtDB.post(`/update-note`, formData)
        .then((response) => {
          if (response.data.success) {
            getCommentsDetail()
            closeModal()
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setInputValue('')
    if(editNote) {
      setInputValue(editNote.comments)
    }
  }, [])
  return (
    <>
      <ContentModal closeModal={closeModal}>
        <div className="modal-body new-notes-box">
          <h3 className="mt-0">Create note</h3>
          <div className="crea-list-m text-start w-100">
            <div className="label-field">
              <label className='text-left'>Notes</label>
              <textarea className="form-control" rows="5" 
              onChange={handleInputChange} value={inputValue} placeholder="Type your note here..." />
            </div>
              <p className='text-right'>{`${wordCount} words`}</p>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-black" type="button" onClick={closeModal}>
            Cancel
          </button>
          <button className="btn btn-outline-black" onClick={submitMsg}>
            Save
          </button>
        </div>
      </ContentModal>
    </>
  )
}

export default AddNewNotesModal