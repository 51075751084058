import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";
import { COUNTRY_ISO_CODE, DOMAIN_CA, DOMAIN_MK } from "../../constants";

const initialState = {
  countryISOCode: COUNTRY_ISO_CODE.MK,
  openFeedbackModel: false,
};
const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setCountryISOCode: (state, action) => {
      state.countryISOCode = action.payload;
    },
    setFeedbackModel: (state, action) => {
      state.openFeedbackModel = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCountryISOCode, setFeedbackModel } = homeSlice.actions;

export default homeSlice.reducer;

// asynchronous function with createAsyncT

// Define a thunk action to determine the country ISO code asynchronously
export const determineCountryISOCodeAsync = () => (dispatch) => {
  const hostname = window.location.hostname;
  let countryISOCode = COUNTRY_ISO_CODE.MK; // Default to MK or CA, depending on your preference
  if (DOMAIN_MK.some((domain) => hostname.includes(domain))) {
    countryISOCode = COUNTRY_ISO_CODE.MK;
  } else if (DOMAIN_CA.some((domain) => hostname.includes(domain))) {
    countryISOCode = COUNTRY_ISO_CODE.CA;
  } else {
    countryISOCode = COUNTRY_ISO_CODE.CA;
  }
  dispatch(setCountryISOCode(countryISOCode));
};

export const userFeedBackAsync = createAsyncThunk(
  "public/userFeedBack",
  async ({ message, star_rating }) => {
    try {
      const formData = new FormData();
      message !== "" && formData.append("message", message);
      star_rating !== null && formData.append("star_rating", star_rating);

      const response = await API.post(`/user-feedback`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getFooterDataAsync = createAsyncThunk(
  "public/getFooterData",
  async () => {
    try {
      const response = await API.get(`/get-footer`);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
