import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ApplicantsTable from "../../../components/Common/ApplicantsTable/ApplicantsTable";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import API from "../../../api";
import APIPvtDB from "../../../apiPvtD";
import Button from "react-bootstrap/Button";

// Import Model
import ResumeModel from "../../../components/Common/ResumeModel/ResumeModel";
import CommentModel from "../../../components/Common/CommentModel/CommentModel";
import CreateListModel from "../../../components/Common/CreateListModel/CreateListModel";
import AddCanListModel from "../../../components/Common/AddCanListModel/AddCanListModel";
import ActionModal from "../../../components/Common/ActionModal/ActionModal";
import ShareListModel from "../../../components/Common/ShareListModel/ShareListModel";
import ContentModal from "../../../components/Common/ContentModal/ContentModal";

// Redux  Slice
import {
  candidateFirstImpressionChangeAsync,
  candidateStatusChangeAsync,
  changeCandidateStatusToResumeReviewedAsync,
  favoriteCandidateAsync,
} from "../../../redux/slices/employerDashboardSlice";
import {
  addJobCommentAsync,
  emptyCommentsModalData,
  setCandidateId,
  setJobId,
} from "../../../redux/slices/commentModalSlice";
import {
  deleteCandidateFromAllListAsync,
  deleteCandidateFromListAsync,
  setCandidateListingDropdown,
} from "../../../redux/slices/candidateListingSlice";
import {
  createListAsync,
  setActiveListingTab,
  shareListAsync,
} from "../../../redux/slices/listingSlice";
import OutsideClickHandler from "react-outside-click-handler";
import {
  CANDIDATE_STATUSES,
  EMP_CAND_LIST,
  EMP_LIST,
} from "../../../constants";
import "./EmpCandidateList.css";
import JobNoRecordFound from "../../../components/Common/JobNoRecordFound/JobNoRecordFound";

const EmpCandidateList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { candidateListId } = useSelector((state) => state.employerDashboard);
  const { candidateStatuses } = useSelector((state) => state.employerDashboard);
  const { listingDropDown } = useSelector((state) => state.candidateListing);

  const [msg, setMsg] = useState("");
  const [count, setCount] = useState(10);
  const [loader, setLoader] = useState(false);

  /*Toggle Model*/
  const [toggleModel, setToggleModel] = useState(false);
  const [toggleModelC, setToggleModelC] = useState(false);
  const [toggleCreateListModel, setToggleCreateListModel] = useState(false);
  const [toggleAddCanListModel, setToggleAddCanListModel] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [toggleDeleteCanListModel, settoggleDeleteCanListModel] =
    useState(false);
  const [toggleDeleteCanAllListModel, setToggleDeleteCanAllListModel] =
    useState(false);
  const [toggleShareListModel, settoggleShareListModel] = useState(false);

  const [candidatesList, setCandidatesList] = useState([]);
  const [jobDetail, setJobDetail] = useState({});
  const [selectedCompanyUser, setSelectedCompanyUser] = useState(null);

  /*Candidate Listing Menu*/
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isSharedMenuOpen, setIsSharedMenuOpen] = useState(false);
  const [activeSharedSubMenu, setActiveSharedSubMenu] = useState(null);

  /*Candidate Listing Dropdown Value*/

  const [myLists, setMyLists] = useState([]);
  const [listSharedWithMe, setListSharedWithMe] = useState([]);
  const [myListsAddCanModel, setMyListsAddCanModel] = useState([]);

  /*Candidate Listing Id Value For Add Candidate to List*/

  const [selectedCandidateId, setSelectedCandidateId] = useState([]);
  const [selectCandidateMyListsId, setSelectCandidateMyListsId] =
    useState(null);

  /* Candidate Listing filter selected value */

  const [overAllMatchDropDown, setOverAllMatchDropDown] = useState([]);
  const [experienceDropDown, setExperienceDropDown] = useState([]);
  const [educationDropDown, setEducationDropDown] = useState([]);
  const [impressionDropDown, setImpressionDropDown] = useState([]);
  const [statusDropDown, setStatusDropDown] = useState([]);

  const [selectedOverAllMatch, setSelectedOverAllMatch] = useState({
    label: null,
    value: null,
  });

  const [selectedExperience, setSelectedExperience] = useState({
    label: null,
    value: null,
  });

  const [selectedEducation, setSelectedEducation] = useState({
    label: null,
    value: null,
  });

  const [selectedImpression, setSelectedImpression] = useState({
    id: null,
    text: null,
  });

  const [selectedCandidateStatus, setSelectedCandidateStatus] = useState({
    id: null,
    text: null,
  });

  const [searchNewApplicant, setSearchNewApplicant] = useState(null);

  /*Candidate Listing Delete */

  const [selectedDeleteCanId, setSelectedDeleteCanId] = useState(null);

  /*Candidate Listing Menu Event */

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsSharedMenuOpen(false);
  };

  const toggleSubMenu = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  const toggleSharedMenu = () => {
    setIsSharedMenuOpen(!isSharedMenuOpen);
    setIsMenuOpen(false);
  };

  const toggleSharedSubMenu = (index) => {
    setActiveSharedSubMenu(activeSharedSubMenu === index ? null : index);
  };

  /***********************/

  /*  Candidate Listing filter Event */

  const onSearchNewApplicantChange = (value) => {
    setSearchNewApplicant(value);
  };

  const onOverAllMatchChange = (label, value) => {
    setSelectedOverAllMatch({
      label: label,
      value: value,
    });
  };

  const onOverAllMatchClean = () => {
    setSelectedOverAllMatch({
      label: null,
      value: null,
    });
  };

  const onExperienceChange = (label, value) => {
    setSelectedExperience({
      label: label,
      value: value,
    });
  };

  const onExperienceClean = () => {
    setSelectedExperience({
      label: null,
      value: null,
    });
  };

  const onEducationChange = (label, value) => {
    setSelectedEducation({
      label: label,
      value: value,
    });
  };

  const onEducationClean = () => {
    setSelectedEducation({
      label: null,
      value: null,
    });
  };

  const onFirstImpressionChange = (id, text) => {
    setSelectedImpression({
      id: id,
      text: text,
    });
  };

  const onFirstImpressionClean = () => {
    setSelectedImpression({
      id: null,
      text: null,
    });
  };

  const onCandidateStatusChange = (id, text) => {
    setSelectedCandidateStatus({
      id: id,
      text: text,
    });
  };

  const onCandidateStatusClean = () => {
    setSelectedCandidateStatus({
      id: null,
      text: null,
    });
  };

  /*************************************/

  const onCandidateListHandler = (id, name, value) => {
    setIsMenuOpen(false);
    setActiveSubMenu(null);
    setIsSharedMenuOpen(false);
    setActiveSharedSubMenu(null);
    if (id && name) {
      setSelectedCompanyUser(name)
    } else {
      if (value === 'ALL') {
        setSelectedCompanyUser('All candidates')
      }
    }

    dispatch(
      setCandidateListingDropdown({
        id,
        name,
        value,
      })
    );
  };

  const commentRemoved = (commentId, commentToReplace) => {
    const updatedCandidates = candidatesList.map((candidate) => {
      if (candidate?.comments?.id === commentId) {
        const newComment = {
          ...commentToReplace,
          comments_count:
            candidate?.comments?.comments_count > 0
              ? candidate?.comments?.comments_count - 1
              : 0,
        };

        if (commentToReplace === "") {
          return {
            ...candidate,
            comments: "",
          };
        }

        return {
          ...candidate,
          comments: {
            ...newComment,
          },
        };
      }
      return candidate;
    });
    setCandidatesList(updatedCandidates);
  };

  const commentAdded = (candidateId, jobId, comment) => {
    const updatedCandidatesList = candidatesList.map((candidate) => {
      if (
        candidate?.account_id === candidateId &&
        candidate?.job_id === jobId
      ) {
        const newComment = {
          ...comment,
          comments_count: candidate?.comments?.comments_count + 1,
        };

        return {
          ...candidate,
          comments: {
            ...newComment,
          },
        };
      }
      return candidate;
    });
    setCandidatesList(updatedCandidatesList);
  };
  const handleNewCommentSubmit = async (text, jobId, candidateId) => {
    if (text === null || text === undefined || text === "") return;
    if (jobId === null || jobId === undefined || jobId === "") return;
    if (candidateId === null || candidateId === undefined || candidateId === "")
      return;

    dispatch(
      addJobCommentAsync({
        userId: user?.id,
        jobId: jobId,
        candidateId: candidateId,
        commentText: text,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedCandidates = candidatesList.map((candidate) => {
            if (
              candidate?.account_id === candidateId &&
              candidate?.job_id === jobId
            ) {
              return {
                ...candidate,
                comments: {
                  ...res?.data[0]?.comments[0],
                  comments_count: 0,
                },
              };
            }
            return candidate;
          });
          setCandidatesList(updatedCandidates);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*Model Event Handler*/

  const modelHandler = (v) => setToggleModel(v);

  const modelCHandler = (v, candidate_id, job_id) => {
    setToggleModelC(v);

    if (v === false) {
      dispatch(emptyCommentsModalData());
      return;
    }
    if (
      candidate_id === null ||
      candidate_id === undefined ||
      candidate_id === "" ||
      job_id === null ||
      job_id === undefined ||
      job_id === ""
    ) {
      setToggleModelC(false);
      return;
    }

    dispatch(setJobId(job_id));
    dispatch(setCandidateId(candidate_id));
  };

  const createListModelHandler = (toggleModel, callAPi) => {
    setToggleCreateListModel(toggleModel);
    if (callAPi) {
      getEmpCandidatesListData();
    }
  };

  const addCanListModelHandler = (v) => {
    setSelectCandidateMyListsId(null);
    setToggleAddCanListModel(v);
  };

  const deleteCanListModelHandler = (toggleModel, CandidateId) => {
    setSelectedDeleteCanId(CandidateId);
    settoggleDeleteCanListModel(toggleModel);
  };

  const deleteCanAllListModelHandler = (toggleModel, CandidateId) => {
    setSelectedDeleteCanId(CandidateId);
    setToggleDeleteCanAllListModel(toggleModel);
  };

  const shareListModelHandler = (toggleModel) => {
    settoggleShareListModel(toggleModel);
  };

  /*Candidate Listing Id Value For Add Candidate to List*/

  const addAllCandidateIdHandler = () => {
    const allCandidateIds = candidatesList?.map(
      (candidate) => candidate?.account_id
    );

    const uniqueCandidateIds = Array.from(new Set(allCandidateIds));
    const updatedSelectedIds = [...selectedCandidateId, ...uniqueCandidateIds];
    setSelectedCandidateId(updatedSelectedIds);
  };

  const removeAllCandidateIdHandler = () => {
    setSelectedCandidateId([]);
  };

  const selectedCandidateIdHandler = (id) => {
    const isIdExists = selectedCandidateId?.includes(id);

    if (isIdExists) {
      const updatedItems = selectedCandidateId?.filter((item) => item !== id);
      setSelectedCandidateId(updatedItems);
    } else {
      setSelectedCandidateId([...selectedCandidateId, id]);
    }
  };

  const selectCandidateMyListsIdHandler = (id) => {
    setSelectCandidateMyListsId(id);
  };

  const candidateListSearchHandler = (e) => {
    let searchInput = e?.target?.value;

    if (searchInput !== "") {
      const searchedData = myLists.filter((data) =>
        data?.name.toLowerCase().includes(searchInput.toLowerCase())
      );
      if (searchedData?.length > 0) {
        setMyListsAddCanModel(searchedData);
      } else {
        setMyListsAddCanModel([]);
      }
    } else {
      setMyListsAddCanModel(myLists);
    }
  };

  /****************************************/

  const countHandler = () => {
    if (count < candidatesList?.length) setCount((prevCount) => prevCount + 10);
  };
  const onFirstImpressionClick = async (
    candidateId,
    jobId,
    impressionId,
    currentStatusId
  ) => {
    if (currentStatusId === CANDIDATE_STATUSES.NEW_APPLICANT) return;
    if (candidateId === null || jobId === null || impressionId === null) return;

    const selectedCandidatesList = candidatesList?.find(
      (applicant) =>
        applicant?.job_id === jobId && applicant?.account_id === candidateId
    );
    if (
      parseInt(selectedCandidatesList?.is_favorite) === 1 &&
      impressionId === 1
    ) {
      toast.error("Favourite candidate cannot have not a good fit status.", {
        autoClose: 2000,
        closeOnClick: false,
      });
      return;
    }

    dispatch(
      candidateFirstImpressionChangeAsync({
        candidateId: candidateId,
        jobId: jobId,
        impressionId: impressionId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = candidatesList.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                impression_id: res?.impression_removed === 1 ? 0 : impressionId,
              };
            }
            return applicant;
          });
          setCandidatesList(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onResumeClickHandler = async (candidateId, jobId, currentStatus) => {
    if (currentStatus !== CANDIDATE_STATUSES.NEW_APPLICANT) return;

    dispatch(
      changeCandidateStatusToResumeReviewedAsync({
        candidateId: candidateId,
        jobId: jobId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = candidatesList.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              return {
                ...applicant,
                status_id: res?.status_id,
                status: res?.status,
              };
            }
            return applicant;
          });
          setCandidatesList(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCandidateStatusChangeHandler = async (
    candidateId,
    jobId,
    statusId,
    currentStatusId
  ) => {
    if (candidateId === null || jobId === null || statusId === null) return;
    if (currentStatusId === statusId) return;
    dispatch(
      candidateStatusChangeAsync({
        candidateId: candidateId,
        statusId: statusId,
        jobId: jobId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedApplicants = candidatesList.map((applicant) => {
            if (
              applicant?.account_id === candidateId &&
              applicant?.job_id === jobId
            ) {
              const { id, status } = statusDropDown?.find(
                (item) => item.id === statusId
              );

              return {
                ...applicant,
                status_id: id,
                status: status,
              };
            }
            return applicant;
          });
          setCandidatesList(updatedApplicants);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFvtClick = async (account_id, job_id) => {
    const selectedCandidatesList = candidatesList?.find(
      (applicant) =>
        applicant?.job_id === job_id && applicant?.account_id === account_id
    );
    if (selectedCandidatesList?.impression_id === 1) {
      toast.error(
        "Not a good Fit candidate cannot be added to favourite list.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      return;
    }

    dispatch(
      favoriteCandidateAsync({
        userId: user?.id,
        accountId: account_id,
        jobId: job_id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const updatedCandidates = candidatesList?.map((candidate) => {
            if (
              candidate?.account_id === account_id &&
              candidate?.job_id === job_id
            ) {
              return {
                ...candidate,
                is_favorite: candidate?.is_favorite === 0 ? 1 : 0,
              };
            }
            return candidate;
          });
          setCandidatesList(updatedCandidates);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createListHandler = async ({ listName, description }) => {
    setLoader(true);
    dispatch(
      createListAsync({
        userId: user?.id,
        candidateListId: candidateListId,
        listName: listName,
        description: description,
      })
    )
      .then((response) => {
        const res = response?.payload;

        if (res?.result === true) {
          createListModelHandler(false, true);
          setLoader(false);
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          getEmpCandidatesListData();
        } else {
          createListModelHandler(false, true);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const addCandidateToListHandler = async () => {
    try {
      const formData = new FormData();
      user !== null && formData.append("user_id", user?.id);
      candidateListId != null && formData.append("job_id", candidateListId);
      selectCandidateMyListsId &&
        formData.append("list_id", selectCandidateMyListsId);
      selectedCandidateId !== null &&
        Object.keys(selectedCandidateId).forEach((key) => {
          formData.append("candidate_id[]", selectedCandidateId[key]);
        });

      setLoader(true);
      await API.post(`/job-board/add-candidates-to-list`, formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;

            if (res?.result === true) {
              setSelectCandidateMyListsId(null);
              setSelectedCandidateId([]);
              addCanListModelHandler(false);
              toast.success(res?.message?.success, {
                autoClose: 2000,
                closeOnClick: false,
              });
              getEmpCandidatesListData();

              setLoader(false);
            } else {
              setSelectCandidateMyListsId(null);
              setSelectedCandidateId([]);
              addCanListModelHandler(false);
              setLoader(false);
            }
          }
        })
        .catch((error) => {
          if (error) {
            const { response } = error;
            const { data } = response;
            setLoader(false);
            console.log(error);
          }
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const deleteCanListHandler = async () => {
    setLoader(true);
    dispatch(
      deleteCandidateFromListAsync({
        listId: listingDropDown?.id,
        candidateId: selectedDeleteCanId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        console.log("res", res);
        if (res?.result === true) {
          setLoader(false);
          settoggleDeleteCanListModel(false);
          toast.error(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          getEmpCandidatesListData();
        } else {
          settoggleDeleteCanListModel(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const deleteCanAllListHandler = () => {
    setLoader(true);
    dispatch(
      deleteCandidateFromAllListAsync({
        userId: user?.id,
        candidateId: selectedDeleteCanId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          setLoader(false);
          setToggleDeleteCanAllListModel(false);
          toast.error(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          getEmpCandidatesListData();
        } else {
          setToggleDeleteCanAllListModel(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const shareListHandler = async (selectCompanyUsersId) => {
    setLoader(true);
    dispatch(
      shareListAsync({
        userId: user?.id,
        selectedShareListId: listingDropDown?.id,
        selectCompanyUsersId: selectCompanyUsersId,
      })
    )
      .then((response) => {
        const res = response?.payload;

        if (res?.result === true) {
          settoggleShareListModel(false);
          setLoader(false);
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          getEmpCandidatesListData();
        } else {
          settoggleShareListModel(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const getEmpCandidatesListData = async () => {
    if (candidateListId !== null) {
      setMsg("");
      let params = `?`;      
      if (candidateListId !== null) {
        params += `job_id=${candidateListId}&`;
      }
      if (listingDropDown?.id !== null) {
        params += `list_id=${listingDropDown?.id}&`;
      }
      if (selectedOverAllMatch?.value !== null) {
        params += `overall_match=${selectedOverAllMatch?.value}&`;
      }
      if (selectedExperience?.value !== null) {
        params += `experience=${selectedExperience?.value}&`;
      }
      if (selectedEducation?.value !== null) {
        params += `education=${selectedEducation?.value}&`;
      }
      if (selectedImpression?.text !== null) {
        params += `impression_id=${selectedImpression?.id}&`;
      }
      if (selectedCandidateStatus?.text !== null) {
        params += `status_id=${selectedCandidateStatus?.id}&`;
      }
      if (searchNewApplicant !== null) {
        params += `search=${searchNewApplicant}&`;
      }

      try {
        await APIPvtDB.get(`/get-candidates${params}`)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              let res = response?.data;
              setCandidatesList(res?.candidates);
              setJobDetail(res?.jobDetail);

              setOverAllMatchDropDown(res?.overAllMatch[0]?.attribute_values);
              setExperienceDropDown(res?.experience[0]?.attribute_values);
              setEducationDropDown(res?.education[0]?.attribute_values);
              setImpressionDropDown(res?.impressions);
              setStatusDropDown(res?.statuses);
              setMyLists(res?.myLists);
              setMyListsAddCanModel(res?.myLists);
              setListSharedWithMe(res?.listSharedWithMe);

              if(res?.candidates?.length === 0){
                setMsg("This search did not return any results.");
              }
              else {
                setMsg("");
              }
            }
          })
          .catch((error) => {
            if (error) {
              setMsg("");
              console.log(error);
            }
          });
      } catch (error) {
        setMsg("");
        console.log(error);
      }
    } else {
      setMsg("");
      console.log("else");
    }
  };

  useEffect(() => {
    getEmpCandidatesListData();
  }, [
    candidateListId,
    listingDropDown?.id,
    searchNewApplicant,
    selectedOverAllMatch,
    selectedExperience,
    selectedEducation,
    selectedImpression,
    selectedCandidateStatus,
  ]);

  return (
    <>
      <div className="position-relative mt-3">
        <div className="container container-site">
          {listingDropDown?.value === EMP_CAND_LIST.ALL ? (
            <Button
              variant="link"
              className="back-to-home with-text p-0 add"
              onClick={() => navigate(-1)}
            >
              <img src="/image/form-back-arrow.png" alt="" className="mr-1" />
            </Button>
          ) : (
            <Button
              variant="link"
              className="back-to-home with-text p-0 add"
              onClick={() =>
                onCandidateListHandler(null, "", EMP_CAND_LIST.ALL)
              }
            >
              <img src="/image/form-back-arrow.png" alt="" className="mr-1" />
            </Button>
          )}
        </div>
      </div>

      <section className="job-heading mt-md-5 mt-3">
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="job-search-box-row position-relative">
              <div className="job-heading-home smilar-job-heading job-heading-local w-100">
                <h2>
                  {" "}
                  {jobDetail?.name ? `Job title: ${jobDetail?.name}` : ""}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container container-site">
          <div className="row mx-0">
            <div className="employee-searchbox-btn">
              <div className="employee-list-fllter-container">
                <div className="two-btn-list">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setIsMenuOpen(false);
                      setActiveSubMenu(null);
                      setIsSharedMenuOpen(false);
                      setActiveSharedSubMenu(null);
                    }}
                  >
                    <div className="multi-dropdown-box bg-transparent mt-md-0 mt-4">
                      <ul className="first-menu-dropdown">
                        <li
                          className={`sub-menu-dropdpwn main-dropdown-heading ${isMenuOpen ? "active" : ""
                            }`}
                        >
                          <Link
                            className="sub-menu-lists clr-b-chng"
                            aria-current="page"
                            onClick={toggleMenu}
                          >
                            {selectedCompanyUser ? selectedCompanyUser : 'Candidate list'}
                            <img
                              src="/image/company--detail-filter.png"
                              alt=""
                              className=""
                            />
                          </Link>

                          <ul
                            className={`second-menu-dropdown second-menu-dropdown-sroll ${isMenuOpen ? "d-block" : ""
                              }`}
                          >
                            <li
                              className="sub-menu-dropdpwn sub-menu-dropdpwn-list-f"
                              onClick={() =>
                                onCandidateListHandler(
                                  null,
                                  "",
                                  EMP_CAND_LIST.ALL
                                )
                              }
                            >
                              <Link className="sub-menu-lists">
                                All candidates
                                <img
                                  src=""
                                  width=""
                                  height=""
                                  className=""
                                  alt=""
                                />
                              </Link>
                            </li>

                            {myLists?.length > 0 && (
                              <li
                                className={`sub-menu-dropdpwn ${activeSubMenu === 1 ? "active" : ""
                                  }`}
                              >
                                <Link
                                  className="sub-menu-lists"
                                  onClick={() => toggleSubMenu(1)}
                                >
                                  Lists created by me
                                  <img
                                    src="/image/cand-vactor.png"
                                    className=""
                                    alt=""
                                  />
                                </Link>
                                <ul
                                  className={`second-menu-dropdown second-menu-dropdown-border ${activeSubMenu === 1 ? "d-block" : ""
                                    }`}
                                >
                                  {myLists?.map((j, i) => (
                                    <li
                                      className="sub-menu-dropdpwn"
                                      onClick={() =>
                                        onCandidateListHandler(
                                          j?.id,
                                          j?.name,
                                          EMP_CAND_LIST.MY_LIST
                                        )
                                      }
                                    >
                                      <Link className="sub-menu-lists">
                                        {j?.name}
                                        <img
                                          src="/image/table-list.png"
                                          className="page-copy-img"
                                          alt=""
                                        />
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            )}

                            {listSharedWithMe?.length > 0 && (
                              <li
                                className={`sub-menu-dropdpwn ${activeSubMenu === 2 ? "active" : ""
                                  }`}
                              >
                                <Link
                                  className="sub-menu-lists"
                                  onClick={() => toggleSubMenu(2)}
                                >
                                  Lists shared with me
                                  <img
                                    src="/image/cand-vactor.png"
                                    className=""
                                    alt=""
                                  />
                                </Link>
                                <ul
                                  className={`second-menu-dropdown second-menu-dropdown-border ${activeSubMenu === 2 ? "d-block" : ""
                                    }`}
                                >
                                  {listSharedWithMe?.map((j, i) => (
                                    <li
                                      className="sub-menu-dropdpwn"
                                      onClick={() =>
                                        onCandidateListHandler(
                                          j?.id,
                                          j?.name,
                                          EMP_CAND_LIST.SHARE_LIST
                                        )
                                      }
                                    >
                                      <Link className="sub-menu-lists">
                                        {j?.name}
                                        <img
                                          src="/image/table-list.png"
                                          className="page-copy-img"
                                          alt=""
                                        />
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            )}

                            <li
                              className="sub-menu-dropdpwn sub-menu-dropdpwn-list-f"
                              onClick={() => {
                                dispatch(setActiveListingTab(null));
                                navigate("/employer-list");
                              }}
                            >
                              <Link className="sub-menu-lists">
                                All lists
                                <img
                                  src=""
                                  width=""
                                  height=""
                                  className=""
                                  alt=""
                                />
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </OutsideClickHandler>
                </div>

                {listingDropDown?.value === EMP_CAND_LIST.ALL && (
                  <div className="two-btn-list create-listing-btns">
                    <div
                      className={`employee-list-btn-create fix-width-btn ${selectedCandidateId?.length === 0 &&
                        "list-btn-create-opcity"
                        }`}
                    >
                      <button
                        onClick={() => {
                          selectedCandidateId?.length > 0 &&
                            addCanListModelHandler(true);
                        }}
                      >
                        Add to list
                      </button>
                    </div>
                    <div class="employee-list-btn fix-width-btn">
                      <button onClick={() => createListModelHandler(true)}>
                        Create list +
                      </button>
                    </div>
                  </div>
                )}

                {listingDropDown?.value === EMP_CAND_LIST.MY_LIST && (
                  <div className="two-btn-list w-100">
                    <div className="employee-list-btn fix-width-btn">
                      <button
                        onClick={() => {
                          settoggleShareListModel(true);
                        }}
                      >
                        Share List <img src="/image/share-list.png" alt="" />
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="filter-search-box">
                <img src="../image/job-search.png" alt="" />
                <input
                  type="text"
                  name=""
                  id="jon-search-box"
                  placeholder="Search applicants"
                  className="form-font-f"
                  onChange={(e) => {
                    onSearchNewApplicantChange(e?.target?.value);
                  }}
                />
              </div>
            </div>

            <div className="divider-opcity mt-5 mt-md-0"></div>
            <div className="j-fillter-only-table">
              <div className="job-fillter-company-table">
                <div className="j-company-fillter-table">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1-table"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-filter-icon-table">
                      <p>
                        {" "}
                        {selectedOverAllMatch?.label === null
                          ? "Overall match"
                          : selectedOverAllMatch?.label?.length >= 18
                            ? selectedOverAllMatch?.label?.substring(0, 18) +
                            " ..."
                            : selectedOverAllMatch?.label}
                      </p>
                      {selectedOverAllMatch?.label === null ? (
                        <img src="/image/company--detail-filter.png" alt="" />
                      ) : (
                        <div
                          id="dropdown-boxId2"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/image/cross-b.png"
                            alt=""
                            onClick={() => onOverAllMatchClean()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {overAllMatchDropDown?.length > 0 &&
                        overAllMatchDropDown?.map((item) => (
                          <li
                            key={item?.id}
                            onClick={() =>
                              onOverAllMatchChange(item?.label, item?.value)
                            }
                          >
                            {item?.label}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="job-fillter-company-table">
                <div className="j-company-fillter-table">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1-table"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-filter-icon-table">
                      <p>
                        {" "}
                        {selectedExperience?.label === null
                          ? "Experience"
                          : selectedExperience?.label?.length >= 18
                            ? selectedExperience?.label?.substring(0, 18) + " ..."
                            : selectedExperience?.label}
                      </p>
                      {selectedExperience?.label === null ? (
                        <img src="/image/company--detail-filter.png" alt="" />
                      ) : (
                        <div
                          id="dropdown-boxId2"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/image/cross-b.png"
                            alt=""
                            onClick={() => onExperienceClean()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {experienceDropDown?.length > 0 &&
                        experienceDropDown?.map((item) => (
                          <li
                            key={item?.id}
                            onClick={() =>
                              onExperienceChange(item?.label, item?.value)
                            }
                          >
                            {item?.label}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="job-fillter-company-table d-none">
                <div className="j-company-fillter-table">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1-table"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-filter-icon-table">
                      <p>
                        {" "}
                        {selectedEducation?.label === null
                          ? "Education"
                          : selectedEducation?.label?.length >= 18
                            ? selectedEducation?.label?.substring(0, 18) + " ..."
                            : selectedEducation?.label}
                      </p>
                      {selectedEducation?.label === null ? (
                        <img src="/image/company--detail-filter.png" alt="" />
                      ) : (
                        <div
                          id="dropdown-boxId2"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/image/cross-b.png"
                            alt=""
                            onClick={() => onEducationClean()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {educationDropDown?.length > 0 &&
                        educationDropDown?.map((item) => (
                          <li
                            key={item?.id}
                            onClick={() =>
                              onEducationChange(item?.label, item?.value)
                            }
                          >
                            {item?.label}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="job-fillter-company-table">
                <div className="j-company-fillter-table">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1-table"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-filter-icon-table">
                      <p>
                        {" "}
                        {selectedImpression?.text === null
                          ? "First impression"
                          : selectedImpression?.text?.length >= 18
                            ? selectedImpression?.text?.substring(0, 18) + " ..."
                            : selectedImpression?.text}
                      </p>
                      {selectedImpression?.text === null ? (
                        <img src="/image/company--detail-filter.png" alt="" />
                      ) : (
                        <div
                          id="dropdown-boxId2"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/image/cross-b.png"
                            alt=""
                            onClick={() => onFirstImpressionClean()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {impressionDropDown?.length > 0 &&
                        impressionDropDown?.map((impression) => (
                          <li
                            key={impression?.id}
                            onClick={() =>
                              onFirstImpressionChange(
                                impression?.id,
                                impression?.impression
                              )
                            }
                          >
                            {impression?.impression}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="job-fillter-company-table">
                <div className="j-company-fillter-table">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1-table"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-filter-icon-table">
                      <p>
                        {" "}
                        {selectedCandidateStatus?.text === null
                          ? "Candidate status"
                          : selectedCandidateStatus?.text?.length >= 12
                            ? selectedCandidateStatus?.text?.substring(0, 12) +
                            " ..."
                            : selectedCandidateStatus?.text}
                      </p>
                      {selectedCandidateStatus?.text === null ? (
                        <img src="/image/company--detail-filter.png" alt="" />
                      ) : (
                        <div
                          id="dropdown-boxId2"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/image/cross-b.png"
                            alt=""
                            onClick={() => onCandidateStatusClean()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {statusDropDown?.length > 0 && statusDropDown?.map((status) => (
                        <li
                          key={status?.id}
                          onClick={() =>
                            onCandidateStatusChange(status?.id, status?.status)
                          }
                        >
                          {status?.status}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="divider-opcity hor-line-hide"></div>
          </div>
        </div>
      </section>

      <section className="job-heading mt-4 section-hide">
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="job-search-box-row position-relative">
              <div className="job-heading-home smilar-job-heading job-heading-local w-100">
                <h2> Candidate list</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container container-site">
          <div className="row mx-0">
            <div className="job-listing">
              <div className="job-listing-header">
                {listingDropDown?.value === EMP_CAND_LIST.ALL && (
                  <div className="lr checkbox">
                    <input
                      type="checkbox"
                      name=""
                      class="td-tr-chk"
                      checked={
                        candidatesList?.length === selectedCandidateId?.length
                          ? true
                          : false
                      }
                      onClick={() => {
                        candidatesList?.length === selectedCandidateId?.length
                          ? removeAllCandidateIdHandler()
                          : addAllCandidateIdHandler();
                      }}
                    />
                  </div>
                )}
                <div className="lr full-name">Full name</div>
                <div className="lr j-title">Job applied to</div>
                <div className="lr experience">Experience</div>
                <div className="lr cand-status">Candidate status</div>
                <div className="lr match">Overall match</div>
                <div className="lr impression">Impression</div>
                <div className="lr date-app">Date applied</div>
                <div className="lr action">Action</div>
              </div>
              <div className="job-listing-mb">
                {candidatesList?.length > 0 &&
                  candidatesList?.slice(0, count)?.map((item, index) => (
                    <div className="job-listing-body">
                      <ApplicantsTable
                        showCheckBox={true}
                        selectedCandidateId={selectedCandidateId}
                        index={index}
                        item={item}
                        candidateStatusesList={statusDropDown}
                        onFvtClick={onFvtClick}
                        modelHandler={modelHandler}
                        modelCHandler={modelCHandler}
                        onCandidateStatusChangeHandler={
                          onCandidateStatusChangeHandler
                        }
                        onFirstImpressionClick={onFirstImpressionClick}
                        onResumeClickHandler={onResumeClickHandler}
                        handleAddNewCommentHandler={handleNewCommentSubmit}
                        handleRemovedComment={commentRemoved}
                        selectedCandidateIdHandler={selectedCandidateIdHandler}
                        deleteCanListModelHandler={deleteCanListModelHandler}
                        deleteCanAllListModelHandler={
                          deleteCanAllListModelHandler
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="divider"></div>
          </div>
          {msg !== "" && candidatesList?.length === 0 && (
            <>
              <JobNoRecordFound msg={msg} />
              <div className="divider"></div>
            </>
          )}
        </div>
        {candidatesList?.length > 10 && (
          <div class="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See more</button>
            </Link>
          </div>

        )}
      </section>
      <WorldConnect />
      {toggleModel && <ResumeModel modelHandler={modelHandler} />}
      {toggleModelC && (
        <CommentModel
          modelCHandler={modelCHandler}
          commentAdded={commentAdded}
          commentRemoved={commentRemoved}
        />
      )}

      {toggleCreateListModel && (
        <ContentModal closeModal={createListModelHandler}>
          <CreateListModel
            loader={loader}
            modelHandler={createListModelHandler}
            createListHandler={createListHandler}
          />
        </ContentModal>
      )}
      {toggleAddCanListModel && (
        <ContentModal closeModal={addCanListModelHandler}>
          <AddCanListModel
            loader={loader}
            myLists={myListsAddCanModel}
            selectCandidateMyListsId={selectCandidateMyListsId}
            modelHandler={addCanListModelHandler}
            selectCandidateMyListsIdHandler={selectCandidateMyListsIdHandler}
            candidateListSearchHandler={candidateListSearchHandler}
            addCandidateToListHandler={addCandidateToListHandler}
          />
        </ContentModal>
      )}
      {toggleDeleteCanListModel && (
        <ActionModal
          closeModal={() => deleteCanListModelHandler(false, null)}
          modalArt="/image/warning-wishlist.png"
          heading="Double-Check"
          content={`Are you sure you want to delete this candidate?`}
        >
          <button
            className="btn btn-black"
            onClick={() => deleteCanListModelHandler(false, null)}
          >
            No
          </button>
          <button
            className="btn btn-outline-black"
            onClick={() => deleteCanListHandler()}
          >
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Yes"
            )}
          </button>
        </ActionModal>
      )}
      {toggleDeleteCanAllListModel && (
        <ActionModal
          closeModal={() => deleteCanAllListModelHandler(false, null)}
          modalArt="/image/warning-wishlist.png"
          heading="Double-Check"
          content={`Are you sure you want to delete this candidate?`}
        >
          <button
            className="btn btn-black"
            onClick={() => deleteCanAllListModelHandler(false, null)}
          >
            No
          </button>
          <button
            className="btn btn-outline-black"
            onClick={() => deleteCanAllListHandler()}
          >
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Yes"
            )}
          </button>
        </ActionModal>
      )}

      {toggleShareListModel && (
        <ContentModal closeModal={shareListModelHandler}>
          <ShareListModel
            loader={loader}
            modelHandler={shareListModelHandler}
            shareListHandler={shareListHandler}
          />
        </ContentModal>
      )}
    </>
  );
};

export default EmpCandidateList;
