import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import API from "../../api";
import { RESUME_MODE } from "../../constants";

const initialState = {
};
const myResumeSlice = createSlice({
  name: "myResume",
  initialState,
  reducers: {
  },
});

// Action creators are generated for each case reducer function
export const { } = myResumeSlice.actions;

export default myResumeSlice.reducer;

// asynchronous function with createAsyncThunk

export const generateSignedURLDataAsync = createAsyncThunk(
  "myResume/generateSignedURLData",
  async ({ candidate_id, resume_id, file_type, application_id }) => {
    const formData = new FormData();
    candidate_id && formData.append("candidate_id", candidate_id);
    resume_id && formData.append("resume_id", resume_id);
    file_type && formData.append("file_type", file_type);
    application_id && formData.append("application_id", application_id);

    try {
      const response = await API.post(`/auth/generate-signed-url`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
