import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BaseInfo from "../../../components/Candidate/Dashboard/BaseInfo";
import JobApplied from "../../../components/Candidate/Dashboard/JobApplied";
import RecJob from "../../../components/Candidate/Dashboard/RecJob";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import { setInboxCount } from "../../../redux/slices/AuthSlice";
import APIPvtDB from "../../../apiPvtD";
import "./index.css";
import API from "../../../api";


const CanDashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { jobAppliedId, withdrawJobId } = useSelector(
    (state) => state.candidateDashboard
  );
  const [appliedJobsCount, setAppliedJobsCount] = useState(0);
  const [recJobsCount, setRecJobsCount] = useState(0);
  const [canJourneyCount, setCanJourneyCount] = useState(0);


  const [appliedJobs, setAppliedJobs] = useState([]);
  const [recJobs, setRecJobs] = useState([]);

  const [showMsg, setShowMSg] = useState(false);
  const [searchJobApplied, setSearchJobApplied] = useState("");

  const onSearchJobApplied = (value) => {
    setSearchJobApplied(value);
  };

  const getCanDashboardData = async () => {
    if (user?.id) {
      let params = `?`;      
      if (searchJobApplied !== "") {
        params += `search=${searchJobApplied}&`;
      }
      try {
        await API.get(`/candidate-dashboard${params}`)
          .then((response) => {
            if (
              response?.status === 200 ||
              response?.status === 201 ||
              response?.status === 304
            ) {
              let res = response?.data;

              setAppliedJobsCount(res?.applied_jobs_count);
              setRecJobsCount(res?.recommended_jobs_count);
              setAppliedJobs(res?.applied_jobs);
              setRecJobs(res?.recommended_jobs);
              dispatch(setInboxCount(res?.unread_conversation_count));
              setCanJourneyCount(res?.candidate_journey_count);
              setShowMSg(true);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (withdrawJobId !== null) {
      const updatedAppliedJobs = appliedJobs?.filter(
        (job) => job?.id !== withdrawJobId
      );
      const recommendedJobs = appliedJobs?.find(
        (job) => job?.id === withdrawJobId
      );

      if (recJobsCount >= 0) setRecJobsCount(recJobsCount + 1);
      if (appliedJobsCount > 0) setAppliedJobsCount(appliedJobsCount - 1);
      setAppliedJobs(updatedAppliedJobs);
      setRecJobs([recommendedJobs, ...recJobs]);
    }
  }, [withdrawJobId]);

  useEffect(() => {
    if (jobAppliedId !== null) {
      const updatedRecommendedJobs = recJobs?.filter(
        (job) => job?.id !== jobAppliedId
      );
      const appliedJob = recJobs?.find((job) => job?.id === jobAppliedId);

      if (recJobsCount > 0) setRecJobsCount(recJobsCount - 1);
      if (appliedJobsCount >= 0) setAppliedJobsCount(appliedJobsCount + 1);
      setAppliedJobs([{ ...appliedJob, date: new Date() }, ...appliedJobs]);
      setRecJobs(updatedRecommendedJobs);
    }
  }, [jobAppliedId]);

  useEffect(() => {
    getCanDashboardData();
  }, [searchJobApplied]);

  return (
    <>
      <BaseInfo
        appliedJobsCount={appliedJobsCount}
        recJobsCount={recJobsCount}  
        canJourneyCount={canJourneyCount}
      />
      <JobApplied
        showMsg={showMsg}
        appliedJobsCount={appliedJobsCount}
        appliedJobs={appliedJobs}
        searchJobApplied={searchJobApplied}
        onSearchChange={onSearchJobApplied}
      />
      <RecJob recJobsCount={recJobsCount} recJobs={recJobs} />
      <WorldConnect />
    </>
  );
};

export default CanDashboard;
