const JobNoRecordFound = ({msg}) => {
  return (
    <>
      {" "}
      <div className="divider divide-desk"></div>
      <p class="error-wishlist">{msg ? msg : "The search did not return any rows"}</p>
    </>
  );
};

export default JobNoRecordFound;