import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const BaseInfo = ({ appliedJobsCount, recJobsCount, canJourneyCount }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { inboxCount } = useSelector((state) => state.auth);

  const handleInbox = () => {
    navigate("/candidate-inbox");
  };

  return (
    <section className="candidate-dashbord-sec">
      <div className="container container-site">
        <div class="space-inner-row">
          <div className="job-recommend-applied">
            <a href="#rec_job" className="card-link-box-2 link-box">
              <div className="job-applied-col job-candidate-cards">
                <img src="/image/number-job.png" alt="" />
                <p className="job-title-candidate-dashboard">
                  Recommended jobs
                </p>
                <p className="job-applied-candidate-dashboard">
                  {recJobsCount}
                </p>
              </div>
            </a>
            <a href="#job_applied" className="card-link-box-1 link-box">
              <div className="job-applied-col job-candidate-cards">
                <img src="/image/job-applied.png" alt="" />
                <p className="job-title-candidate-dashboard">Jobs applied</p>
                <p className="job-applied-candidate-dashboard">
                  {appliedJobsCount}
                </p>
              </div>
            </a>
            <a
              className="card-link-box-3 link-box cursor-pointer"
              onClick={handleInbox}
            >
              <div className="job-applied-col job-candidate-cards">
                <img src="/image/chat-icon.png" alt="" />
                <p className="job-title-candidate-dashboard">Inbox</p>
                <p className="job-applied-candidate-dashboard">
                  {inboxCount && inboxCount}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BaseInfo;
