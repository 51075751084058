const setDateTime = (data) => {
    const date = new Date(data);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    let formattedDate = "";
  
    // Compare dates
    if (date.toDateString() === today.toDateString()) {
      formattedDate = "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      formattedDate = "Yesterday";
    } else {
      // Format the date as "Aug 20, 2024"
      formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
  
    return formattedDate;
  };


  export default setDateTime;