import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomRouter from "./routes";
import { determineCountryISOCodeAsync } from "./redux/slices/homeSlice";
import "./App.css";
import "./styles/common.css";
import "./styles/adminCommon.css";
import "./styles/responsive.css";

function App() {
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(determineCountryISOCodeAsync());
  }, []);
  return <CustomRouter />;
}

export default App;
