import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import NFormatter from "../../../utils/NFormatter";
import { ApplyJobSteps } from "../JobRecord/ApplyJobSteps/Index";
import getDays from "../../../utils/getDays";
import { setLoginModel } from "../../../redux/slices/AuthSlice";
import {
  jobAppliedAsync,
  setJobAppliedId,
} from "../../../redux/slices/candidateDashboardSlice";
import AddToWishlist from "../JobRecord/AddToWishlist";
import { ADMIN } from "../../../constants";
import "./JobCardMbl.css";
import ReactHtmlParser from "react-html-parser";
import { formatSalary } from "../../../utils/formatSalary";
import ActionModal from "../ActionModal/ActionModal";

const initialValuesForSubmitData = {
  id: null,
  profile: false,
  salary: false,
};

const JobCardMbl = ({
  hashId,
  slug,
  JobData,
  index,
  selectedJobId,
  jobCountry,
  date,
  isApplied,
  wishList,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggleApplied, setToggleApplied] = useState(false);
  const [toggleEmptyResumesModal, setToggleEmptyResumesModal] = useState(false);
  const [showApplyJobModal, setShowApplyJobModal] = useState(false);
  const [applyingToJob, setApplyingToJob] = useState(false);

  const [submitData, setSubmitData] = useState(initialValuesForSubmitData);

  const jobAppiled = isApplied === 1 || toggleApplied;
  const { user, isEmailVerify, resumes, userCountry } = useSelector(
    (state) => state.auth
  );
  const onDetailJobHandler = (id) => {
    if (slug && hashId) {
      navigate(`/jobs/${slug}/?currentJobId=${hashId}`);
    }
  };

  const handleSubmit = (resume_id, include_profile, show_expected_salary) => {
    setApplyingToJob(true);
    dispatch(
      jobAppliedAsync({
        userId: user?.id,
        jobId: selectedJobId,
        resume_id,
        include_profile,
        show_expected_salary,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          dispatch(setJobAppliedId(selectedJobId));
          setToggleApplied(true);
          setShowApplyJobModal(false);
          setSubmitData(initialValuesForSubmitData);
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        setApplyingToJob(false);
      })
      .catch((error) => {
        console.log(error);
        setApplyingToJob(false);
      });
  };

  const toggleEmptyResumesHandler = (value) => {
    setToggleEmptyResumesModal(value);
    if (value === true) {
      setTimeout(() => {
        setToggleEmptyResumesModal(false);
      }, 5000);
    }
  };

  const applyNowHandler = () => {
    if (isApplied === 1) {
      return null;
    }
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else if (resumes?.length === 0) {
      toggleEmptyResumesHandler(true);
    } else {
      setShowApplyJobModal(true);
    }
  };

  const onDetailCompanyHandler = (hashed_id) => {
    if (hashed_id) {
      navigate(`/company-detail/?currentCompanyId=${hashed_id}`);
    }
  };

  return (
    <div className="job-card" style={{ position: "relative" }}>
      <div className="card-header-custom" style={{ width: "100%" }}>
        {JobData?.is_promoted === 1 && (
          <Link href="#" className="title-job-link promoted-btn-not">
            <button className=" promoted-job-btn">Promoted * </button>
          </Link>
        )}

        {isEmailVerify && ADMIN.includes(user?.user_type) ? (
          ""
        ) : (
          <div
            className="wish-cirle"
            style={{
              position: "absolute",
              top: JobData?.promoted ? "25px" : "20px",
              right: "20px",
              visibility: isApplied === 0 ? "visible" : "hidden",
            }}
          >
            <AddToWishlist
              jobId={JobData?.id}
              wishlist={JobData?.whishlist}
              withoutTxt={true}
            />
          </div>
        )}
      </div>

      <div className={`job-name`}>
        <span onClick={() => onDetailJobHandler(JobData?.id)}>
          <h3 className="job-name-title">
            {JobData?.full_title ? JobData?.full_title : JobData?.name}
          </h3>
        </span>
      </div>

      <h3
        className="company-name"
        onClick={() => onDetailCompanyHandler(JobData?.company?.hashed_id)}
        style={{ fontWeight: "600" }}
      >
        {JobData?.company?.name}
      </h3>

      <div className="">
        <div className="job-stats-container">
          {JobData?.salary_from && (
            <button className="purple-tags">
              {" "}
              {JobData?.salary_from &&
                JobData?.salary_from !== null &&
                "Salary "}
              {JobData?.salary_from && JobData?.salary_from !== null
                ? JobData?.currency[0]?.title
                  ? ` ${JobData?.currency[0]?.title}`
                  : JobData?.currency?.title
                  ? JobData?.currency?.title
                  : JobData?.currency?.name
                  ? JobData?.currency?.name
                  : ""
                : ""}
              {JobData?.salary_from &&
                ` ${
                  JobData?.currency[0]?.title
                    ? formatSalary(
                        JobData?.salary_from,
                        JobData?.currency[0]?.title,
                        "",
                        false
                      )
                    : JobData?.currency?.title
                    ? formatSalary(
                        JobData?.salary_from,
                        JobData?.currency?.title,
                        "",
                        false
                      )
                    : formatSalary(
                        JobData?.salary_from,
                        JobData?.currency?.name,
                        "",
                        false
                      )
                }`}{" "}
              {JobData?.salary_to &&
                ` - ${
                  JobData?.currency[0]?.title
                    ? formatSalary(
                        JobData?.salary_to,
                        JobData?.currency[0]?.title,
                        JobData?.salary_range,
                        true
                      )
                    : JobData?.currency?.title
                    ? formatSalary(
                        JobData?.salary_to,
                        JobData?.currency?.title,
                        JobData?.salary_range,
                        true
                      )
                    : formatSalary(
                        JobData?.salary_to,
                        JobData?.currency?.title,
                        JobData?.salary_range,
                        true
                      )
                }`}
            </button>
          )}

          <button className="purple-tags">
            <p className="location-head">Location</p>
            <img src="/image/location-blue.png" alt="Location" />
            <p class="job-city ">
              {JobData?.jobCities?.length === 1
                ? JobData?.jobCities[0]?.name
                : "Multiple Cities"}
            </p>
          </button>

          <button className="purple-tags">
            {JobData?.jobType?.length > 0 && JobData?.jobType[0]?.name}
          </button>
        </div>

        <br />

        <div className="job-deesc">
          {ReactHtmlParser(JobData?.description?.substr(0, 200) + "...")}
        </div>

        <div className="card-center-left">
          {/* <img
            className="wish-list-mbl company-logo"
            src={JobData?.company?.logo}
            alt="favorite"
          /> */}
          {/* <div className="job-company"> */}
          {/* <h3 className="company-name">{JobData?.company?.name}</h3> */}
          {/* <div class="location-job-tabl">
              <p className="location-head">Location</p>
              <img src="/image/Location.png" alt="Location" />
              <p class="job-city ">
                {JobData?.jobCities?.length === 1
                  ? JobData?.jobCities[0]?.name
                  : "Multiple Cities"}
              </p>
            </div> */}
          {/* </div> */}
          {/* </div>
          <div className="card-center-right">
            <h3 className="salary-head">
              {JobData?.salary_from &&
                `Salary $${NFormatter(JobData?.salary_from)}`}{" "}
              {JobData?.salary_to && `- $${NFormatter(JobData?.salary_to)}`}
            </h3>
            <h3>{JobData?.jobType?.length > 0 && JobData?.jobType[0]?.name}</h3>
          </div> */}
        </div>
      </div>

      <br />

      {toggleEmptyResumesModal && (
        <ActionModal
          heading="Please upload/create a resume to apply."
          content=""
          closeModal={() => setToggleEmptyResumesModal(false)}
          modalArt="/image/warning-wishlist.png"
        ></ActionModal>
      )}

      {showApplyJobModal && (
        <ApplyJobSteps
          setSubmitData={(data) => {
            setSubmitData(data);
          }}
          submitData={submitData}
          handleSubmit={handleSubmit}
          applyingToJob={applyingToJob}
          show={showApplyJobModal}
          onHide={() => setShowApplyJobModal(false)}
        />
      )}

      <button
        class="view-job-btn"
        onClick={() => onDetailJobHandler(JobData?.id)}
      >
        View job
      </button>
      {(isEmailVerify && ADMIN.includes(user?.user_type)) ||
      (isEmailVerify && userCountry?.id !== jobCountry?.id) ? (
        ""
      ) : jobAppiled ? (
        <div
          className={`apply-btn applied-btn`}       
          style={{ marginTop: "10px" }}
        >
          <img
            className={`apply-icon`}
            alt="apply"
            src="/image/apply.png"
          />{" "}
          <p className="apply-head">Applied</p>
        </div>
      ) : (
        <div
          className={`apply-btn apply-btn`}
          onClick={() => !toggleApplied && applyNowHandler()}
          style={{ marginTop: "10px" }}
        >          
          <p className="apply-head">Apply now</p>
        </div>
      )}

      <p className="museo-font date-end" style={{ textAlign: "right" }}>
        {date
          ? getDays(date) > 1
            ? getDays(date) + " days ago"
            : getDays(date) > 0
            ? getDays(date) + " day ago"
            : "Today"
          : ""}
      </p>
    </div>
  );
};
export default JobCardMbl;
