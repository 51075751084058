import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    
  },
});

// Action creators are generated for each case reducer function
export const {} = notificationSlice.actions;

export default notificationSlice.reducer;

// asynchronous function with createAsyncThunk

export const getNotifyDataAsync = createAsyncThunk(
  "cand/getNotifyData",
  async ({ page, per_page }) => {
    try {
      let params = `?`;
      if (page !== null) {
        params += `page=${page}&`;
      }
      if (per_page !== null) {
        params += `per_page=${per_page}&`;
      }

      const response = await API.get(`/notifications${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteNotifyDataAsync = createAsyncThunk(
  "cand/deleteNotifyData",
  async ({ selectedNotifyId }) => {
    const formData = new FormData();

    selectedNotifyId !== null &&
      Object.keys(selectedNotifyId).forEach((key) => {
        formData.append("notification_ids[]", selectedNotifyId[key]);
      });

    try {
      const response = await API.post(`/delete-notifications`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const markNotifyAsReadDataAsync = createAsyncThunk(
  "cand/markNotifyAsReadData",
  async ({ notification_ids }) => {
    const formData = new FormData();

    notification_ids !== null &&
      formData.append("notification_ids[]", notification_ids);

    try {
      const response = await API.post(`/mark-notification-as-read`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const markAllNotifyAsReadDataAsync = createAsyncThunk(
  "cand/markAllNotifyAsReadData",
  async () => {
    try {
      const response = await API.post(`/mark-all-notifications-as-read`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
