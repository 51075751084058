import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";
import APIPvtDB from "../../apiPvtD";

const initialState = {
  totalBulkResumeCredit: 0
};
const buyBulkAnalysisSlice = createSlice({
  name: "buyBulkAnalysis",
  initialState,
  reducers: {
    setTotalBulkResumeCredit: (state, action) => {
      state.totalBulkResumeCredit = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTotalBulkResumeCredit } = buyBulkAnalysisSlice.actions;

export default buyBulkAnalysisSlice.reducer;

// asynchronous function with createAsyncThunk

export const getBulkCreditsDataAsync = createAsyncThunk(
  "admin/getBulkCreditsData",
  async () => {
    try {
      const response = await APIPvtDB.get(`/job-board/get-bulk-credits-list`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const buyBulkCreditsDataAsync = createAsyncThunk(
  "admin/buyBulkCreditsData",
  async ({ bundle_id, user_email, user_id }) => {
    const formData = new FormData();
    bundle_id && formData.append("bundle_id", bundle_id);
    user_email && formData.append("user_email", user_email);
    user_id && formData.append("user_id", user_id);

    try {
      const response = await API.post(`/job-board/buy-bulk-credits`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getBulkCreditsPurchasedDataAsync = createAsyncThunk(
  "admin/getBulkCreditsPurchasedData",
  async ({ userId, currentPage, perPage, sortBy, sortDirection }) => {
    let params = `?`;
    if (userId !== null) {
      params += `user_id=${userId}&`;
    }
    if (currentPage !== null) {
      params += `page=${currentPage}&`;
    }
    if (perPage !== "") {
      params += `per_page=${perPage}&`;
    }
    if (sortBy !== "") {
      params += `sort_by=${sortBy}&`;
    }
    if (sortDirection !== "") {
      params += `sort_direction=${sortDirection}&`;
    }

    try {
      const response = await API.get(
        `/job-board/get-bulk-credits-purchased${params}`
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
