import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import { setLoginModel } from "../../../redux/slices/AuthSlice";
import {
  jobAppliedAsync,
  setJobAppliedId,
} from "../../../redux/slices/candidateDashboardSlice";
import getDays from "../../../utils/getDays";
import AddToWishlist from "./AddToWishlist";
import { ADMIN, USER_TYPES } from "../../../constants";
import "./JobRecord.css";
import { ApplyJobSteps } from "./ApplyJobSteps/Index";
import ActionModal from "../ActionModal/ActionModal";

const initialValuesForSubmitData = {
  id: null,
  profile: false,
  salary: false,
};

const JobRecordRight = ({
  hashId,
  slug,
  selectedJobId,
  jobCountry,
  date,
  isApplied,
  wishList,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggleApplied, setToggleApplied] = useState(false);
  const [toggleEmptyResumesModal, setToggleEmptyResumesModal] = useState(false);
  const [showApplyJobModal, setShowApplyJobModal] = useState(false);
  const { user, isEmailVerify, resumes, userCountry } = useSelector(
    (state) => state.auth
  );
  const [applyingToJob, setApplyingToJob] = useState(false);

  const [submitData, setSubmitData] = useState(initialValuesForSubmitData);
  const jobAppiled = isApplied === 1 || toggleApplied;

  const onDetailJobHandler = () => {
    if(slug && hashId){
      navigate(`/jobs/${slug}/?currentJobId=${hashId}`);
    }
  };

  const toggleEmptyResumesHandler = (value) => {
    setToggleEmptyResumesModal(value);
    if (value === true) {
      setTimeout(() => {
        setToggleEmptyResumesModal(false);
      }, 5000); 
  }
};

  const applyNowHandler = () => {
    if (isApplied === 1) {
      return null;
    }
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } 
    else if(resumes?.length === 0){
      toggleEmptyResumesHandler(true);
    }
    else {
      setShowApplyJobModal(true);
    }
  };

  const handleSubmit = (resume_id, include_profile, show_expected_salary) => {
    setApplyingToJob(true);
    dispatch(
      jobAppliedAsync({
        userId: user?.id,
        jobId: selectedJobId,
        resume_id,
        include_profile,
        show_expected_salary,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          dispatch(setJobAppliedId(selectedJobId));
          setToggleApplied(true);
          setShowApplyJobModal(false);
          setSubmitData(initialValuesForSubmitData);
          toast.success(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        setApplyingToJob(false);
      })
      .catch((error) => {
        console.log(error);
        setApplyingToJob(false);
      });
  };

  return (
    <div>
    
      <div className="job-announcements">      
        <div
          style={{ width: "100%" }}
          className={
            isEmailVerify && ADMIN.includes(user?.user_type) ? "no-fav" : ""
          }
        >
          {isEmailVerify && ADMIN.includes(user?.user_type) ? (
            ""
          ) : (
            
            <div className="wish-cirle" style={{ marginLeft: "auto", visibility: isApplied === '0' ? 'visible' : 'hidden' }}>
              
              <AddToWishlist
                jobId={selectedJobId}
                wishlist={wishList}
                withoutTxt={true}
              />
    
            </div>
          )}
        </div>

      {toggleEmptyResumesModal && (
        <ActionModal
          heading="Please upload/create a resume to apply."
          content=""
          closeModal={() => setToggleEmptyResumesModal(false)}
          modalArt='/image/warning-wishlist.png'
        ></ActionModal>
      )}

      {showApplyJobModal && 
      <ApplyJobSteps
        setSubmitData={(data) => {
          setSubmitData(data);
        }}
        submitData={submitData}
        handleSubmit={handleSubmit}
        applyingToJob={applyingToJob}
        show={showApplyJobModal}
        onHide={() => setShowApplyJobModal(false)}
      />
      }

        <div className="btn-holder">
          <div className="btn-group-holder">
            <button class="view-job-btn" onClick={() => onDetailJobHandler()}>
              View job
            </button>

            {(isEmailVerify && ADMIN.includes(user?.user_type)) ||
            (isEmailVerify && userCountry?.id !== jobCountry?.id) ? (
              ""
            ) : (
              jobAppiled ? (
                <div
                  className={`apply-btn mt-2 applied-btn`}       
                  style={{ marginTop: "10px" }}
                >
                  <img
                    className={`apply-icon`}
                    alt="apply"
                    src="/image/apply.png"
                  />{" "}
                  <p className="apply-head">Applied</p>
                </div>
              ) : (
                <div
                  className={`apply-btn mt-2 apply-btn`}
                  onClick={() => !toggleApplied && applyNowHandler()}
                  style={{ marginTop: "10px" }}
                >          
                  <p className="apply-head">Apply now</p>
                </div>
              )
            )}
          </div>

          <p
            className="museo-font"
            style={{
              textAlign: "right",
              fontSize: "16px",
              color: "rgba(43 43 43 / 70%)",
            }}
          >
            {date
              ? getDays(date) > 1
                ? getDays(date) + " days ago"
                : getDays(date) > 0
                ? getDays(date) + " day ago"
                : "Today"
              : ""}
          </p>
        </div>

        {/* <div className="job-announcements-col btn-size">



        <div className="job-time-wishlist">
          <div className="job-announcements-status">
            <p className="museo-font">
              {date
                ? getDays(date) > 1
                  ? getDays(date) + " days ago"
                  : getDays(date) > 0
                    ? getDays(date) + " day ago"
                    : "Today"
                : ""}
            </p>
          </div>
          {isEmailVerify && ADMIN.includes(user?.user_type) ? (
            ""
          ) : (
            <>
              <div
                className="job-apply icon-cursor"
                onClick={() => applyNowHandler()}
              >
                <img src="/image/apply.png" alt="apply" />
                <p className="museo-font">
                  {isApplied === 1
                    ? "Applied"
                    : toggleApplied
                      ? "Applied"
                      : "Apply now"}
                </p>
              </div>
              <AddToWishlist jobId={selectedJobId} wishlist={wishList} />
            </>
          )}
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default JobRecordRight;
