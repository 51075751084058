import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIPvt from "../../apiPvt";

const initialState = {
  promotionFormMode: "add",
  selectedEditProID: null,
  selectedPromotionType: { id: null, name: "" },
  selectedTimeFrame: { id: null, name: "" },
  selectedPromotionDate: {
    id: null,
    name: "",
    week_start_day: "",
    week_end_day: "",
    start_date: "",
    end_date: "",
  },
};
const companyPromotionSlice = createSlice({
  name: "companyPromotion",
  initialState,
  reducers: {
    setPromotionFormMode: (state, action) => {
      state.promotionFormMode = action.payload;
    },
    setSelectedEditProID: (state, action) => {
      state.selectedEditProID = action.payload;
    },
    setSelectedPromotionType: (state, action) => {
      state.selectedPromotionType = action.payload;
    },
    setSelectedTimeFrame: (state, action) => {
      state.selectedTimeFrame = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setSelectedPromotionDate: (state, action) => {
      state.selectedPromotionDate = action.payload;
    },
    setSelectedDefultPromotionDate: (state, action) => {
      state.selectedPromotionDate = {
        id: null,
        name: "",
        week_start_day: "",
        week_end_day: "",
        start_date: "",
        end_date: "",
      }
    },
    setSelectedJob: (state, action) => {
      state.selectedJob = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPromotionFormMode,
  setSelectedEditProID,
  setSelectedPromotionType,
  setSelectedTimeFrame,
  setSelectedPage,
  setSelectedPromotionDate,
  setSelectedDefultPromotionDate,
  setSelectedJob,
} = companyPromotionSlice.actions;

export default companyPromotionSlice.reducer;

// asynchronous function with createAsyncThunk
export const getPromotionListingDataAsync = createAsyncThunk(
  "admin/getPromotionListing",
  async ({
    promotionTypeId,
    companyId,
    promotionStatusId,
    search,
    sortByStartDate,
    page,
    perPage,
  }) => {
    try {
      let params = `?`;
      if (promotionTypeId !== null) {
        params += `promotion_type_id=${promotionTypeId}&`;
      }
      if (companyId !== null) {
        params += `company_id=${companyId}&`;
      }
      if (promotionStatusId !== null) {
        params += `status_id=${promotionStatusId}&`;
      }
      if (search !== "") {
        params += `search=${search}&`;
      }
      if (sortByStartDate !== "") {
        params += `start_date_sort_by=${sortByStartDate}&`;
      }
      if (page !== null) {
        params += `page=${page}&`;
      }
      if (perPage !== "") {
        params += `per_page=${perPage}&`;
      }

      const response = await APIPvt.get(`/promotions${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getCreatePromotionDataAsync = createAsyncThunk(
  "admin/getCreatePromotion",
  async () => {
    try {
      const response = await APIPvt.get(`/create-promotion`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getTimePromotionDataAsync = createAsyncThunk(
  "admin/getTimePromotionData",
  async ({promotion_type_id}) => {
    let params = `?`;
    if (promotion_type_id !== null) {
      params += `promotion_type_id=${promotion_type_id}`;
    }
    try {
      const response = await APIPvt.get(`/get-timeframe-promotions${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getCreatePromotionPagesForAdsAsync = createAsyncThunk(
  "admin/getPages",
  async () => {
    try {
      const response = await APIPvt.get(`/pages?promotion_type_id=2`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getPromotionJobsAsync = createAsyncThunk(
  "admin/jobs",
  async ({ start_date, end_date }) => {
    try {
      const response = await APIPvt.get(
        `/jobs?start_date=${start_date}&end_date=${end_date}`
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getWeeksPromotionDataAsync = createAsyncThunk(
  "admin/getWeeksPromotion",
  async ({ timeFrame }) => {
    try {
      let params = `?`;
      if (timeFrame !== null) {
        params += `time_frame=${timeFrame}`;
      }

      const response = await APIPvt.get(`/get-weeks-promotions${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getPositionForPromotionDataAsync = createAsyncThunk(
  "admin/getPositionForPromotion",
  async ({ promotionTypeId, startDate, endDate, page_id, promotionId }) => {
    try {
      let params = `?`;
      if (promotionTypeId !== null) {
        params += `promotion_type_id=${promotionTypeId}&`;
      }     
      if (startDate !== null) {
        params += `start_date=${startDate}&`;
      }
      if (endDate !== null) {
        params += `end_date=${endDate}&`;
      }
      if (page_id !== undefined) {
        params += `page_id=${page_id}&`;
      }
      if (promotionId !== undefined) {
        params += `promotion_id=${promotionId}&`;
      }

      const response = await APIPvt.get(
        `/get-positions-for-promotions${params}`
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const createPromotionReqAsync = createAsyncThunk(
  "admin/createPromotionReq",
  async ({
    promotionTypeId,
    timeFrameId,
    startDate,
    endDate,
    positionNumber,
    pageId,
    sectionId,
    jobId,
  }) => {
    try {
      const formData = new FormData();
      promotionTypeId !== null && formData.append("type_id", promotionTypeId);
      timeFrameId !== null && formData.append("time_frame_id", timeFrameId);
      startDate !== null && formData.append("start_date", startDate);
      endDate !== null && formData.append("end_date", endDate);
      positionNumber !== null &&
        formData.append("position_number", positionNumber);
      pageId !== null && formData.append("page_id", pageId);
      sectionId !== null && formData.append("section_id", sectionId);

      jobId && jobId !== null && formData.append("job_id", parseInt(jobId));
      const response = await APIPvt.post(`/promotions`, formData);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const promotionApproveReqAsync = createAsyncThunk(
  "admin/promotionApproveReqAsync",
  async ({ selectedListingId }) => {
    try {
      const formData = new FormData();
      selectedListingId !== null &&
        Object.keys(selectedListingId).forEach((key) => {
          formData.append("promotion_ids[]", selectedListingId[key]);
        });

      const response = await APIPvt.post(
        `/approve-promotion-requests`,
        formData
      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const promotionRejectReqAsync = createAsyncThunk(
  "admin/promotionRejectReqAsync",
  async ({ selectedListingId }) => {
    try {
      const formData = new FormData();
      selectedListingId !== null &&
        Object.keys(selectedListingId).forEach((key) => {
          formData.append("promotion_ids[]", selectedListingId[key]);
        });

      const response = await APIPvt.post(
        `/reject-promotion-requests`,
        formData
      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const promotionReqDeleteAsync = createAsyncThunk(
  "admin/promotionReqDelete",
  async ({ selectedDeleteProId }) => {
    try {
      const response = await APIPvt.delete(`/promotion/${selectedDeleteProId}`);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getEditPromotionDataAsync = createAsyncThunk(
  "admin/getEditPromotionData",
  async ({ selectedEditProID }) => {
    try {
      const response = await APIPvt.get(`/promotion/${selectedEditProID}`);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const editPromotionReqAsync = createAsyncThunk(
  "admin/editPromotionReq",
  async ({
    selectedEditProID,
    promotionTypeId,
    timeFrameId,
    startDate,
    endDate,
    positionNumber,
    pageId,
    sectionId,
    jobId,
  }) => {
    try {
      const formData = new FormData();

      promotionTypeId !== null && formData.append("type_id", promotionTypeId);
      timeFrameId !== null && formData.append("time_frame_id", timeFrameId);
      startDate !== null && formData.append("start_date", startDate);
      endDate !== null && formData.append("end_date", endDate);
      positionNumber !== null &&
        formData.append("position_number", positionNumber);
      pageId !== null && formData.append("page_id", pageId);
      sectionId !== null && sectionId !== undefined && formData.append("section_id", sectionId);
      jobId !== null && jobId !== undefined && formData.append("job_id", jobId);

      const response = await APIPvt.post(
        `/promotion/${selectedEditProID}`,
        formData
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
